import React from "react";
import styles from "./popUpCreateThemes.module.css";

import Button from "@material-ui/core/Button";

export default function PopUpCreateTopic(props) {
  return (
    <div className={styles.mainRateBlock}>
      <div className={styles.block}>
        {ClosePopUp(props)}
        {BlockText(props)}
        {BlockButtonCancelAndOk(props)}
      </div>
    </div>
  );
}

/**
 * Функция закрытия попапа
 * @param {*} props
 */
function ClosePopUp(props) {
  let funcSrtTypeColumn = props.states.functions.createThemes;
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        funcSrtTypeColumn(false);
      }}
    >
      X
    </div>
  );
}

/**
 * Функция вывода текста в попапе
 */
function BlockText(props) {
  let tmpTopic = null;
  for (
    let i = 0;
    i < props.states.school.journalColumn.partTopics.length;
    i++
  ) {
    if (
      Number(props.states.school.journalColumn.createTopicColumn.mainTopic) ===
      props.states.school.journalColumn.partTopics[i].Id
    ) {
      tmpTopic = props.states.school.journalColumn.partTopics[i].Title;
    }
  }
  return (
    <div className={styles.textHelp}>
      <span>Увага!</span>
      <br />
      Ви впевнені що хочете створити колонку тематична?
      <br />
      <span>Тема: {tmpTopic}</span>
      <br />
      Після підтвердження видалити колонку буде неможливо.
    </div>
  );
}

/**
 * Функция выводит кнопки Отмена и Принять
 * @param {*} props
 */
function BlockButtonCancelAndOk(props) {
  let funcSrtTypeColumn = props.states.functions.createThemes;
  //let funcCreateThematicColumn = props.states.functions.post.column.createThematicColumn;
  const stylesLine = {
    width: "218px",
    height: "50px",
  };
  return (
    <div className={styles.buttonHelp}>
      <Button
        style={stylesLine}
        variant="contained"
        color="primary"
        onClick={() => {
          funcSrtTypeColumn(false);
        }}
      >
        Скасувати
      </Button>
      <Button
        style={stylesLine}
        variant="contained"
        color="primary"
        onClick={() => {
          props.states.school.journalColumn.createTopicColumn.create();
          //funcCreateThematicColumn();
          funcSrtTypeColumn(false);
        }}
      >
        Підтвердити
      </Button>
    </div>
  );
}
