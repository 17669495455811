import React from "react";
import styles from "./buttonDeleteColumn.module.css";

import Button from "@material-ui/core/Button";

export default function ButtonDeleteColumn(props) {
  return deleteButton(props);
}

function deleteButton(props) {
  let tmp = null;
  if (props.states.school.baseData.columnPprimary === 0) {
    if (props.states.school.baseData.columntypeIdDell !== 18) {
      if (props.states.school.baseData.columntypeIdDell !== 2) {
        if (props.states.school.baseData.columntypeIdDell !== 19) {
          if (props.states.school.baseData.columntypeIdDell !== 20) {
            if (props.states.school.baseData.columntypeIdDell !== 21) {
              if (props.states.school.baseData.numRate === 0) {
                tmp = (
                  <Button
                    onClick={() => {
                      props.states.functions.post.column.deleteColumn();
                    }}
                    variant="contained"
                    color="secondary"
                    className={styles.Button + " " + styles.button_delete}
                  >
                    Вилучити колонку
                  </Button>
                );
              }
            }
          }
        }
      }
    }
  }
  return tmp;
}
