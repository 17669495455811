import React from "react";
import styles from "./style.module.css";

export default function MessageAdmin(props) {
  return (
    <div
      className={
        styles.mainMenu__leftBaseData + " " + styles.mainMenu__leftBottomBorder
      }
      onClick={() => {
        /*props.changeStateMenu();
                    props.changeStateMainJournal( 1 );
                    props.states.functions.stateTypeJournal(14);
                    props.states.functions.getFeedbackTopic();
                    props.states.functions.menu.reversText(14);
                    props.states.functions.getAllFeedback();
                    props.states.functions.setOwnClassFlag(false);*/
      }}
    >
      {textMenu("Шкільні новини та новини класу")}
    </div>
  );
}

function textMenu(text) {
  return (
    <p>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.0001 11.5C21.0035 12.8199 20.6952 14.1219 20.1001 15.3C19.3945 16.7117 18.3098 17.8992 16.9675 18.7293C15.6252 19.5594 14.0783 19.9994 12.5001 20C11.1802 20.0034 9.47812 19.6951 8.3 19.1L2.6 21L4.5 15.3C3.90493 14.1219 3.59656 12.8199 3.6 11.5C3.60061 9.92176 4.04061 8.37485 4.87072 7.03255C5.70083 5.69025 6.88825 4.60557 8.3 3.9C9.47812 3.30493 11.1802 2.99656 12.5001 3H13.0001C15.0844 3.11499 17.0531 3.99476 18.5292 5.47086C20.0053 6.94695 20.8851 8.91565 21.0001 11V11.5Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 15V11"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 7H12.01"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {text}
    </p>
  );
}
