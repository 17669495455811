import React from "react";
import styles from "./selectLessonTopic.module.css";

import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export default function SelectLessonTopic(props) {
  return (
    <>
      <div className={styles.headerText}>Тема уроку: </div>
      <div className={styles.homework_wrap_slide_one}>
        <Select
          className={styles.select_lesson_one}
          native
          value={props.states.school.journalColumn.updateColumn.lessonTopic}
          onChange={(value) => {
            props.states.school.journalColumn.updateColumn.setLessonTopic(
              value.target.value
            ); //new
            props.states.school.journalColumn.updateColumn.updateTopic(
              value.target.value
            ); //new
          }}
          input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
        >
          <option selected disabled key={5677} value={0}>
            Обрати тему уроку
          </option>
          {editBindedMainTopic(props)}
        </Select>
      </div>
      <div className={styles.helpText}>
        Для зміни теми оберіть необхідну зі списку
      </div>
    </>
  );
}

function editBindedMainTopic(props) {
  let tmp = null;
  if (
    props.states.school.journalTopics.partTopics !== null &&
    props.states.school.journalColumn.updateColumn.mainTopic !== null
  ) {
    tmp = props.states.school.journalTopics.partTopics
      .filter((e) => {
        return (
          e.ParentId ===
          Number(props.states.school.journalColumn.updateColumn.mainTopic)
        );
      })
      .map((e) => {
        return (
          <option key={e.Id} value={e.Id}>
            {e.Number} - {e.Title}
          </option>
        );
      });
  }
  return tmp;
}
