import React from "react";
import styles from "./editTopic.module.css";

import MainTopic from "./mainTopic/mainTopic.js";
import LessonTopic from "./lessonTopic/lessonTopic.js";

export default function CreateTopic(props) {
  return viewCreateNewThemes(props);
}

function viewCreateNewThemes(props) {
  return (
    <div className={styles.wrapBlok}>
      <MainTopic states={props.states} />
      <LessonTopic states={props.states} />
    </div>
  );
}
