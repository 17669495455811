import React from "react";
import Button from "@material-ui/core/Button";

import Clasess from "./errorLogin.module.css";

export default function ErrorAlert(props) {
  if (props.states.school.baseData.tiberUpdateSheduler === true) {
    props.states.functions.timerScheduleUpdateClose();
  }
  return (
    <div className={Clasess.errorMain}>
      <div id={Clasess.logoPage} className={Clasess.logoPaige}>
        <div className={Clasess.loginP}>
          <div className={Clasess.loginBase}>
            {props.textAlert}
            <br />
            <br />
            {props.textAlertTwo}
          </div>
          <Button
            variant="contained"
            color="primary"
            className={Clasess.buttonTT}
            onClick={() => {
              deleteAllCookies();
              props.changeStateExit();
              props.states.functions.getConstData.errorLoginConect(0);
              props.states.functions.getConstData.clearError();
              window.location.reload(true);
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
}

function deleteAllCookies() {
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookies = decodedCookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
