import React from "react";
import styles from "./lesson.module.css";

import ViewData from "../viewData/viewData.js";
import SelectTypeLesson from "./selectTypeLesson/selectTypeLesson.js";
import BlockSelectedTopic from "./blockSelectedTopic/blockSelectedTopic.js";
import ViewHomework from "./viewHomework/viewHomework.js";

export default function Lesson(props) {
  return (
    <div className={styles.popup_topic_scrol}>
      <ViewData states={props.states} />
      <SelectTypeLesson states={props.states} />
      <BlockSelectedTopic states={props.states} />
      <ViewHomework states={props.states} />
    </div>
  );
}
