import React from "react";
import styles from "./selectmaintopic.module.css";

import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export default function SelectMainTopicCreateHomework(props) {
  return (
    <>
      <div className={styles.headerText}>Тема: </div>
      <div className={styles.homework_wrap_slide_one}>
        <Select
          className={styles.select_lesson_one}
          native
          value={props.states.school.journalColumn.createHomework.mainTopic}
          onChange={(value) => {
            props.states.school.journalColumn.createHomework.setMainTopic(
              value.target.value
            ); //new
          }}
          input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
        >
          <option selected disabled key={5677} value={0}>
            Обрати тему
          </option>
          {createSelectMainTopic(props)}
        </Select>
      </div>
    </>
  );
}

function createSelectMainTopic(props) {
  let tmp = null;
  if (props.states.school.journalTopics.partTopics !== null) {
    tmp = props.states.school.journalTopics.partTopics
      .filter((e) => {
        return e.ParentId === null;
      })
      .map((e) => {
        return (
          <option key={e.Id} value={e.Id}>
            {e.Title}
          </option>
        );
      });
  }
  return tmp;
}
