import React from "react";
import styles from "./description.module.css";

import TextField from "@material-ui/core/TextField";

export default function DescriptionEditHomework(props) {
  return viewDescriptionEditHomework(props);
}

function viewDescriptionEditHomework(props) {
  let tmp = null;
  if (props.states.school.journalColumn.editHomework.statertLesson !== null) {
    tmp = (
      <TextField
        className={styles.select_lesson_one}
        value={props.states.school.journalColumn.editHomework.deskription}
        onChange={(event) => {
          props.states.school.journalColumn.editHomework.setDeskription(
            event.target.value
          );
        }}
        id="outlined-bare"
        inputProps={{ "aria-label": "bare" }}
        margin="normal"
        variant="outlined"
        multiline={true}
        rowsMax="6"
        rows="6"
      />
    );
  }
  return tmp;
}
