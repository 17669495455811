import React from "react";
import styles from "./style.module.css";

export default function MessageAdmin(props) {
  return (
    <div
      className={
        styles.mainMenu__leftBaseData + " " + styles.mainMenu__leftBottomBorder
      }
      onClick={() => {}}
    >
      {textMenu("Навчальні матеріали")}
    </div>
  );
}

function textMenu(text) {
  return (
    <p>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.5 2H20V22H6.5C5.83696 22 5.20107 21.7366 4.73223 21.2678C4.26339 20.7989 4 20.163 4 19.5V4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2V2Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {text}
    </p>
  );
}
