import React from "react";
import styles from "./uploadTopic.module.css";

import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

export default function UploadTopic(props) {
  return (
    <>
      <div>
        Для завантаження списку тем Вам необхідно імпортувати наступний файл
        формату XLSX на комп'ютер, заповнити його та експортувати у систему.
      </div>
      <div className={styles.button_imp}>
        <a href={props.states.school.journalTopics.getTopicsXLSX()} download>
          <Button
            component="span"
            variant="contained"
            color="default"
            className={styles.button}
          >
            Скачати шаблон файлу
          </Button>
        </a>
      </div>
      <div className={styles.button_imp}>
        <a
          href="https://journal.eschool-ua.com/download/Настанова_користувачів_журнал_вчителя.pdf"
          download
        >
          <Button
            component="span"
            variant="contained"
            color="default"
            className={styles.button}
          >
            Скачати настанову користувачів журнал вчителя
          </Button>
        </a>
      </div>

      <div className={styles.files + " " + styles.button_imp}>
        <input
          accept=".xlsx"
          className={styles.input + " " + styles.files_input}
          id="text-button-file"
          name="fileUp"
          multiple
          type="file"
          onChange={(e) => {
            selectFiles(props, e);
          }}
        />
        <label htmlFor="text-button-file">
          <Button
            component="span"
            variant="contained"
            color="default"
            className={styles.button}
          >
            Завантажити
            <CloudUploadIcon className={styles.rightIcon} />
          </Button>
        </label>
      </div>
      <div className={styles.uploadTexr}>
        {props.states.school.baseData.UploadGood}
      </div>
    </>
  );
}

const postFiles = (_url, data, props) => {
  return new Promise(function (resolve, reject) {
    let request = new XMLHttpRequest();
    let fd = new FormData();
    let jsonData;

    fd.append("upload-file", data);

    request.open("POST", _url);

    request.setRequestHeader(
      "X-Auth-Token",
      props.states.school.baseData.token
    );
    request.setRequestHeader("Api-key", props.states.school.baseData.apikey);

    request.onload = function () {
      if (request.status === 200 && request.readyState === 4) {
        jsonData = JSON.parse(request.responseText);
        //resolve( jsonData );
        resolve(data);
      } else {
        reject(request.status);
      }
    };
    request.onerror = function (error) {
      reject(error);
    };
    request.send(fd);
  });
};

const selectFiles = (props, value) => {
  let PartId = props.states.school.baseData.PartId;
  let funcSetUploadGood = props.states.functions.setUploadGood;
  let funcGetPartThemes = props.states.functions.getConstData.getPartThemes;
  let tempUrl =
    props.states.school.baseData.domen +
    "/v1/" +
    props.states.school.baseData.teacher.SchoolId +
    "/Part/" +
    props.states.school.baseData.PartId +
    "/UploadTopic";

  for (let i = 0; i < value.target.files.length; i++) {
    postFiles(tempUrl, value.target.files[i], props)
      .then(
        (body) => {
          return body;
        },
        (err) => {
          if (err.type === "error") {
          } else if (err === 404 || err === 403) {
          }
        }
      )
      .then((body) => {
        //props.states.functions.setUploadGood("Теми успішно завантажені!");
        funcSetUploadGood("Теми успішно завантажені!");
        return body;
      })
      .then((body) => {
        //props.states.functions.getConstData.getPartThemes(PartId);
        funcGetPartThemes(PartId);
        //props.states.school.journalTopics.getTopics();
        props.states.school.journalTopics.getTopics(
          props.states.school.baseData.partNumber
        );
        props.states.functions.get.column.dataTopic();
        props.states.school.journalTopics.getTopics();
        props.states.school.journalColumn.getTopics();
        return body;
      })
      .then((body) => {});
  }
};
