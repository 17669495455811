import React from "react";
import styles from "./lessonTopic.module.css";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function LessonTopic(props) {
  const handleLessonNumber = (name) => (event) => {
    props.states.school.journalTopics.createLesson.setNumber(
      event.target.value
    );
  };
  const handleLessonTitle = (name) => (event) => {
    props.states.school.journalTopics.createLesson.setTitle(event.target.value);
  };
  const handleLessonDescript = (name) => (event) => {
    props.states.school.journalTopics.createLesson.setDescription(
      event.target.value
    );
  };
  const handleLessonHomework = (name) => (event) => {
    props.states.school.journalTopics.createLesson.setHomework(
      event.target.value
    );
  };
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>
          <p>Тема уроку</p>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>
          <div className={styles.popup_div_selected}>
            <div className={styles.popup_descrip}>Тема</div>
            <Select
              className={styles.textField + " " + styles.selectFiles}
              native
              value={props.states.school.journalTopics.createLesson.parentTopic}
              onChange={(value) => {
                props.states.school.journalTopics.createLesson.setParentTopic(
                  value.target.value
                );
              }}
              input={
                <OutlinedInput name="age" id="outlined-age-native-simple" />
              }
            >
              <option selected disabled key={5677} value={0}>
                Обрати тему
              </option>
              {createSelectMainTopic(props)}
            </Select>
          </div>
          <div>
            <div className={styles.popup_topic}>Номер теми уроку</div>
            <div>
              <TextField
                value={props.states.school.journalTopics.createLesson.number}
                onChange={handleLessonNumber("name")}
                id="outlined-bare"
                className={styles.textField + " " + styles.textfild_topic}
                inputProps={{ "aria-label": "bare" }}
                margin="normal"
                variant="outlined"
              />
            </div>
          </div>
          <div>
            <div className={styles.popup_topic}>Назва теми уроку</div>
            <div>
              <TextField
                value={props.states.school.journalTopics.createLesson.title}
                onChange={handleLessonTitle("name")}
                id="outlined-bare"
                className={styles.textField + " " + styles.textfild_topic}
                inputProps={{ "aria-label": "bare" }}
                margin="normal"
                variant="outlined"
              />
            </div>
          </div>
          <div>
            <div className={styles.popup_descrip}>Примітка теми уроку</div>
            <div>
              <TextField
                value={
                  props.states.school.journalTopics.createLesson.description
                }
                onChange={handleLessonDescript("name")}
                id="outlined-bare"
                className={styles.textField + " " + styles.textfild_descript}
                inputProps={{ "aria-label": "bare" }}
                margin="normal"
                variant="outlined"
                multiline={true}
                rowsMax="3"
                rows="3"
              />
            </div>
          </div>
          <div>
            <div className={styles.popup_descrip}>Домашнє завдання</div>
            <div>
              <TextField
                value={props.states.school.journalTopics.createLesson.homework}
                onChange={handleLessonHomework("name")}
                id="outlined-bare"
                className={styles.textField + " " + styles.textfild_descript}
                inputProps={{ "aria-label": "bare" }}
                margin="normal"
                variant="outlined"
                multiline={true}
                rowsMax="3"
                rows="3"
              />
            </div>
          </div>
          {buttonCtreateLessonTopic(props)}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

function createSelectMainTopic(props) {
  let tmp = null;
  if (props.states.school.journalTopics.partTopics !== null) {
    tmp = props.states.school.journalTopics.partTopics
      .filter((e) => {
        return e.ParentId === null;
      })
      .map((e) => {
        return (
          <option key={e.Id} value={e.Id}>
            {e.Title}
          </option>
        );
      });
  }
  return tmp;
}

function buttonCtreateLessonTopic(props) {
  let tmp = null;
  if (
    props.states.school.journalTopics.createLesson.parentTopic !== null &&
    props.states.school.journalTopics.createLesson.number !== null &&
    props.states.school.journalTopics.createLesson.title !== null
  ) {
    tmp = (
      <div className={styles.popup_button}>
        <Button
          onClick={() => {
            if (props.states.school.baseData.topicThemes !== 0) {
              props.states.school.journalTopics.createLesson.create();
              props.states.functions.changed.flags.changeFlagCreateTopic(false);
            }
          }}
          variant="contained"
          color="primary"
          className={styles.Button}
        >
          Створити тему уроку
        </Button>
      </div>
    );
  }
  return tmp;
}
