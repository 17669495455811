import React from "react";
import styles from "./selectHomework.module.css";

import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export default function SelectEditHomework(props) {
  return (
    <Select
      className={styles.select_lesson_one}
      native
      value={props.states.school.journalColumn.editHomework.statertLesson}
      onChange={(value) => {
        props.states.school.journalColumn.editHomework.setTargetLesson(
          value.target.value
        );
      }}
      input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
    >
      <option selected disabled key={5677} value={0}>
        Обрати дз
      </option>
      {createSelectedHomeworks(props)}
    </Select>
  );
}

function createSelectedHomeworks(props) {
  let tmp = null;
  //if( props.states.school.arr.homework !== null ) {
  if (props.states.school.journalTopics.partHomework !== null) {
    //tmp = props.states.school.arr.homework.filter(
    tmp = props.states.school.journalTopics.partHomework
      .filter((item) => {
        return (
          item.Id === props.states.school.baseData.columnPopUpData.AppointmentId
        );
      })
      .map((items) => {
        let temp = null;
        if (items.Embed.Homeworks.length > 0) {
          temp = items.Embed.Homeworks.map((e) => {
            return (
              <option key={e.Id} value={e.Id}>
                {e.Topic}
              </option>
            );
          });
        }
        return temp;
      });
  }
  return tmp;
}
