import React from "react";
import styles from "./description.module.css";

import TextField from "@material-ui/core/TextField";

export default function DescriptionCreateHomework(props) {
  const handleDescript = (name) => (event) => {
    props.states.school.journalColumn.createHomework.setDeskription(
      event.target.value
    );
  };
  return (
    <>
      <div className={styles.headerText}>Примітка до домашнього завдання: </div>
      <TextField
        className={styles.select_lesson_one}
        value={props.states.school.journalColumn.createHomework.deskription}
        onChange={handleDescript("name")}
        id="outlined-bare"
        inputProps={{ "aria-label": "bare" }}
        margin="normal"
        variant="outlined"
        multiline={true}
        rowsMax="3"
        rows="3"
      />
    </>
  );
}
