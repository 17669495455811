import React from "react";
import styles from "./blockSelectedTopic.module.css";

import SelectMainTopic from "./selectMainTopic/selectMainTopic.js";
import SelectLessonTopic from "./selectLessonTopic/selectLessonTopic.js";

export default function BlockSelectedTopic(props) {
  return headElemLessonType(props);
}

const headElemLessonType = (props) => {
  let tmp = null;

  let setThem = null;
  let setMainTopic = null;
  if (props.states.school.baseData.columnPopUpData.ColumnTypeId === 18) {
    setThem = (
      <>
        <div className={styles.headerText}>
          Тема:{" "}
          <span>{props.states.school.baseData.columnPopUpData.Topic}</span>
        </div>
      </>
    );
  } else if (props.states.school.baseData.columnPopUpData.ColumnTypeId === 2) {
    setThem = (
      <>
        <div className={styles.headerText}>
          Тема:{" "}
          <span>{props.states.school.baseData.columnPopUpData.Topic}</span>
        </div>
      </>
    );
  } else if (props.states.school.baseData.columnPopUpData.ColumnTypeId === 19) {
    setThem = null;
    setMainTopic = null;
  } else if (props.states.school.baseData.columnPopUpData.ColumnTypeId === 20) {
    setThem = null;
    setMainTopic = null;
  } else if (props.states.school.baseData.columnPopUpData.ColumnTypeId === 21) {
    setThem = null;
    setMainTopic = null;
  } else {
    if (props.states.school.baseData.summary !== true) {
      setMainTopic = <SelectMainTopic states={props.states} />;
      setThem = <SelectLessonTopic states={props.states} />;
    } else {
      setThem = (
        <>
          <div className={styles.headerText}>
            Тема уроку:{" "}
            <span>{props.states.school.baseData.columnPopUpData.Topic}</span>
          </div>
        </>
      );
    }
  }
  tmp = (
    <div>
      {setMainTopic}
      {setThem}
    </div>
  );
  return tmp;
};
