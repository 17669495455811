import React from "react";
import styles from "./file.module.css";

export default function FileSvg() {
  return (
    <div title="Файл">
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="https://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
        viewBox="0 0 56 56"
        className={styles.clsM}
        xmlSpace="preserve"
      >
        <g>
          <path
            className={styles.cls0}
            d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
                        c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
          />
          <polygon
            className={styles.cls1}
            points="37.5,0.151 37.5,12 49.349,12 	"
          />
          <path
            className={styles.cls2}
            d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"
          />
          <circle className={styles.cls3} cx="18.5" cy="47" r="3" />
          <circle className={styles.cls3} cx="28.5" cy="47" r="3" />
          <circle className={styles.cls3} cx="38.5" cy="47" r="3" />
        </g>
      </svg>
    </div>
  );
}
