import React from "react";
import styles from "./selectTypeLesson.module.css";

import SelectForm from "./select/select.js";

export default function SelectTypeLesson(props) {
  return headElemLessonType(props);
}

const headElemLessonType = (props) => {
  let setType = null;
  if (props.states.school.baseData.columnPopUpData.Primary !== 1) {
    if (props.states.school.baseData.columnPopUpData.ColumnTypeId === 18) {
      setType = (
        <>
          <div className={styles.headerText}>
            Тип уроку: <span>Тематична (Системна середня)</span>
          </div>
        </>
      );
    } else if (
      props.states.school.baseData.columnPopUpData.ColumnTypeId === 2
    ) {
      setType = (
        <>
          <div className={styles.headerText}>
            Тип уроку: <span>Тематична</span>
          </div>
        </>
      );
    } else if (
      props.states.school.baseData.columnPopUpData.ColumnTypeId === 19
    ) {
      setType = (
        <>
          <div className={styles.headerText}>
            Тип уроку: <span>Семестрова</span>
          </div>
        </>
      );
    } else if (
      props.states.school.baseData.columnPopUpData.ColumnTypeId === 20
    ) {
      setType = (
        <>
          <div className={styles.headerText}>
            Тип уроку: <span>Семестрова (Системна середня)</span>
          </div>
        </>
      );
    } else if (
      props.states.school.baseData.columnPopUpData.ColumnTypeId === 21
    ) {
      setType = (
        <>
          <div className={styles.headerText}>
            Тип уроку: <span>Семестрова (Скорегована)</span>
          </div>
        </>
      );
    } else {
      setType = <SelectForm states={props.states} type={2} />;
    }
  } else {
    setType = <SelectForm states={props.states} type={1} />;
  }
  return setType;
};
