import React from "react";
import styles from "./selecttargetlesson.module.css";

import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export default function SelectTargetLesson(props) {
  return (
    <>
      <div className={styles.headerText}>
        Урок на який задається домашнє завдання:{" "}
      </div>
      <Select
        className={styles.select_lesson_one}
        native
        value={props.states.school.journalColumn.createHomework.targetLesson}
        onChange={(value) => {
          props.states.school.journalColumn.createHomework.setTargetLesson(
            value.target.value
          ); //new
        }}
        input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
      >
        <option selected disabled key={5677} value={0}>
          Обрати урок
        </option>
        {createTargetHomeworks(props)}
      </Select>
    </>
  );
}

const createTargetHomeworks = (props) => {
  let tmp = null;
  if (props.states.school.popUpFlag.flagTargetApointment === true) {
    tmp = props.states.school.arr.TargetAppointment.map((e) => {
      return (
        <option key={e.Id} value={e.Id}>
          {e.Subject} на {e.Date}
        </option>
      );
    });
  }
  return tmp;
};
