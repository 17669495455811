import React from "react";
import styles from "./viewData.module.css";

export default function ViewData(props) {
  return (
    <div className={styles.dataPopUp}>Дата колонки: {createDate(props)}</div>
  );
}

function createDate(props) {
  let tmp = null;
  if (props.states.school.baseData.columnPopUpData !== null) {
    let tmpArr = props.states.school.baseData.columnPopUpData.Date.split("-");
    tmp = tmpArr[2] + "-" + tmpArr[1] + "-" + tmpArr[0];
  }
  return tmp;
}
