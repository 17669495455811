import React from "react";
import styles from "./button.module.css";

import Button from "@material-ui/core/Button";

export default function ButtonEditHomework(props) {
  return buttonEditView(props);
}

const buttonEditView = (props) => {
  let tmp = null;
  if (props.states.school.journalColumn.editHomework.statertLesson !== null) {
    tmp = (
      <Button
        onClick={() => {
          props.states.school.journalColumn.editHomework.edit();
        }}
        variant="contained"
        color="primary"
        className={styles.button_create}
      >
        Зберегти
      </Button>
    );
  }
  return tmp;
};
