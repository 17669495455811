import React from "react";
import styles from "./closePopUp.module.css";

export default function ClosePopUp(props) {
  let funcPopupProfile = props.states.functions.changed.flags.popupProfile;
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        funcPopupProfile();
      }}
    >
      X
    </div>
  );
}
