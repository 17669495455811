import React from "react";
import styles from "./journalRates.module.css";

export default function JournalRates(props) {
  let tmp = null;

  if (props.states.school.baseData.appointmentNumber !== null) {
    tmp = (
      <div
        className={styles.mainMenu__leftBaseData}
        onClick={() => {
          props.changeStateMenu();
          //props.states.school.journalTopics.getHomeworks();//New
          props.changeStateMainJournal(1);
          props.states.functions.stateTypeJournal(0);
          props.states.functions.menu.reversText(1);
          props.states.functions.getConstData.getPartRates(
            props.states.school.baseData.PartId
          );
          //props.states.functions.setOwnClassFlag(false);
        }}
      >
        {textMenu("Журнал оцінок")}
      </div>
    );
  } else {
    tmp = (
      <div
        title="Для разблокування треба обрати урок"
        className={styles.mainMenu__leftBaseData + " " + styles.mainMenuGrey}
      >
        {textMenu("Журнал оцінок")}
      </div>
    );
  }
  return tmp;
}

function textMenu(text) {
  return (
    <p>
      <svg
        width="30"
        height="27"
        viewBox="0 0 30 27"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M4.10247 22.2811C4.10247 21.2575 3.45505 20.6667 2.32872 20.6667C0.847639 20.6667 0.457414 21.657 0.457414 22.1812C0.457414 22.7887 0.936327 22.8552 1.14918 22.8552C1.55714 22.8552 1.8232 22.5973 1.8232 22.2228C1.8232 22.073 1.87641 21.9066 2.31098 21.9066C2.62139 21.9066 2.74555 21.9482 2.74555 22.3726C2.74555 22.7804 2.648 22.8303 2.28438 22.8303C1.92963 22.8303 1.66356 23.0966 1.66356 23.4461C1.66356 23.7956 1.9385 24.0536 2.29325 24.0536C2.78103 24.0536 2.87858 24.2449 2.87858 24.5445V24.6777C2.87858 25.1853 2.66573 25.2851 2.27551 25.2851C1.73451 25.2851 1.69017 25.0272 1.69017 24.944C1.69017 24.6444 1.47732 24.3365 0.998408 24.3365C0.581577 24.3365 0.333252 24.5778 0.333252 24.9939C0.333252 25.7428 1.01615 26.5334 2.28438 26.5334C3.51713 26.5334 4.24437 25.8427 4.24437 24.6777V24.5445C4.24437 24.0702 4.08473 23.6708 3.77433 23.3878C3.99604 23.0966 4.10247 22.7221 4.10247 22.2811Z"
          fill="black"
        />
        <path
          d="M0.994174 16.5334H3.61933C3.95864 16.5334 4.24437 16.2504 4.24437 15.9009C4.24437 15.5597 3.96757 15.2768 3.61933 15.2768H1.71743V15.2685C1.71743 15.0854 2.17282 14.7775 2.54784 14.5362C3.28003 14.0535 4.19972 13.4544 4.19972 12.4059C4.19972 11.4156 3.37825 10.6667 2.2889 10.6667C1.24419 10.6667 0.458428 11.3324 0.458428 12.2228C0.458428 12.7387 0.815592 12.9218 1.12811 12.9218C1.56564 12.9218 1.83351 12.6139 1.83351 12.3227C1.83351 12.1396 1.88709 11.9316 2.27997 11.9316C2.81571 11.9316 2.83357 12.3726 2.83357 12.4225C2.83357 12.822 2.27997 13.1881 1.74422 13.5459C1.08347 13.987 0.33342 14.4946 0.33342 15.2685V15.9342C0.324491 16.3004 0.672726 16.5334 0.994174 16.5334Z"
          fill="black"
        />
        <path
          d="M1.61709 2.80836V5.9417C1.61709 6.28336 1.9039 6.53336 2.28632 6.53336C2.66005 6.53336 2.95556 6.27503 2.95556 5.9417V1.25835C2.95556 0.925021 2.67743 0.666687 2.3124 0.666687C1.99951 0.666687 1.83437 0.866687 1.78222 0.941688C1.78222 0.941688 1.78222 0.950021 1.77353 0.950021L1.20859 1.71669C1.09561 1.83336 1 2.01669 1 2.19169C1.00869 2.53336 1.27812 2.80836 1.61709 2.80836Z"
          fill="black"
        />
        <path
          d="M9.66659 25.6H28.3333C28.8692 25.6 29.8444 24.7048 29.8444 23.6C29.8444 22.4952 28.8692 21.6 28.3333 21.6H9.66659C9.13065 21.6 8.33325 22.4952 8.33325 23.6C8.33325 24.7048 9.13065 25.6 9.66659 25.6Z"
          fill="black"
        />
        <path
          d="M9.66659 15.6H28.3333C28.8692 15.6 29.8444 14.7048 29.8444 13.6C29.8444 12.4952 28.8692 11.6 28.3333 11.6H9.66659C9.13065 11.6 8.33325 12.4952 8.33325 13.6C8.33325 14.7048 9.13065 15.6 9.66659 15.6Z"
          fill="black"
        />
        <path
          d="M9.66659 5.60001H28.3333C28.8648 5.60001 29.6666 4.70477 29.6666 3.60001C29.6666 2.49524 28.8648 1.60001 28.3333 1.60001H9.66659C9.13508 1.60001 8.33325 2.49524 8.33325 3.60001C8.33325 4.70477 9.13508 5.60001 9.66659 5.60001Z"
          fill="black"
        />
      </svg>
      {text}
    </p>
  );
}
