import React from "react";
import styles from "./summaryTableOfMovementOfStudentsOfClass.module.css";

export default function SummaryTableOfMovementOfStudentsOfClass(props) {
  let tmp = null;

  if (props.states.school.baseData.teacher.OwnClassId !== null) {
    tmp = (
      <div
        className={styles.mainMenu__leftBaseData}
        onClick={() => {
          props.changeStateMenu();
          props.changeStateMainJournal(1);
          props.states.functions.stateTypeJournal(7);
          props.states.functions.menu.reversText(8);
          props.states.functions.getConstData.getOwnClassSchoolboyAndParent();
          props.states.functions.getSchoolboyMoving();
          props.states.functions.setOwnClassFlag(false);
        }}
      >
        {textMenu("Зведена таблиця руху учнів класу")}
      </div>
    );
  } else {
    tmp = (
      <div
        className={styles.mainMenu__leftBaseData + " " + styles.mainMenuGrey}
      >
        {textMenu("Зведена таблиця руху учнів класу")}
      </div>
    );
  }

  return tmp;
}

function textMenu(text) {
  return (
    <p>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M31.8159 9.41699L25.1213 0.379395C24.9443 0.140869 24.665 0 24.3679 0C24.0708 0 23.7913 0.140869 23.6147 0.379395L16.9202 9.41699C16.7095 9.70142 16.677 10.0806 16.8364 10.3967C16.9956 10.7129 17.3196 10.9126 17.6736 10.9126H20.0833V15.4438C19.6113 15.2007 19.0769 15.0625 18.5103 15.0625H16.8367C14.9355 15.0625 13.3889 16.6091 13.3889 18.5103V20.4646C12.917 20.2214 12.3823 20.0833 11.8159 20.0833H10.1423C8.24121 20.0833 6.69458 21.6301 6.69458 23.5312V25.4854C6.22241 25.2424 5.68799 25.104 5.12158 25.104H3.44775C1.54663 25.104 0 26.6509 0 28.552V31.0625C0 31.5803 0.419678 32 0.9375 32H27.7151C28.2329 32 28.6526 31.5803 28.6526 31.0625V10.9126H31.0625C31.4165 10.9126 31.7405 10.7129 31.8997 10.3967C32.0591 10.0806 32.0266 9.70142 31.8159 9.41699ZM1.875 28.552C1.875 27.6848 2.58057 26.979 3.44775 26.979H5.12134C5.98877 26.979 6.69434 27.6848 6.69434 28.552V30.125H1.875V28.552ZM8.56934 28.552V23.5312C8.56934 22.6638 9.2749 21.9583 10.1423 21.9583H11.8159C12.6831 21.9583 13.3887 22.6638 13.3887 23.5312V30.125H8.56934V28.552ZM15.2639 23.5312V18.5103C15.2639 17.6431 15.9695 16.9375 16.8367 16.9375H18.5103C19.3774 16.9375 20.0833 17.6431 20.0833 18.5103V30.125H15.2639V23.5312ZM27.7151 9.0376C27.1973 9.0376 26.7776 9.45728 26.7776 9.9751V30.125H21.9583V9.9751C21.9583 9.45728 21.5383 9.0376 21.0208 9.0376H19.5347L24.3679 2.51245L29.2014 9.0376H27.7151Z"
          fill="black"
        />
      </svg>
      {text}
    </p>
  );
}
