import React from "react";
import styles from "./journalCreateTopics.module.css";

export default function JournalCreateTopics(props) {
  let tmp = null;

  if (props.states.school.baseData.appointmentNumber !== null) {
    tmp = (
      <div
        className={styles.mainMenu__leftBaseData}
        onClick={() => {
          if (props.states.school.baseData.appointmentNumber !== null) {
            props.states.functions.get.column.dataTopic();
            props.changeStateMenu();
            props.states.functions.changed.flags.changeFlagCreateTopic(true);
            props.states.functions.post.themes.getPartAppointments();
            //props.states.functions.setOwnClassFlag(false);
          }
        }}
      >
        {textMenu("Створити тему")}
      </div>
    );
  } else {
    tmp = (
      <div
        title="Для разблокування треба обрати урок"
        className={styles.mainMenu__leftBaseData + " " + styles.mainMenuGrey}
      >
        {textMenu("Створити тему")}
      </div>
    );
  }
  return tmp;
}

function textMenu(text) {
  return (
    <p>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15" stroke="black" strokeWidth="2" />
        <path
          d="M16.6953 14.8984H19.8672V16.2656H16.6953V19.8594H15.2422V16.2656H12.0703V14.8984H15.2422V11.5781H16.6953V14.8984Z"
          fill="black"
        />
      </svg>
      {text}
    </p>
  );
}
