import React from "react";
import styles from "./select.module.css";

import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export default function SelectTypeLesson(props) {
  return (
    <>
      <div className={styles.headerText}>Тип уроку: </div>
      <div className={styles.homework_wrap_slide_one}>
        <Select
          className={styles.select_lesson_one}
          native
          value={props.states.school.journalColumn.updateColumn.typeLesson}
          onChange={(value) => {
            props.states.school.journalColumn.updateColumn.setTypeLesson(
              value.target.value
            ); //new
            props.states.school.journalColumn.updateColumn.updateType(
              value.target.value
            ); //new
          }}
          input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
        >
          <option selected disabled key={5677} value={0}>
            Обрати тип колонки
          </option>
          {selectLessonType(props, props.type)}
        </Select>
      </div>
      <div className={styles.helpText}>
        Для зміни типу уроку оберіть необхідний зі списку
      </div>
    </>
  );
}

const selectLessonType = (props, data) => {
  let tmp = null;
  if (data === 1) {
    if (props.states.school.arr.typeColumnArr !== null) {
      tmp = props.states.school.arr.typeColumnArr
        .filter((item) => {
          return item.Primary === true;
        })
        .map((item) => {
          return (
            <option key={item.Id} value={item.Id}>
              {item.Title}
            </option>
          );
        });
    }
  } else if (data === 2) {
    if (props.states.school.arr.typeColumnArr !== null) {
      tmp = props.states.school.arr.typeColumnArr
        .filter((item) => {
          return item.Summary === false;
        })
        .map((item) => {
          return (
            <option key={item.Id} value={item.Id}>
              {item.Title}
            </option>
          );
        });
    }
  }
  return tmp;
};
