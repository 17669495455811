import React from "react";
import styles from "./viewHomework.module.css";

import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import Pdf from "./files/pdf.js";
import Rtf from "./files/pdf.js";
import Png from "./files/png.js";
import File from "./files/file.js";
import Doc from "./files/doc.js";
import Fhtml from "./files/html.js";

/*import xls from './files/004-xls.svg';
import jpg from './files/005-jpg.svg';
import ppt from './files/006-powerpoint.svg';
import zip from './files/008-zip.svg';
import txt from './files/009-txt.svg';
import mp3 from './files/010-mp3.svg';
import mp4 from './files/011-mp4.svg';
import avi from './files/013-avi.svg';*/

export default function ViewHomework(props) {
  return createSelectTypeColumn(props);
}

function getArrPartHomework(homework, appointId) {
  return homework
    .filter((e) => {
      return e.Id === appointId;
    })
    .filter((e) => {
      return e.Embed.Homeworks.length > 0;
    });
}

const createSelectTypeColumn = (props) => {
  let tmp = null;
  let idColumn = null;
  let topic = null;
  let dicript = null;
  let dataStart = null;
  let dataEnd = null;

  if (props.states.school.journalTopics.partHomework !== null) {
    if (props.states.school.journalTopics.partHomework.length > 0) {
      tmp = getArrPartHomework(
        props.states.school.journalTopics.partHomework,
        props.states.school.baseData.columnPopUpData.AppointmentId
      );
      /*tmp = props.states.school.journalTopics.partHomework
            .filter(
                (e) => {
                    return e.Id === props.states.school.baseData.columnPopUpData.AppointmentId
                }
            )
            .filter(
                (e) => {
                    return e.Embed.Homeworks.length > 0
                }
            )*/

      if (tmp.length > 0) {
        tmp = tmp[0].Embed.Homeworks.map((item) => {
          idColumn = item.Id;
          topic = item.Topic;
          dicript = item.Description;
          dataStart = item.AppointmentDate.split("-");
          dataEnd = item.TargetAppointmentDate.split("-");
          return (
            <div className={styles.popup_lesson_wrap}>
              <div className={styles.themes}>
                <div className={styles.themes_text}>
                  <div>
                    <span>Тема домашнього завдання: </span>
                    {topic}
                  </div>
                  <div>
                    <span>Опис домашнього завдання: </span>
                    {dicript}
                  </div>
                </div>
                <Button
                  onClick={() => {
                    props.states.functions.post.homework.deleteHomework(
                      idColumn
                    );
                    props.states.functions.changed.flags.changeFlagViewDZ(
                      false
                    );
                  }}
                  variant="contained"
                  color="secondary"
                  className={styles.button}
                >
                  Видалити
                  <DeleteIcon className={styles.rightIcon} />
                </Button>
              </div>
              <div className={styles.dataWrap}>
                <div>
                  <span>Дата завдання: </span>
                  {dataStart[2] + "-" + dataStart[1] + "-" + dataStart[0]}
                </div>
                <div>
                  <span>Дата виконання завдання: </span>
                  {dataEnd[2] + "-" + dataEnd[1] + "-" + dataEnd[0]}
                </div>
              </div>
              <div className={styles.themes}>
                <div>
                  <div>Додані файли</div>
                  <div className={styles.groopFiles}>
                    {/*viewDZ( props, item )*/}
                    {newViewHomework(props, idColumn)}
                  </div>
                </div>
                <div className={styles.files}>
                  <input
                    className={styles.input + " " + styles.files_input}
                    id="text-button-file"
                    name="fileUp"
                    multiple
                    type="file"
                    onChange={(e) => {
                      selectFiles(props, e);
                    }}
                  />
                  <label htmlFor="text-button-file">
                    <Button
                      component="span"
                      variant="contained"
                      color="default"
                      className={styles.button}
                      onClick={() => {
                        props.states.functions.homeforkFilesUploadPath(item.Id);
                      }}
                    >
                      Додати
                      <CloudUploadIcon className={styles.rightIcon} />
                    </Button>
                  </label>
                </div>
              </div>
            </div>
          );
        });
        //tmp = <div>Чет есть</div>
      } else {
        tmp = <div>Домашні завдання відсутні</div>;
      }
    } else {
      tmp = <div>Домашні завдання відсутні 1</div>;
    }
  } else {
    tmp = <div>Домашні завдання відсутні 2</div>;
  }
  /*if( props.states.school.popUpFlag.flagHomework === true ) {
        if( props.states.school.arr.homework.length !== 0 ) {
            tmp = props.states.school.arr.homework.map(
                ( item ) => {
                    idColumn = item.Id;
                    topic = item.Topic;
                    dicript = item.Description;
                    dataStart = item.AppointmentDate.split( '-' );
                    dataEnd = item.TargetAppointmentDate.split( '-' );
                    return (
                        <div className = { styles.popup_lesson_wrap } >
                            <div className = { styles.themes } >
                                <div className = { styles.themes_text } >
                                    <div><span>Тема домашнього завдання: </span>{ topic }</div>
                                    <div><span>Опис домашнього завдання: </span>{ dicript }</div>
                                </div>
                                <Button
                                    onClick = {
                                        () => {
                                            props.states.functions.post.homework.deleteHomework(idColumn);
                                            props.states.functions.changed.flags.changeFlagViewDZ(false);
                                        }
                                    }
                                    variant = "contained" color = "secondary" className = { styles.button }
                                >
                                    Видалити
                                    <DeleteIcon className = { styles.rightIcon } />
                                </Button>
                            </div>
                            <div className = { styles.dataWrap } >
                                <div><span>Дата завдання: </span>{ dataStart[2] + '-' + dataStart[1] + '-' + dataStart[0] }</div>
                                <div><span>Дата виконання завдання: </span>{ dataEnd[2] + '-' + dataEnd[1] + '-' + dataEnd[0] }</div>
                            </div>
                            <div className = { styles.themes } >
                                <div>
                                    <div>Додані файли</div>
                                    <div className = { styles.groopFiles } >
                                        { viewDZ( props, item ) }
                                    </div>
                                </div>
                                <div className = { styles.files } >
                                    <input
                                        accept = "image/*"
                                        className = { styles.input + ' ' + styles.files_input }
                                        id = "text-button-file"
                                        name = "fileUp"
                                        multiple
                                        type = "file"
                                    
                                        onChange = {
                                        (e) => {
                                            selectFiles( props, e )
                                        }
                                        }
                                    />
                                    <label htmlFor = "text-button-file" >
                                        <Button component = "span" variant = "contained" color = "default" className = { styles.button }
                                            onClick = {
                                                () => {
                                                    props.states.functions.homeforkFilesUploadPath( item.Id );
                                                }
                                            }
                                        >
                                            Додати
                                            <CloudUploadIcon className = { styles.rightIcon } />
                                        </Button>
                                    </label>
                                </div>
                            </div>
                        </div>
                    )
                }
            );
        } else {
            tmp = <div>Домашні завдання відсутні</div>
        }
    } else {
        tmp = <div>Домашні завдання відсутні</div>
    }*/
  return tmp;
};

const postFiles = (_url, data, props) => {
  return new Promise(function (resolve, reject) {
    let request = new XMLHttpRequest();
    let fd = new FormData();
    let jsonData;

    fd.append("upload-file", data);

    request.open("POST", _url);

    request.setRequestHeader(
      "X-Auth-Token",
      props.states.school.baseData.token
    );
    request.setRequestHeader("Api-key", props.states.school.baseData.apikey);

    request.onload = function () {
      if (request.status === 200 && request.readyState === 4) {
        jsonData = JSON.parse(request.responseText);
        resolve(jsonData);
      } else {
        reject(request.status);
      }
    };
    request.onerror = function (error) {
      reject(error);
    };
    request.send(fd);
  });
};

const selectFiles = (props, value) => {
  let tempUrl =
    props.states.school.baseData.domen +
    "/v1/" +
    props.states.school.baseData.teacher.SchoolId +
    "/Homework/" +
    props.states.school.baseData.homeworkFilesUpload +
    "/UploadFile";
  for (let i = 0; i < value.target.files.length; i++) {
    postFiles(tempUrl, value.target.files[i], props)
      .then(
        (body) => {
          return body;
        },
        (err) => {
          if (err.type === "error") {
          } else if (err === 404 || err === 403) {
          }
        }
      )
      .then(() => {
        props.states.school.journalTopics.setArrFiles();
      })
      .then(() => {
        props.states.functions.get.column.getTargetAppointmentsHomework();
        props.states.functions.get.column.getHomework();
        props.states.school.journalTopics.getHomeworks(
          props.states.school.baseData.PartId
        );
      });
  }
};

function newViewHomework(props, homeworkId) {
  let tmp = null;
  if (props.states.school.arr.PartHW.length > 0) {
    for (let t = 0; t < props.states.school.arr.PartHW.length; t++) {
      if (props.states.school.arr.PartHW[t].Id === homeworkId) {
        if (props.states.school.arr.PartHW[t].Embed.Files !== null) {
          if (props.states.school.arr.PartHW[t].Embed.Files.length > 0) {
            tmp = props.states.school.arr.PartHW[t].Embed.Files.map((items) => {
              let test = items.Name.split(".");
              return (
                <div className={styles.files_save}>
                  <div className={styles.files_icon}>
                    {failesIcon(test[test.length - 1])}
                  </div>
                  <div className={styles.files_title}>{items.Name}</div>
                  <Button
                    onClick={() => {
                      props.states.functions.post.homework.deleteHomeworkFiles(
                        items.Id,
                        homeworkId
                      );
                    }}
                    variant="contained"
                    color="secondary"
                    className={styles.button + " " + styles.deleteFiles}
                  >
                    <DeleteIcon className={styles.rightIcon} />
                  </Button>
                </div>
              );
            });
            return tmp;
          } else {
            tmp = <div>Файли відсутні</div>;
          }
        } else {
          tmp = <div>Файли відсутні</div>;
        }
      } else {
        tmp = <div>Файли відсутні</div>;
      }
    }
  }
  return tmp;
}

const viewDZ = (props, item) => {
  let tmp = null;

  if (props.states.school.popUpFlag.flagHomeworkViewDZ === true) {
    if (item.hasOwnProperty("filesDZ")) {
      if (item.filesDZ !== null) {
        if (item.filesDZ.length !== 0) {
          tmp = item.filesDZ.map((items) => {
            let test = items.Name.split(".");
            return (
              <div className={styles.files_save}>
                <div className={styles.files_icon}>
                  {failesIcon(test[test.length - 1])}
                </div>
                <div className={styles.files_title}>{items.Name}</div>
                <Button
                  onClick={() => {
                    props.states.functions.post.homework.deleteHomeworkFiles(
                      items.Id,
                      item.Id
                    );
                  }}
                  variant="contained"
                  color="secondary"
                  className={styles.button + " " + styles.deleteFiles}
                >
                  <DeleteIcon className={styles.rightIcon} />
                </Button>
              </div>
            );
          });
        } else {
          tmp = <div>Файли відсутні</div>;
        }
      }
    } else {
    }
  }
  return tmp;
};

const failesIcon = (value) => {
  switch (value) {
    case "pdf":
      return <Pdf />;
    case "doc":
      return <Doc />;
    case "html":
      return <Fhtml />;
    /*case 'xls' :
            return <img className = { styles.imgIcon } src = { xls } alt = "Файл формату xls"></img>
        case 'jpg' :
            return <img className = { styles.imgIcon } src = { jpg } alt = "Файл формату jpg"></img>
        case 'ppt' :
            return <img className = { styles.imgIcon } src = { ppt } alt = "Файл формату ppt"></img>
        */ case "png":
      return <Png />;
    /*case 'zip' :
            return <img className = { styles.imgIcon } src = { zip } alt = "Файл формату zip"></img>
        case 'txt' :
            return <img className = { styles.imgIcon } src = { txt } alt = "Файл формату txt"></img>
        case 'mp3' :
            return <img className = { styles.imgIcon } src = { mp3 } alt = "Файл формату mp3"></img>
        case 'mp4' :
            return <img className = { styles.imgIcon } src = { mp4 } alt = "Файл формату mp4"></img>
        case 'avi' :
            return <img className = { styles.imgIcon } src = { avi } alt = "Файл формату avi"></img>
        */ case "rtf":
      return <Rtf />;
    default:
      return <File />;
  }
};
