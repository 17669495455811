import React from "react";
import styles from "./buttonClosePopup.module.css";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

export default function ButtonClosePopup(props) {
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        props.states.school.journalColumn.clear();
        props.states.functions.changed.flags.popUpHomework(false);
      }}
    >
      X
    </div>
  );
}
