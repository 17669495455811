import React from 'react';
import Loader from 'react-loader-spinner';
import css from './loaderSpinner.module.scss';

import imgBGC from './Screenshot_new.webp';

const styleBGC = {
  backgroundImage: `url(${imgBGC})`,
};

const LoaderSpinner = ({ bg = false }) => (
  <>
   {bg && <div style={styleBGC} className={css.logoPaige}></div>}
    <div className={css.container}>
      <div>
        <Loader
          type='Oval'
          color='#51619c'
          height={100}
          width={100}
          // timeout={3000}
          className={css.loader}
        />
      </div>
    </div>
  </>
);

export default LoaderSpinner;
