import React from "react";
import styles from "./popUpDeleteTopic.module.css";

import Button from "@material-ui/core/Button";

export default function PopUpCreateTopic(props) {
  return (
    <div className={styles.mainRateBlock}>
      <div className={styles.block}>
        {ClosePopUp(props)}
        {BlockText()}
        {BlockButtonCancelAndOk(props)}
      </div>
    </div>
  );
}

/**
 * Функция закрытия попапа
 * @param {*} props
 */
function ClosePopUp(props) {
  let funcSrtTypeColumn = props.states.functions.deleteTopic;
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        funcSrtTypeColumn(false);
      }}
    >
      X
    </div>
  );
}

/**
 * Функция вывода текста в попапе
 */
function BlockText() {
  return (
    <div className={styles.textHelp}>
      <span>Увага!</span>
      <br />
      Ви впевнені що хочете видалити тему?
      <br />
      Видаляти можливо лише неприв'язані теми.
    </div>
  );
}

/**
 * Функция выводит кнопки Отмена и Принять
 * @param {*} props
 */
function BlockButtonCancelAndOk(props) {
  let funcSrtTypeColumn = props.states.functions.deleteTopic;
  const stylesLine = {
    width: "218px",
    height: "50px",
  };
  return (
    <div className={styles.buttonHelp}>
      <Button
        style={stylesLine}
        variant="contained"
        color="primary"
        onClick={() => {
          funcSrtTypeColumn(false);
        }}
      >
        Скасувати
      </Button>
      <Button
        style={stylesLine}
        variant="contained"
        color="primary"
        onClick={() => {
          funcSrtTypeColumn(false);
          props.states.functions.post.themes.deleteTopic(
            props.states.school.baseData.appointThemeTmpEdit
          );
        }}
      >
        Підтвердити
      </Button>
    </div>
  );
}
