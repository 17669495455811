import React from "react";
import styles from "./lang.module.css";

export default function PrimarySearchAppBar(props) {
  let langType = null;
  /*let langType = [
        {type: 'EN'},
        {type: 'UA'},
    ];*/
  return langType; /*(
        <div className={styles.button_lang} onClick={
            () => {
                if(props.states.school.baseData.langTypeFlag === 0){
                    props.states.functions.stateTypeLang(1);
                }else if(props.states.school.baseData.langTypeFlag === 1){
                    props.states.functions.stateTypeLang(0);
                }
            }
        }>
            {langType[props.states.school.baseData.langTypeFlag].type}
        </div>
    )*/
}
