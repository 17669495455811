import React from "react";
import styles from "./bindTopic.module.css";

import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export default function BindTopic(props) {
  return (
    <div className={styles.wrapBlok}>
      <div className={styles.popup_div_selected}>
        <div className={styles.popup_descrip}>Тема</div>
        <Select
          className={styles.selectFiles}
          native
          value={props.states.school.journalTopics.bindLesson.parentId}
          onChange={(value) => {
            props.states.school.journalTopics.bindLesson.setParentId(
              value.target.value
            );
          }}
          input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
        >
          <option selected disabled key={5677} value={0}>
            Обрати тему
          </option>
          {bindSelectMainTopic(props)}
        </Select>
      </div>
      <div className={styles.popup_div_selected}>
        <div className={styles.popup_descrip}>Тема урока</div>
        <Select
          className={styles.selectFiles}
          native
          value={props.states.school.journalTopics.bindLesson.lessonTopicId}
          onChange={(value) => {
            props.states.school.journalTopics.bindLesson.setLessonTopicId(
              value.target.value
            );
          }}
          input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
        >
          <option selected disabled key={5677} value={0}>
            Обрати тему урока
          </option>
          {bindSelectLessonTopic(props)}
        </Select>
      </div>
      {createButtonBindThemes(props)}
    </div>
  );
}

function createButtonBindThemes(props) {
  let tmp = null;
  if (
    props.states.school.journalTopics.bindLesson.parentId !== null &&
    props.states.school.journalTopics.bindLesson.lessonTopicId !== null
  ) {
    tmp = (
      <Button
        onClick={() => {
          props.states.school.journalTopics.bindLesson.bindLessonTopic();
          props.states.functions.changed.flags.changeFlagCreateTopic(false);
        }}
        variant="contained"
        color="primary"
        className={styles.Button}
      >
        Створити
      </Button>
    );
  }
  return tmp;
}

function bindSelectMainTopic(props) {
  let tmp = null;
  if (props.states.school.journalTopics.partTopics !== null) {
    tmp = props.states.school.journalTopics.partTopics
      .filter((e) => {
        return e.ParentId === null;
      })
      .map((e) => {
        return (
          <option key={e.Id} value={e.Id}>
            {e.Title}
          </option>
        );
      });
  }
  return tmp;
}

function bindSelectLessonTopic(props) {
  let tmp = null;
  if (props.states.school.journalTopics.partTopics !== null) {
    tmp = props.states.school.journalTopics.partTopics
      .filter((e) => {
        return e.ParentId === -1;
      })
      .map((e) => {
        return (
          <option key={e.Id} value={e.Id}>
            {e.Title}
          </option>
        );
      });
  }
  return tmp;
}
