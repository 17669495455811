import React from "react";
import styles from "./popUpReplacementMissing.module.css";

import Button from "@material-ui/core/Button";

export default function PopUpReplacementMissing(props) {
  return (
    <div className={styles.mainRateBlock}>
      <div className={styles.block}>
        <div>
          Відсутність протягом дня
          {createButtonMassMissing(props, "Н", 1, "")}
          {createButtonMassMissing(props, "П/П", 2, "primary")}
          {createButtonMassMissing(props, "Х/В", 3, "")}
          {createButtonMassMissing(props, "Б/П", 4, "secondary")}
        </div>
        {closePopUp(props)}
        <div className={styles.popup_topic}>
          <div>Уроки: {props.states.school.baseData.setMissingDate}</div>
        </div>
        <div className={styles.popup_wrap_scroling}>
          {createMissingAppointments(props)}
        </div>
      </div>
    </div>
  );
}

function closePopUp(props) {
  let funcPopUpMissingPicker =
    props.states.functions.changed.flags.popUpMissingPicker;
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        funcPopUpMissingPicker(null, null);
      }}
    >
      X
    </div>
  );
}

function createMissingAppointments(props) {
  let tmpMissingArrSec = props.states.school.missing.tmpMissingArrSec;
  let tmpMissingArr = props.states.school.missing.tmpMissingArr;
  let funcRateMissUnrateMis =
    props.states.functions.post.rate.rateMissUnrateMis;
  let funcPopUpMissingPicker =
    props.states.functions.changed.flags.popUpMissingPicker;
  let tmp = tmpMissingArrSec.map((item) => {
    let tmpMiss = tmpMissingArr.filter((items) => {
      return item.Id === items.ColumnId;
    });
    let rate = null;
    if (tmpMiss.length > 0) {
      rate = tmpMiss[0].MissingTypeId;
    }
    let tmpButton = null;
    if (rate !== null) {
      tmpButton = (
        <Button
          variant="contained"
          color="secondary"
          className={styles.popup_button + " " + styles.popup_button_small}
          onClick={() => {
            funcRateMissUnrateMis(
              item.Id,
              item.AppointmentId,
              item.SchoolboyId
            );
            funcPopUpMissingPicker(null, null);
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="https://www.w3.org/2000/svg"
          >
            <path
              fill="#ffffff"
              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M9,8H11V17H9V8M13,8H15V17H13V8Z"
            />
          </svg>
        </Button>
      );
    }
    return (
      <div className={styles.popup_lesson_wrap}>
        <div className={styles.popup_lesson}>{item.Subject}</div>
        <div className={styles.popup_missing_type}>{missingTypeView(rate)}</div>
        {tmpButton}
        {createButtonSingleMissing(props, item, "П/П", 2, "primary")}
        {createButtonSingleMissing(props, item, "Х/В", 3, "")}
        {createButtonSingleMissing(props, item, "Б/П", 4, "secondary")}
      </div>
    );
  });
  return tmp;
}

function missingTypeView(data) {
  let tmp = null;
  if (1 === data) {
    tmp = "Н";
  } else if (2 === data) {
    tmp = "ПП";
  } else if (3 === data) {
    tmp = "ХВ";
  } else if (4 === data) {
    tmp = "БП";
  }
  return tmp;
}

function createButtonSingleMissing(props, item, text, type, color) {
  let domen = props.states.school.baseData.domen;
  let schoolId = props.states.school.baseData.teacher.SchoolId;
  let funcPostMissing = props.states.functions.getConstData.postMissing;
  let funcPopUpMissingPicker =
    props.states.functions.changed.flags.popUpMissingPicker;
  //let tempUrl = 'https://eschool-ua.com:4433/v1/' + props.states.school.baseData.teacher.SchoolId + '/Column/' + item.ColumnId + '/Missing';
  let tempUrl =
    props.states.school.baseData.domen +
    "/v1/" +
    //let tempUrl = 'https://eschool-ua.com:3344/v1/' +
    props.states.school.baseData.teacher.SchoolId +
    "/Column/" +
    item.Id +
    "/Missing";
  //let tempUrl = domen + schoolId + '/Column/' + item.Id + '/Missing';
  return (
    <Button
      variant="contained"
      color={color}
      className={styles.popup_button}
      onClick={() => {
        let dataJS = JSON.stringify({
          SchoolBoyId: item.SchoolboyId,
          AppointmentId: item.AppointmentId,
          MissingTypeId: type,
          TopicId: item.TopicId,
        });
        funcPostMissing(tempUrl, dataJS);
        funcPopUpMissingPicker(null, null);
      }}
    >
      {text}
    </Button>
  );
}

/**
 * Функция создает строку с данными для отправки
 * @param {*} item
 * @param {*} type
 */
function CreateJSONString(item, type) {
  return JSON.stringify({
    SchoolBoyId: item.SchoolboyId,
    AppointmentId: item.AppointmentId,
    MissingTypeId: type,
    TopicId: item.TopicId,
  });
}

function createButtonMassMissing(props, text, type, color) {
  let allMissedAppointmentSingeSchoolboy =
    props.states.school.arr.allMissedAppointmentSingeSchoolboy;
  let funcGetSchoolboyAppointmentPart =
    props.states.functions.getSchoolboyAppointmentPart;
  let funcPopUpMissingPicker =
    props.states.functions.changed.flags.popUpMissingPicker;
  return (
    <Button
      variant="contained"
      color={color}
      className={styles.popup_button}
      onClick={() => {
        for (
          let i = 0;
          i < allMissedAppointmentSingeSchoolboy.Items.length;
          i++
        ) {
          funcGetSchoolboyAppointmentPart(
            allMissedAppointmentSingeSchoolboy.Items[i].Id,
            type
          );
        }
        funcPopUpMissingPicker(null, null);
      }}
    >
      {text}
    </Button>
  );
}
