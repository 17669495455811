import React from "react";
import styles from "./semestr.module.css";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginTop: 5,
  },
}));

export default function Semestr(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
  let buttomSem = null;
  if (props.states.school.journalColumn.createSemestrColumn.semestr === null) {
    buttomSem = (
      <div>
        <div className={styles.popup_topic}>Семестр</div>
        <div className={styles.semestr_topic}>
          <div
            onClick={() => {
              props.states.school.journalColumn.createSemestrColumn.setSemestr(
                1
              );
            }}
          >
            1
          </div>
          <div
            onClick={() => {
              props.states.school.journalColumn.createSemestrColumn.setSemestr(
                2
              );
            }}
          >
            2
          </div>
        </div>
      </div>
    );
  } else if (
    props.states.school.journalColumn.createSemestrColumn.semestr === 1
  ) {
    buttomSem = (
      <div>
        <div className={styles.popup_topic}>Семестр</div>
        <div className={styles.semestr_topic}>
          <div
            className={styles.semestr_topicActiv}
            onClick={() => {
              props.states.school.journalColumn.createSemestrColumn.setSemestr(
                1
              );
            }}
          >
            1
          </div>
          <div
            onClick={() => {
              props.states.school.journalColumn.createSemestrColumn.setSemestr(
                2
              );
            }}
          >
            2
          </div>
        </div>
      </div>
    );
  } else if (
    props.states.school.journalColumn.createSemestrColumn.semestr === 2
  ) {
    buttomSem = (
      <div>
        <div className={styles.popup_topic}>Семестр</div>
        <div className={styles.semestr_topic}>
          <div
            onClick={() => {
              props.states.school.journalColumn.createSemestrColumn.setSemestr(
                1
              );
            }}
          >
            1
          </div>
          <div
            className={styles.semestr_topicActiv}
            onClick={() => {
              props.states.school.journalColumn.createSemestrColumn.setSemestr(
                2
              );
            }}
          >
            2
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.popup_topic_scrol}>
      {buttomSem}
      {createSemestr(props)}
    </div>
  );
}

/**
 * Создает кноку закрыть семестр
 * @param {*} props
 */
function createSemestr(props) {
  let tmp = null;
  let funcCreateSemestrColumn =
    props.states.functions.post.column.createSemestrColumn;
  let createSemestr = props.states.functions.createSemestr;
  if (props.states.school.journalColumn.createSemestrColumn.semestr !== null) {
    tmp = (
      <div className={styles.popup_button}>
        <Button
          onClick={() => {
            createSemestr(true);
          }}
          variant="contained"
          color="secondary"
          className={styles.Button}
        >
          Закрити семестр
        </Button>
      </div>
    );
  }
  return tmp;
}
