import React from "react";
import Cell from "./Cell/Cell";
import styles from "./BlockUp.module.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  Button: {
    width: "218px",
    height: "50px",
  },
  root: {
    width: "198px",
    height: "212px",
  },
}));

function genRateButton(props) {
  let temp = props.states.school.arr.rateValueArr.map((item) => {
    return <Cell states={props.states} mark={item.Value} markT={item.Title} />;
  });
  return temp;
}

export default function BlockUp(props) {
  const classes = useStyles();
  let rightSideText = null;
  if (props.states.school.baseData.popUpDataRate.typeId === 2) {
    rightSideText = null;
  } else if (props.states.school.baseData.popUpDataRate.typeId === 18) {
    rightSideText = null;
  } else if (props.states.school.baseData.popUpDataRate.typeId === 19) {
    rightSideText = null;
  } else if (props.states.school.baseData.popUpDataRate.typeId === 20) {
    rightSideText = null;
  } else if (props.states.school.baseData.popUpDataRate.typeId === 21) {
    rightSideText = null;
  } else {
    rightSideText = (
      <div className={styles.block_discript}>
        <div>Примітка</div>
        {createTextFieldNoteForRate(props)}
        <div className="bottomForms">
          {saveNotes(props, classes)}
          {renderPopUp(props)}
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <div>
        <div className={styles.titles}>
          {viewData(props)}
          {viewName(props)}
        </div>
        <div>Тема: {viewThemesColum(props)}</div>
        {buttonExit(props)}
        <div className={styles.blockAll}>
          <div className={styles.block_rate}>
            {genRateButton(props)}
            <div>
              {createButtonClearRate(props, classes)}
              {createButtonMissing(props, classes)}
            </div>
          </div>
          {rightSideText}
        </div>
      </div>
    </Fragment>
  );
}

function viewThemesColum(props) {
  let tmp = props.states.school.arr.partRates
    .filter((item) => {
      return props.states.school.baseData.popUpDataRate.journalId === item.Id;
    })
    .map((item) => {
      return item.Topic;
    });
  return tmp;
}

function viewData(props) {
  let tmp = null;
  if (props.states.school.baseData.hasOwnProperty("popUpDataRate")) {
    if (props.states.school.baseData.popUpDataRate.hasOwnProperty("data")) {
      let tmpDay = props.states.school.baseData.popUpDataRate.data.split("-");
      tmp = <div>За: {tmpDay[2] + "-" + tmpDay[1] + "-" + tmpDay[0]}</div>;
    }
  }
  return tmp;
}

function viewName(props) {
  let tmp = props.states.school.arr.partSchoolboy
    .filter((item) => {
      return item.Id === props.states.school.baseData.popUpDataRate.schoolBoyId;
    })
    .map((item) => {
      return (
        <div>
          {item.LastName} {item.FirstName} {item.SecondName}
        </div>
      );
    });
  return tmp;
}

function saveNotes(props, classes) {
  return (
    <div className="button">
      <Button
        variant="contained"
        color="primary"
        className={classes.Button}
        onClick={() => {
          let journal = props.states.school.baseData.popUpDataRate.journalId;
          let schoolBoy =
            props.states.school.baseData.popUpDataRate.schoolBoyId;
          let inputChec = false;
          for (let i = 0; i < props.states.school.arr.partRates.length; i++) {
            if (props.states.school.arr.partRates[i].Id === journal) {
              for (
                let j = 0;
                j < props.states.school.arr.partRates[i].Embed.Rates.length;
                j++
              ) {
                if (
                  props.states.school.arr.partRates[i].Embed.Rates[j]
                    .SchoolBoyId === schoolBoy
                ) {
                  inputChec =
                    props.states.school.arr.partRates[i].Embed.Rates[j]
                      .Homework === 1
                      ? true
                      : false;
                }
              }
            }
          }
          props.states.functions.post.rate.rateComment(
            props.states.school.baseData.popUpDataRate.journalId,
            props.states.school.baseData.popUpDataRate.schoolBoyId,
            inputChec,
            props.states.school.baseData.popUpDataRate.description
          );
        }}
      >
        Зберегти примітку
      </Button>
    </div>
  );
}

function buttonExit(props) {
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        props.states.functions.changed.flags.popUpRatesPicker();
        props.states.functions.post.rate.popUpDataRateClear();
      }}
    >
      X
    </div>
  );
}

function createButtonClearRate(props, classes) {
  if (props.states.school.baseData.popUpDataRate !== null) {
    if (
      (props.states.school.baseData.popUpDataRate.linkRateId !== undefined &&
        props.states.school.baseData.popUpDataRate.rate !== 0) ||
      props.states.school.baseData.popUpDataRate.missingId !== null
    ) {
      return (
        <Button
          className={classes.button + " " + styles.vidutnii}
          variant="contained"
          color="secondary"
          onClick={() => {
            props.states.functions.post.rate.switchClear(
              props.states.school.baseData.popUpDataRate.journalId,
              props.states.school.baseData.popUpDataRate.schoolBoyId
            );
            props.states.functions.changed.flags.popUpRatesPicker();
          }}
        >
          Вилучити
        </Button>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function createButtonMissing(props, classes) {
  if (props.states.school.baseData.popUpDataRate.flagMain === 1) {
    return (
      <Button
        className={classes.button + " " + styles.vidutnii}
        variant="contained"
        color="secondary"
        onClick={() => {
          props.states.functions.post.rate.rateMiss(
            props.states.school.baseData.popUpDataRate.journalId,
            props.states.school.baseData.popUpDataRate.schoolBoyId
          );
          props.states.functions.changed.flags.popUpRatesPicker();
        }}
      >
        Відсутній
      </Button>
    );
  }
}

/**
 * Создаем поле примесание для оценок
 * @param {*} props
 */
function createTextFieldNoteForRate(props) {
  const handleChangeDescrip = (name) => (event) => {
    props.states.functions.dataDescription(event.target.value);
  };
  return (
    <div className={styles.comment}>
      <TextField
        id="comment"
        name="text"
        value={props.states.school.baseData.popUpDataRate.description}
        onChange={handleChangeDescrip("text")}
        margin="normal"
        variant="outlined"
        multiline={true}
        rowsMax="9"
        rows="9"
      />
    </div>
  );
}

function renderPopUp(props) {
  return (
    <div
      className={styles.checkDiv}
      onClick={() => {
        props.states.functions.post.rate.rateHomeworkCheked(
          props.states.school.baseData.popUpDataRate.journalId,
          props.states.school.baseData.popUpDataRate.schoolBoyId,
          props.states.school.baseData.homeworkCheckbox
        );
        typeFlag(props);
      }}
    >
      <input
        type="checkbox"
        checked={props.states.school.baseData.homeworkCheckbox}
      />
      <div>Домашнє завдання здано</div>
    </div>
  );
}

function typeFlag(props) {
  if (props.states.school.baseData.homeworkCheckbox === false) {
    props.states.functions.setHomeworkCheckbox(1);
  } else {
    props.states.functions.setHomeworkCheckbox(0);
  }
}
