import React from 'react';
import styles from './menublock.module.css';

import Teacher from './teacher/teacher.js';
import OwnerTeacher from './ownerTeacher/ownerTeacher.js';
import HeadTeacher from './headTeacher/headTeacher.js';
import MessageAdmin from './messageAdmin/messageAdmin.js';
//import PrintDocument from "./printDocument/printDocument.js";
import ExitProgram from './exitProgram/exitProgram.js';

//import JFacultatuvu from "./jFakultativ/jFakultativ.js";
//import JProdovjDen from "./jProdovjDen/jprodovjden.js";
//import JIndividualnogoNavchannya from "./jIduvidNavch/jindNavch.js";

//////////////////////////
import Profil from './profil/profil.js';
import Uspischnist from './uspischnist/uspischnist.js';
import Testuvannya from './testuvannya/testuvannya.js';
import Navchmterial from './navchmterial/navchmterial.js';
import Vchitelpredmet from './vchitelpredmet/vchitelpredmet.js';
import Novunu from './novunu/novunu.js';
import Stream from './stream/stream.js';
//import DistEducation from "./distEducation/distEducation.js";

export default function MenuBlock(props) {
  return (
    <div className={styles.mainMenu}>
      {menuLeftBarAssembly(props)}
      {rightCloseMenu(props)}
    </div>
  );
}

const menuLeftBarAssembly = (props) => {
  // eslint-disable-next-line no-unused-vars
  let baseTeacher = null;
  // eslint-disable-next-line no-unused-vars
  let ownTeacher = null;
  // eslint-disable-next-line no-unused-vars
  let headTeacher = null;
  if (props.states.school.baseData.appointmentNumber !== null) {
    baseTeacher = (
      <Teacher
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />
    );
  }
  if (props.states.school.baseData.teacher.OwnClassId !== null) {
    ownTeacher = (
      <OwnerTeacher
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />
    );
  }
  if (props.states.school.baseData.teacher.PositionId !== null) {
    if (
      props.states.school.baseData.teacher.PositionId === 4 ||
      props.states.school.baseData.teacher.PositionId === 9
    ) {
      headTeacher = (
        <HeadTeacher
          changeStateMenu={props.changeStateMenu}
          changeStateMainJournal={props.changeStateMainJournal}
          states={props.states}
        />
      );
    }
  }

  return (
    <div className={styles.mainMenu__left}>
      {menuTopLineAssembly(props)}
      <Profil
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />
      <Uspischnist
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />
      {/* {(props.states.school.baseData.domenQuiz ===
        'https://quiz22.eschool-ua.com/' ||
        props.states.school.baseData.schoolId === 6840 ||
        props.states.school.baseData.schoolId === 841) && (
        <Testuvannya
          changeStateMenu={props.changeStateMenu}
          changeStateMainJournal={props.changeStateMainJournal}
          states={props.states}
        />
      )} */}
      <Testuvannya
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />
      {/* <DistEducation
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      /> */}
      <Navchmterial
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />
      <Vchitelpredmet
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />
      <Novunu
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />
      <Stream
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />

      <MessageAdmin
        changeStateMenu={props.changeStateMenu}
        changeStateMainJournal={props.changeStateMainJournal}
        states={props.states}
      />
      <ExitProgram
        changeStateExit={props.changeStateExit}
        states={props.states}
      />
    </div>
  );
};

const menuTopLineAssembly = (props) => {
  return (
    <div
      className={
        styles.topElemMeny +
        ' ' +
        styles.mainMenu__leftBaseData +
        ' ' +
        styles.mainMenu__leftBottomBorder
      }
      onClick={props.changeStateMenu}
    >
      {menuTopIcon(props)}
      {menuTopUserFullName(props)}
    </div>
  );
};

const menuTopIcon = () => {
  return (
    <div>
      <svg
        width='24'
        height='16'
        viewBox='0 0 24 16'
        fill='none'
        xmlns='https://www.w3.org/2000/svg'
      >
        <path
          d='M0 16H24V13.3333H0V16ZM0 9.33333H24V6.66667H0V9.33333ZM0 0V2.66667H24V0H0Z'
          fill='black'
        />
      </svg>
    </div>
  );
};

const menuTopUserFullName = (props) => {
  return (
    <div>
      {props.states.school.baseData.teacher.LastName}{' '}
      {props.states.school.baseData.teacher.FirstName}{' '}
      {props.states.school.baseData.teacher.SecondName}
    </div>
  );
};

function rightCloseMenu(props) {
  return (
    <div
      className={styles.mainMenu__right}
      onClick={() => {
        props.states.functions.menu.reversText(0);
        props.changeStateMenu();
      }}
    ></div>
  );
}
