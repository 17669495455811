import React, { Component, Fragment, Suspense, lazy } from 'react';

import Logo from '../logo/logo';
import Login from '../login/login';
import ErrLogin from '../login/errorLogin/errorLogin';
import { Switch, Route } from 'react-router-dom';
//import Journal from '../journal/journal';
import MenuBlock from '../menublock/index';
import Profile from '../popUpProfile/journalProfile';
import PopUpRatesPiker from '../popUpRatesPiker/dirtyBlock';
import PopUpReplacementMissing from '../popUpReplacementMissing/index';
//import PopUpCreateColumn from '../popUpCreateColumn2/popUpCreateColumn';
import PopUpCreateColumn from '../popUpCreateColumn/popUpCreateColumn';
import PopUpHomework from '../popUpHomework/popUpHomework';
import PopUpCreateTopic from '../popUpCreateTopic2/popUpCreateTopic';
import PopUpInstruction from '../popUpInstruction/popUpInstruction';
import PopUpBrowserMessage from '../popUpBrowserMessage/popUpBrowserMessage';
//import PopUpCreateTopic from '../popUpCreateTopic/popUpCreateTopic';
import OkThema from '../popUpOKTema/popUpOKTema';
import CreateSemestr from '../popUpCreateSemestr/popUpCreateSemestr';
import CreateThemes from '../popUpCreateThemes/popUpCreateThemes';
import DeleteTopic from '../popUpDeleteTopic/popUpDeleteTopic';
import Trip from '../popUpTrip/popUpTrip';
import Tell from '../popUpTell/popUpTell';
import Lang from '../lang/lang';
import DeleteTopicFromAppointment from '../popUpDeleteTopicFromAppointment/popUpDeleteTopicFromAppointment';
import PopUpDeleteTopicTableTopic from '../popUpDeleteTableTopic/delTopic';
import PopUpDeleteHW from '../popUpDeleteHW/delTopic';
import UnBindColumn from '../popUpUnBindColumn/popUpOKTema';
import LoaderSpinner from '../loaderSpinner/loaderSpinner';

import { stateUpdate } from '../../services/state';

const Journal = lazy(() => import('../journal/journal'));

export default class App extends Component {
  state = {
    stateJournal: false, //
    stateMenu: false, //
    tokens: [],
    //stateWidth: 10000,
  };

  changeStateMainJournal = (flag) => {
    if (flag === 1) {
      this.setState({ stateJournal: true });
    } else if (flag === 2) {
      this.setState({ stateJournal: false });
    }
  };

  changeStateJournalTopMenu = () => {
    !this.state.stateJournal
      ? this.setState({ stateJournal: true })
      : this.setState({ stateJournal: false });
  };

  changeStateExit = () => {
    this.setState({ stateJournal: false, stateMenu: false });
  };

  changeStateMenu = () => {
    !this.state.stateMenu
      ? this.setState({ stateMenu: true })
      : this.setState({ stateMenu: false });
  };

  deleteWithExpiry(key) {
    var itemStr = localStorage.getItem('token');
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  getTokens = (deleteWithExpiry) => {
    return deleteWithExpiry('token');
  };

  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  setStateOptions = (tokens) => {
    const parseJwt = (token) => {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    };
    if (this.props.states.school.baseData.stateError === 0) {
      let temp;
      let indexToken;
      let tmpArr = [];
      for (let i = 0; i < tokens?.length; i++) {
        tmpArr[i] = parseJwt(tokens[i]?.token);
      }
      // eslint-disable-next-line array-callback-return
      temp = tmpArr.find((item, index) => {
        if (item.role === 'schoolboy') {
          indexToken = index;
          return true;
        }
      });
      if (this.props.states.school.popUpFlag.error === false) {
        this.props.states.functions.data.detectWecend();
        this.props.states.school.baseData.viewLogin.methods.changeStartScreen(
          2,
        );
      }
      if (!temp) {
        this.props.states.functions.getConstData.errorLoginConect(2);
        let tmp = { ...this.props.states };
        tmp.school.popUpFlag.error = true;
        tmp.school.baseData.password = null;
        stateUpdate(tmp);
        return;
      }
      let tmp = { ...this.props.states };
      tmp.school.baseData.schoolId = temp?.school_id;
      tmp.school.baseData.teacherId = temp?.jti;
      tmp.school.baseData.role = temp?.role;
      tmp.school.baseData.token = tokens[indexToken]?.token;
      tmp.school.baseData.refresh = tokens[indexToken]?.refresh;
      tmp.school.baseData.flagInstruction = true;
      stateUpdate(tmp);
    }
  };

  createStringData(data) {
    let tmp = data;
    if (data < 10) {
      tmp = '0' + tmp;
    }
    return tmp;
  }

  UNSAFE_componentWillMount() {
    this.setState({
      tokens: this.getTokens(this.deleteWithExpiry),
    });
  }

  componentDidMount() {
    if (this.state.tokens?.length > 0) {
      Promise.all([this.setStateOptions(this.state.tokens)])
        .then(() => {
          if (this.props.states.school.baseData.stateError === 0) {
            this.props.states.functions.data.detectStart();
          }
        })
        .then(() => {
          this.props.states.functions.getConstData
            .getAcademicPeriod()
            .then(() => {
              if (this.props.states.school.baseData.stateError === 0) {
                sessionStorage.clear();
                this.props.states.functions.getConstData.getMissingType();
                this.props.states.functions.getConstData.getRateType();
                this.props.states.functions.getConstData.getAllRateValue();
                this.props.states.functions.getConstData.getTypeColumn();
                this.props.states.functions.getConstData.getSchoolboySchedule(
                  this.props.states.school.arr.wecend[0]?.year,
                  this.createStringData(
                    this.props.states.school.arr.wecend[0]?.month,
                  ),
                  this.createStringData(
                    this.props.states.school.arr.wecend[0]?.day,
                  ),
                  this.props.states.school.arr.wecend[5]?.year,
                  this.createStringData(
                    this.props.states.school.arr.wecend[5]?.month,
                  ),
                  this.createStringData(
                    this.props.states.school.arr.wecend[5]?.day,
                  ),
                );
                this.props.states.functions.getConstData.getSchoolboyLink();
                this.props.states.functions.getConstData.getSchoolboyParts();
                this.props.states.functions.getConstData.getParentComments();
                // state.functions.getConstData.getSchoolboyRates();
                setInterval(() => {
                  sessionStorage.clear();
                }, 7200000);
              }
            })
            .then(() => {
              if (this.props.states.school.baseData.stateError === 0) {
                let tmp = { ...this.props.states };
                tmp.school.baseData.appointFlag = true;
                tmp.school.baseData.password = null;
                stateUpdate(tmp);
              }
            });
        });
    }
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    let timerId = null;
    if (
      this.props.states.school.baseData.refresh !== null &&
      this.props.states.school.baseData.tokenWork.updateToken === false
    ) {
      this.props.states.school.baseData.tokenWork.function.updateRefreshToken();
      timerId = setInterval(() => {
        this.props.states.functions.refresch();
      }, 1000 * 60 * 48);
    }
    // eslint-disable-next-line no-unused-vars
    let timerMessage = null;
    if (
      this.props.states.school.baseData.teacherId !== null &&
      this.props.states.school.baseData.messageFlag !== true
    ) {
      timerMessage = setInterval(() => {
        if (this.props.states.school.baseData.messageFlag === false) {
          //this.props.states.functions.setFlagFeedback( true );
          //this.props.states.functions.getAllFeedback();
        }
      }, 1000 * 10 * 1);
    }

    let startState = null;
    let menuState;
    let connectState;

    if (this.props.states.school.baseData.stateError === 1) {
      connectState = (
        <ErrLogin
          states={this.props.states}
          textAlert="Відсутній зв'язок або сервер тимчасово недоступний."
          textAlertTwo='Спробуйте приєднатися через деякий час'
          changeStateExit={this.changeStateExit}
        />
      );
    } else if (this.props.states.school.baseData.stateError === 2) {
      connectState = (
        <ErrLogin
          states={this.props.states}
          textAlert='Невірний логін або пароль'
          textAlertTwo=''
          changeStateExit={this.changeStateExit}
        />
      );
    } else if (this.props.states.school.baseData.stateError === 3) {
      connectState = (
        <ErrLogin
          states={this.props.states}
          textAlert='Ви користуєтесь старою версією журналу!'
          textAlertTwo="Для коректної роботи будь ласка оновіть версію: для комп'ютера - клавіши CTRL+F5; для оновлення на планшеті проведіть пальцем по екрану зверх вниз."
          changeStateExit={this.changeStateExit}
        />
      );
    }
    if (
      this.props.states.school.baseData.viewLogin.property.startScreen === 0
    ) {
      startState = <Logo states={this.props.states} />;
    } else if (
      this.props.states.school.baseData.viewLogin.property.startScreen === 1
    ) {
      startState = (
        <Login
          states={this.props.states}
          stateUpdate={this.props.stateUpdate}
        />
      );
    } else if (
      this.props.states.school.baseData.viewLogin.property.startScreen === 2 &&
      this.props.states.school.baseData.stateError === 0
    ) {
      startState = (
        <Journal
          changeStateMenu={this.changeStateMenu}
          changeStateMainJournal={this.changeStateMainJournal}
          changeRates={this.changeRates}
          state={this.state}
          states={this.props.states}
          stateUpdate={this.props.stateUpdate}
          changeStateJournalTopMenu={this.changeStateJournalTopMenu}
        />
      );

      if (this.state.stateMenu === true) {
        menuState = (
          <MenuBlock
            changeStateMenu={this.changeStateMenu}
            changeStateExit={this.changeStateExit}
            changeStateMainJournal={this.changeStateMainJournal}
            states={this.props.states}
          />
        );
      }
    }

    let viewProfile = null;
    if (this.props.states.school.popUpFlag.profileFlag === true) {
      viewProfile = <Profile states={this.props.states} />;
    }

    let vievRate = null;
    if (this.props.states.school.popUpFlag.ratePicker === true) {
      vievRate = <PopUpRatesPiker states={this.props.states} />;
    }

    let viewData = null;
    if (this.props.states.school.popUpFlag.missingPicker === true) {
      viewData = <PopUpReplacementMissing states={this.props.states} />;
    }

    let viewCreateColumn = null;
    if (this.props.states.school.popUpFlag.createColumnPicker === true) {
      viewCreateColumn = <PopUpCreateColumn states={this.props.states} />;
    }

    let viewHomework = null;
    if (this.props.states.school.popUpFlag.homeworkPicker === true) {
      viewHomework = <PopUpHomework states={this.props.states} />;
    }

    let viewCreateTopic = null;
    if (this.props.states.school.popUpFlag.createTopicPiker === true) {
      viewCreateTopic = <PopUpCreateTopic states={this.props.states} />;
    }

    let viewConfirmation = null;
    if (this.props.states.school.baseData.typeColumn !== null) {
      viewConfirmation = <OkThema states={this.props.states} />;
    }

    let viewConfirmationSemestr = null;
    if (this.props.states.school.baseData.createSemestr === true) {
      viewConfirmationSemestr = <CreateSemestr states={this.props.states} />;
    }
    let viewConfirmationThemes = null;
    if (this.props.states.school.baseData.createThemes === true) {
      viewConfirmationThemes = <CreateThemes states={this.props.states} />;
    }
    let viewConfirmationDeleteTopics = null;
    if (this.props.states.school.baseData.deleteTopic === true) {
      //viewConfirmation = <DeleteTopic
      viewConfirmationDeleteTopics = <DeleteTopic states={this.props.states} />;
    }
    let viewTrip = null;
    if (this.props.states.school.baseData.tripJournal === true) {
      viewTrip = <Trip states={this.props.states} />;
    }
    let viewTell = null;
    if (this.props.states.school.baseData.tellJournal === true) {
      viewTell = <Tell states={this.props.states} />;
    }
    let viewConfirmationDeleteTopicFromAppointment = null;
    if (this.props.states.school.baseData.deleteTopicFromAppointment === true) {
      viewConfirmationDeleteTopicFromAppointment = (
        <DeleteTopicFromAppointment states={this.props.states} />
      );
    }
    let viewConfirmationDeleteTopicTable = null;
    if (this.props.states.school.journalColumn.popUpDeleteTable === true) {
      viewConfirmationDeleteTopicTable = (
        <PopUpDeleteTopicTableTopic states={this.props.states} />
      );
    }
    let viewConfirmationDeleteHW = null;
    if (this.props.states.school.journalColumn.popUpDeleteHW === true) {
      viewConfirmationDeleteHW = <PopUpDeleteHW states={this.props.states} />;
    }

    let viewPopUpUnBindColumn = null;
    if (this.props.states.school.journalTopics.editColumn === true) {
      viewPopUpUnBindColumn = <UnBindColumn states={this.props.states} />;
    }

    let viewPopUpInstruction = null;
    if (this.props.states.school.baseData.flagInstruction === true) {
      viewPopUpInstruction = <PopUpInstruction states={this.props.states} />;
    }

    let viewPopUpBrowserMessage = null;
    if (this.props.states.school.popUpFlag.flagBrowserMessage === true) {
      viewPopUpBrowserMessage = (
        <PopUpBrowserMessage states={this.props.states} />
      );
    }

    return (
      <Suspense fallback={<LoaderSpinner bg='true' />}>
        <Switch>
          <Route
            path='/'
            exact
            render={(props) => (
              <Fragment>
                {startState}
                {menuState}
                {connectState}
                {viewProfile}
                {vievRate}
                {viewData}
                {viewCreateColumn}
                {viewHomework}
                {viewCreateTopic}
                {viewConfirmation}
                {viewConfirmationSemestr}
                {viewConfirmationThemes}
                {viewConfirmationDeleteTopics}
                {viewTrip}
                {viewTell}
                {viewConfirmationDeleteTopicFromAppointment}
                {viewConfirmationDeleteTopicTable}
                {viewConfirmationDeleteHW}
                {viewPopUpUnBindColumn}
                {viewPopUpInstruction}
                {viewPopUpBrowserMessage}
                <Lang states={this.props.states} />
              </Fragment>
            )}
          />
        </Switch>
      </Suspense>
    );
  }
}
