import React from "react";
import styles from "./editHomework.module.css";

import ButtonEditHomework from "./button/button.js";
import DescriptionEditHomework from "./description/description.js";
import SelectEditHomework from "./selectHomework/selectHomework.js";
import ViewData from "../viewData/viewData.js";

export default function EditHomework(props) {
  return (
    <div>
      <div className={styles.homework_wrap_slide_one}>
        <ViewData states={props.states} />
        <SelectEditHomework states={props.states} />
        <DescriptionEditHomework states={props.states} />
        <ButtonEditHomework states={props.states} />
      </div>
    </div>
  );
}
