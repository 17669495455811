import React from 'react';
//import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

//let textErr = "";

const arrChar = [];
arrChar[34] = arrChar[1028] = arrChar[1069] = { char: ['"', 'Є'] };

arrChar[39] = arrChar[1101] = arrChar[1108] = { char: ["'", 'є'] };

arrChar[44] = arrChar[1073] = { char: [',', 'б'] };
arrChar[45] = { char: ['-', '-'] };
arrChar[46] = arrChar[1102] = { char: ['.', 'ю'] };
arrChar[47] = { char: ['/', '.'] };
arrChar[48] = { char: ['0', '0'] };
arrChar[49] = { char: ['1', '1'] };
arrChar[50] = { char: ['2', '2'] };
arrChar[51] = { char: ['3', '3'] };
arrChar[52] = { char: ['4', '4'] };
arrChar[53] = { char: ['5', '5'] };
arrChar[54] = { char: ['6', '6'] };
arrChar[55] = { char: ['7', '7'] };
arrChar[56] = { char: ['8', '8'] };
arrChar[57] = { char: ['9', '9'] };
arrChar[58] = arrChar[1046] = { char: [':', 'Ж'] };
arrChar[59] = arrChar[1078] = { char: [';', 'ж'] };
arrChar[60] = arrChar[1041] = { char: ['<', 'Б'] };
arrChar[61] = { char: ['=', '='] };
arrChar[62] = arrChar[1070] = { char: ['>', 'Ю'] };
arrChar[63] = { char: ['?', ','] };

arrChar[65] = arrChar[1060] = { char: ['A', 'Ф'] };
arrChar[66] = arrChar[1048] = { char: ['B', 'И'] };
arrChar[67] = arrChar[1057] = { char: ['C', 'С'] };
arrChar[68] = arrChar[1042] = { char: ['D', 'В'] };
arrChar[69] = arrChar[1059] = { char: ['E', 'У'] };
arrChar[70] = arrChar[1040] = { char: ['F', 'А'] };
arrChar[71] = arrChar[1055] = { char: ['G', 'П'] };
arrChar[72] = arrChar[1056] = { char: ['H', 'Р'] };
arrChar[73] = arrChar[1064] = { char: ['I', 'Ш'] };
arrChar[74] = arrChar[1054] = { char: ['J', 'О'] };
arrChar[75] = arrChar[1051] = { char: ['K', 'Л'] };
arrChar[76] = arrChar[1044] = { char: ['L', 'Д'] };
arrChar[77] = arrChar[1068] = { char: ['M', 'Ь'] };
arrChar[78] = arrChar[1052] = { char: ['N', 'Т'] };
arrChar[79] = arrChar[1065] = { char: ['O', 'Щ'] };
arrChar[80] = arrChar[1047] = { char: ['P', 'З'] };
arrChar[81] = arrChar[1049] = { char: ['Q', 'Й'] };
arrChar[82] = arrChar[1052] = { char: ['R', 'К'] };
arrChar[83] = arrChar[1030] = arrChar[1067] = { char: ['S', 'І'] };
arrChar[84] = arrChar[1045] = { char: ['T', 'Е'] };
arrChar[85] = arrChar[1043] = { char: ['U', 'Г'] };
arrChar[86] = arrChar[1052] = { char: ['V', 'М'] };
arrChar[87] = arrChar[1062] = { char: ['W', 'Ц'] };
arrChar[88] = arrChar[1063] = { char: ['X', 'Ч'] };
arrChar[89] = arrChar[1053] = { char: ['Y', 'Н'] };
arrChar[90] = arrChar[1071] = { char: ['Z', 'Я'] };
arrChar[91] = arrChar[1093] = { char: ['[', 'х'] };
arrChar[92] = arrChar[1169] = { char: ['\\', 'ґ'] };
arrChar[93] = arrChar[1098] = arrChar[1111] = { char: [']', 'ї'] };

arrChar[96] = arrChar[1105] = { char: ['`', '`'] };
arrChar[97] = arrChar[1092] = { char: ['a', 'ф'] };
arrChar[98] = arrChar[1080] = { char: ['b', 'и'] };
arrChar[99] = arrChar[1089] = { char: ['c', 'с'] };
arrChar[100] = arrChar[1074] = { char: ['d', 'в'] };
arrChar[101] = arrChar[1091] = { char: ['e', 'у'] };
arrChar[102] = arrChar[1072] = { char: ['f', 'а'] };
arrChar[103] = arrChar[1087] = { char: ['g', 'п'] };
arrChar[104] = arrChar[1088] = { char: ['h', 'р'] };
arrChar[105] = arrChar[1096] = { char: ['i', 'ш'] };
arrChar[106] = arrChar[1086] = { char: ['j', 'о'] };
arrChar[107] = arrChar[1083] = { char: ['k', 'л'] };
arrChar[108] = arrChar[1076] = { char: ['l', 'д'] };
arrChar[109] = arrChar[1100] = { char: ['m', 'ь'] };
arrChar[110] = arrChar[1090] = { char: ['n', 'т'] };
arrChar[111] = arrChar[1097] = { char: ['o', 'щ'] };
arrChar[112] = arrChar[1079] = { char: ['p', 'з'] };
arrChar[113] = arrChar[1081] = { char: ['q', 'й'] };
arrChar[114] = arrChar[1082] = { char: ['r', 'к'] };
arrChar[115] = arrChar[1099] = arrChar[1110] = { char: ['s', 'і'] };
arrChar[116] = arrChar[1077] = { char: ['t', 'е'] };
arrChar[117] = arrChar[1075] = { char: ['u', 'г'] };
arrChar[118] = arrChar[1084] = { char: ['v', 'м'] };
arrChar[119] = arrChar[1094] = { char: ['w', 'ц'] };
arrChar[120] = arrChar[1095] = { char: ['x', 'ч'] };
arrChar[121] = arrChar[1085] = { char: ['y', 'н'] };
arrChar[122] = arrChar[1103] = { char: ['z', 'я'] };
arrChar[123] = arrChar[1061] = { char: ['{', 'Х'] };
arrChar[124] = arrChar[1168] = { char: ['|', 'Ґ'] };
arrChar[125] = arrChar[1031] = arrChar[1066] = { char: ['}', 'Ї'] };

function OutlinedTextFields(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    login: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleTogglePasswordVisibility = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  let body = JSON.stringify({
    phone: values.login, //'7977',
    //'password': values.password, //'admin',
    password: props.states.school.baseData.password,
  });

  /*if( props.state.stateError === 2 ){
    textErr = "<div>Неверный логин или пароль</div>";
  } else {
    textErr = "";
  }*/

  let logoIn = null;
  if (props.states.school.baseData.keyBoardMode === false) {
    logoIn = (
      <TextField
        id='outlined-name'
        label='Логін'
        name='login'
        className={classes.textField}
        value={values.login}
        onChange={handleChange('login')}
        margin='normal'
        variant='outlined'
        placeholder='Логін'
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (
              props.states.school.baseData.password !== null &&
              values.login !== '' &&
              props.states.school.baseData.password !== ''
            ) {
              props.states.functions.getConstData.postLogin(body);
              if (props.states.school.popUpFlag.error === false) {
                props.states.functions.data.detectWecend();
                //props.stateJournal();
                props.states.school.baseData.viewLogin.methods.changeStartScreen(
                  2,
                );
                //props.updateDataAPI({'status': serverStatus});
              }
            }
          }
        }}
      />
    );
  } else if (props.states.school.baseData.keyBoardMode === true) {
    logoIn = (
      <TextField
        id='outlined-name'
        label='Логін'
        name='login'
        className={classes.textField}
        value={values.login}
        onCut={(e) => {
          // eslint-disable-next-line no-unused-vars
          let cut = (e.clipboardData || window.clipboardData).setData('text');
        }}
        onPaste={(e) => {
          let paste = (e.clipboardData || window.clipboardData).getData('text');
          setValues({ ...values, login: paste });
        }}
        onKeyPress={(e) => {
          try {
            let str1 = values.login.slice(0, doGetCaretPosition(e.target));
            let str2 = values.login.slice(
              doGetCaretPosition(e.target),
              values.login.length,
            );
            //setValues({ ...values, ['login']: values.login + arrChar[e.charCode].char[props.states.school.baseData.langTypeFlag] });
            setValues({
              ...values,
              // eslint-disable-next-line no-useless-computed-key
              ['login']:
                str1 +
                arrChar[e.charCode].char[
                  props.states.school.baseData.langTypeFlag
                ] +
                str2,
            });
          } catch (ss) {}
          if (e.key === 'Enter') {
          }
        }}
        onKeyDown={(e) => {
          props.states.functions.setSelectionRange(
            doGetCaretPosition(e.target),
            2,
          );
          if (e.keyCode === 8) {
            let str1 = values.login.slice(0, doGetCaretPosition(e.target) - 1);
            let str2 = values.login.slice(
              doGetCaretPosition(e.target),
              values.login.length,
            );
            // eslint-disable-next-line no-useless-computed-key
            setValues({ ...values, ['login']: str1 + str2 });
          }
          if (e.keyCode === 46) {
            let str1 = values.login.slice(0, doGetCaretPosition(e.target));
            let str2 = values.login.slice(
              doGetCaretPosition(e.target) + 1,
              values.login.length,
            );
            //setValues({...values, ['login']: values.login.slice(0, -1)});
            // eslint-disable-next-line no-useless-computed-key
            setValues({ ...values, ['login']: str1 + str2 });
          }
          if (e.keyCode === 17) {
          }
        }}
        margin='normal'
        variant='outlined'
        placeholder='Логін'
      />
    );
  }

  let passIn = null;
  let password = '';
  if (props.states.school.baseData.password !== null) {
    password = props.states.school.baseData.password;
  }
  if (props.states.school.baseData.keyBoardMode === false) {
    passIn = (
      <TextField
        id='outlined-password-input'
        label='Пароль'
        name='password'
        className={classes.textField}
        value={password}
        onChange={(e) => {
          props.states.functions.setPassword(e.target.value);
        }}
        type={values.showPassword ? 'text' : 'password'}
        autoComplete='current-password'
        margin='normal'
        variant='outlined'
        placeholder='Пароль'
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (
              props.states.school.baseData.password !== null &&
              values.login !== '' &&
              props.states.school.baseData.password !== ''
            ) {
              props.states.functions.getConstData.postLogin(body);
              if (props.states.school.popUpFlag.error === false) {
                props.states.functions.data.detectWecend();
                //props.stateJournal();
                props.states.school.baseData.viewLogin.methods.changeStartScreen(
                  2,
                );
                //props.updateDataAPI({'status': serverStatus});
              }
            }
          }
        }}
      />
    );
  } else if (props.states.school.baseData.keyBoardMode === true) {
    passIn = (
      <AutoFocusTextInput
        states={props.states}
        showPassword={values.showPassword}
      />
    );
  }

  return (
    <form className={classes.container} noValidate autoComplete='off'>
      {logoIn}
      {passIn}
      <div
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: 'calc(50% - 92px)',
          top: 'calc(50% + 20px)',
        }}
        onClick={handleTogglePasswordVisibility}
      >
        {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </div>
      {/*textErr*/}
      <Button
        variant='contained'
        color='primary'
        disabled={props.states.profile.isLoadingSpinner}
        className={classes.button}
        onClick={() => {
          if (
            props.states.school.baseData.password !== null &&
            values.login !== '' &&
            props.states.school.baseData.password !== ''
          ) {
            props.states.profile.functions.setIsLoadingSpinner(true);
            props.states.functions.getConstData.postLogin(body);
            if (props.states.school.popUpFlag.error === false) {
              props.states.functions.data.detectWecend();
              //props.stateJournal();
              props.states.school.baseData.viewLogin.methods.changeStartScreen(
                2,
              );
              //props.updateDataAPI({'status': serverStatus});
            }
          }
        }}
      >
        Ввійти
      </Button>
    </form>
  );
}

export default OutlinedTextFields;

class AutoFocusTextInput extends React.Component {
  constructor(props) {
    super(props);
    // создадим реф в поле `textInput` для хранения DOM-элемента
    this.styless = {
      padding: '18.5px 14px',
      font: 'inherit',
      color: 'currentColor',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, .2)',
      borderRadius: '4px',
      height: '1.1875em',
      margin: '0',
      display: 'block',
      minWidth: '0',
      background: 'none',
      boxSizing: 'content-box',
      marginLeft: '8px',
      marginRight: '-30px',
    };
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  focusTextInput() {
    // Установим фокус на текстовое поле с помощью чистого DOM API
    // Примечание: обращаемся к "current", чтобы получить DOM-узел
    this.textInput.current.focus();
    this.textInput.current.setSelectionRange(
      this.props.states.school.baseData.setSelectionRange.range + 1,
      this.props.states.school.baseData.setSelectionRange.range + 1,
    );
  }

  render() {
    if (this.props.states.school.baseData.setSelectionRange.flag === 2) {
      //this.focusTextInput()
    }
    return (
      <div>
        <input
          style={this.styless}
          type={this.props.showPassword ? 'text' : 'password'}
          ref={this.textInput}
          placeholder='Пароль'
          value={this.props.states.school.baseData.password}
          name='password'
          onChange={(e) => {
            this.props.states.functions.setPassword(e.target.value);
          }}
        />

        <input
          type='button'
          value='Фокус на текстовом поле'
          onClick={this.focusTextInput}
        />
      </div>
    );
  }
}

/**
 * пример для нативного js как курсор выставить на нужную позицию
 * @param {*} props
 */
// eslint-disable-next-line no-unused-vars
function positionSet(props) {
  let input = document.getElementById('outlined-name');
  input.focus();
  input.setSelectionRange(
    props.states.school.baseData.setSelectionRange.range,
    props.states.school.baseData.setSelectionRange.range,
  );
}

/**
 * Определяем позицию курсора в момент нажатия
 * если удалять перед курсором то текущее значение -1
 * если удалять за курсором то текущее значение
 * @param {*} oField
 */
function doGetCaretPosition(oField) {
  // Initialize
  var iCaretPos = 0;

  // IE Support
  if (document.selection) {
    // Set focus on the element
    oField.focus();

    // To get cursor position, get empty selection range
    var oSel = document.selection.createRange();

    // Move selection start to 0 position
    oSel.moveStart('character', -oField.value.length);

    // The caret position is selection length
    iCaretPos = oSel.text.length;
  }

  // Firefox support
  else if (oField.selectionStart || oField.selectionStart === '0')
    iCaretPos =
      oField.selectionDirection === 'backward'
        ? oField.selectionStart
        : oField.selectionEnd;

  // Return results
  return iCaretPos;
}
