import React from "react";
import styles from "./popUpCreateColumn.module.css";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { makeStyles } from "@material-ui/core/styles";

import Semestr from "./semestr/semestr.js";
import Topics from "./topics/topics.js";
import Common from "./common/common.js";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginTop: 5,
  },
}));

export default function popUpCreateColumn(props) {
  let dataFlag = props.states.school.baseData.dataFlag;
  return (
    <div className={styles.mainRateBlock}>
      <div className={styles.block}>
        <div className={styles.TitleColumn}>
          УВАГА! Колонка буде створена на <span>{dataFlag}</span>. Якщо Вам
          необхідно створити колонку на іншу дату, поверніться до "Розкладу",
          знайдіть необхідний урок та створіть колонку.
        </div>
        {ClosePopUp(props)}
        {FullWidthTabs(props)}
      </div>
    </div>
  );
}

/**
 * Функция закрытия попапа
 * @param {*} props
 */
function ClosePopUp(props) {
  let funcPopUpCreateColumnPicker =
    props.states.functions.changed.flags.popUpCreateColumnPicker;
  let funcGetTmpColumnSelectThemes =
    props.states.functions.changed.column.getTmpColumnSelectThemes;
  let funcGetTmpColumnSelectCreateThemes =
    props.states.functions.changed.column.getTmpColumnSelectCreateThemes;
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        funcPopUpCreateColumnPicker(false);
        funcGetTmpColumnSelectThemes(null);
        funcGetTmpColumnSelectCreateThemes(null);
        props.states.school.journalColumn.clear();
      }}
    >
      X
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Створити колонку" {...a11yProps(0)} />
          <Tab label="Закрити тему" {...a11yProps(1)} />
          <Tab label="Закрити семестр" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          className={styles.scrollAuto}
        >
          <Common states={props.states} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Topics states={props.states} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Semestr states={props.states} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
