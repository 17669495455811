import React from "react";
import styles from "./popUpTell.module.css";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export default function PopUpCreateTopic(props) {
  return (
    <div className={styles.mainRateBlock}>
      <div className={styles.block}>
        {ClosePopUp(props)}
        {BlockText(props)}
      </div>
    </div>
  );
}

/**
 * Функция закрытия попапа
 * @param {*} props
 */
function ClosePopUp(props) {
  let funcSrtTypeColumn = props.states.functions.journalTellClodePopUp;
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        funcSrtTypeColumn();
      }}
    >
      X
    </div>
  );
}

/**
 * Функция вывода текста в попапе
 */
function BlockText(props) {
  const handleDate = (name) => (event) => {
    props.states.functions.journalTellDate(event.target.value);
  };
  const handleTopic = (name) => (event) => {
    props.states.functions.journalTellTopic(event.target.value);
  };
  const handleBody = (name) => (event) => {
    props.states.functions.journalTellBody(event.target.value);
  };

  return (
    <div>
      <div className={styles.popup_topic}>Створення бесіди</div>
      <div className={styles.popup_topic}>Редагування дати бесіди</div>
      <div>
        <TextField
          value={props.states.school.baseData.tellJournalDate}
          onChange={handleDate("name")}
          id="outlined-bare"
          className={styles.textField + " " + styles.textfild_topic}
          inputProps={{ "aria-label": "bare" }}
          margin="normal"
          variant="outlined"
        />
      </div>
      <div className={styles.popup_topic}>Редагування теми бесіди</div>
      <div>
        <TextField
          value={props.states.school.baseData.tellJournalTopic}
          onChange={handleTopic("name")}
          id="outlined-bare"
          className={styles.textField + " " + styles.textfild_topic}
          inputProps={{ "aria-label": "bare" }}
          margin="normal"
          variant="outlined"
        />
      </div>
      <div className={styles.popup_topic}>Редагування примітки</div>
      <div>
        <TextField
          value={props.states.school.baseData.tellJournalBody}
          onChange={handleBody("name")}
          id="outlined-bare"
          className={styles.textField + " " + styles.textfild_topic}
          inputProps={{ "aria-label": "bare" }}
          margin="normal"
          variant="outlined"
        />
      </div>
      {createButtonCreateThema(props)}
    </div>
  );
}

function createButtonCreateThema(props) {
  let tmp = null;
  if (
    props.states.school.baseData.tellJournalDate !== "" &&
    props.states.school.baseData.tellJournalTopic !== "" &&
    props.states.school.baseData.tellJournalBody !== ""
  ) {
    tmp = (
      <div className={styles.popup_button}>
        <Button
          onClick={() => {
            props.states.functions.journalTellClodePopUp();
          }}
          variant="contained"
          color="primary"
          className={styles.Button}
        >
          Створити
        </Button>
      </div>
    );
  }
  return tmp;
}
