import React, { useEffect, useRef } from 'react';
import styles from './popUpInstruction.module.css';

const PopUpInstruction = (props) => {
  const handleClick = () => {
    props.states.functions.getConstData.closeFlagInstruction();
  };

  const useOnClickOutside = (ref, handler) => {
    // Check if the user clicked outside of the calendar
    useEffect(() => {
      // add event listener
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      // add event listener
      document.addEventListener('mousedown', listener);
      // remove event listener
      return () => {
        document.removeEventListener('mousedown', listener);
      };
    }, [ref, handler]);
  };

  // close calendar on click outside
  const ref = useRef(null);
  useOnClickOutside(ref, () => handleClick(false));

  return (
    <div id='myModal' className={styles.modal}>
      <div className={styles.modalContent} ref={ref}>
        <span className={styles.close} onClick={handleClick}>
          &times;
        </span>
        <div style={{ textAlign: 'center' }}>
          {/* <h3>Систему «Єдина школа» оновлено!</h3> */}
        </div>
        <div>
          <h3>Шановні учні!</h3>
          <p>
            Маємо для вас чудову новину: відтепер вам буде набагато простіше
            долучитися до онлайн-уроку на сервісі Google Meet – просто із свого
            електронного щоденника!
          </p>
          <span>Інструкцію як це зробити можна переглянути </span>
          <span>
            <a
              className={styles.popup__link}
              href={`https://eschool-ua.com/download/Як_учневі_долучитись_до_онлайн_уроку_в_гугл_міт.pdf?token=${props.states.school.baseData.token}`}
              download='Як_учневі_долучитись_до_онлайн_уроку_в_гугл_міт.pdf'
              target='_blank'
              rel='noopener noreferrer'
              style={{ display: 'inline' }}
            >
              <u>тут</u>
            </a>
            .
          </span>
          <p style={{ fontStyle: 'italic' }}>
            Щиро ваша, команда «Єдиної школи»
          </p>
          {/* <p style={{ color: 'red', fontWeight: 'bold' }}>
            10.06 з 10:00 до 13:00 і системі проводитимуться технічні роботи.
            «Єдина Школа» тимчасово не працюватиме.
          </p> */}
          {/* <p style={{ color: 'red', fontWeight: 'bold' }}>
            Просимо планувати свою роботу.
          </p> */}
          {/* <p>Дякуємо за розуміння!🙏🏽🎄</p> */}
          {/* <a
            className={styles.popup__link}
            href="./download/Як учню долучитися до запланованого вчителем онлайн-уроку через електронний щоденник учня з комп'ютера або ноутбука.mp4"
            target="_blank"
          >
            Відеоінструкція за посиланням
          </a> */}
        </div>
        {/* <ul className={styles.modalList}>
          <div>
            <li>
              <strong> Як я можу подивитися розклад своїх уроків?</strong>
            </li>
            Для того, щоб подивитися розклад своїх уроків, просто зайди до свого
            ЕЛЕКТРОННОГО ЩОДЕННИКА. На цьому екрані ти побачиш назви днів тижня,
            дати під ними та перелік уроків на кожен день. В інформації до уроку
            ти побачиш номер уроку в синьому квадратику, назву уроку, а також
            позначку про запланований ОНЛАЙН-УРОК, який відбудеться прямо у
            твоєму щоденнику.
          </div>
          <div>
            <li>
              <strong> Як мені потрапити на потрібний ОНЛАЙН-УРОК?</strong>
            </li>
            Щоб потрапити на потрібний ОНЛАЙН УРОК, просто натисни на позначку
            «ОНЛАЙН» біля необхідного уроку. Ти потрапиш на новий екран, де твій
            вчитель вже почав або незабаром почне онлайн заняття.
          </div>
          <div>
            <li>
              {" "}
              <strong>Що я можу робити, коли перейду на ОНЛАЙН-УРОК?</strong>
            </li>
            Коли ти зайдеш до свого особистого цифрового класу, то побачиш
            дошку, на якій учитель показуватиме відео, фото та інші цікаві
            матеріали під час уроку. Справа від дошки на екрані ти зможеш бачити
            вчителя, який буде, як і в звичайному шкільному класі, розповідати
            тобі цікаву інформацію з математики, української мови та інших
            звичних для тебе предметів. Учитель буде ставити запитання, а ти та
            твої однокласники відповідатимете на них. Зверни увагу, що під
            дошкою є віконце, у яке ти можеш написати повідомлення для вчителя,
            яке ніхто, окрім нього і тебе, не побачить. Під відео – навпаки: ти
            зможеш листуватися з однокласниками і писати інформацію, яку будуть
            бачити учні твого класу.
          </div>
          <div>
            <li>
              <strong>
                {" "}
                Як я можу побачити домашнє завдання, яке задав мені вчитель на
                уроці?
              </strong>
            </li>
            Якщо ти хочеш зробити домашнє завдання й шукаєш, де воно записане,
            то ти навчаєшся уже в третьому чи четвертому класі, а значить із
            легкістю вирішуєш завдання, які стоять перед тобою! Щоб побачити
            домашнє завдання, яке задав тобі вчитель, після уроку просто натисни
            назву предмета у своєму розкладі, в день, який тобі потрібний! І ти
            зможеш побачити назву уроку, яке домашнє завдання ти маєш виконати,
            а також зможеш закачати прикріплені файли до домашнього завдання. Як
            це зробити, тобі покажуть батьки! Або інші однокласники, які знають,
            як це робити.
          </div>

          <strong>
            {" "}
            Гарного навчання і звертайся до нас, якщо будуть питання.
            +380730110111
          </strong>
        </ul> */}
      </div>
    </div>
  );
};

export default PopUpInstruction;
