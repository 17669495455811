import React from 'react';
import styles from './style.module.css';

export default function Quiz(props) {
  return (
    <div
      className={
        styles.mainMenu__leftBaseData + ' ' + styles.mainMenu__leftBottomBorder
      }
      //onClick={() => {
      /*props.changeStateMenu();
                    props.changeStateMainJournal( 1 );
                    props.states.functions.stateTypeJournal(14);
                    props.states.functions.getFeedbackTopic();
                    props.states.functions.menu.reversText(14);
                    props.states.functions.getAllFeedback();
                    props.states.functions.setOwnClassFlag(false);*/
      //}}
    >
      {textMenu(props, 'Тестування')}
    </div>
  );
}

function textMenu(props, text) {
  let href = `${props.states.school.baseData.domenQuiz}#/?token=${props.states.school.baseData.token}`;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={href
        // props.states.school.baseData.domenQuiz ===
        //   'https://quiz22.eschool-ua.com/' ||
        // props.states.school.baseData.schoolId === 6840 ||
        // props.states.school.baseData.schoolId === 841
        //   ? href
        //   : null
      }
      target='_blank'
      rel='noopener noreferrer'
      className={styles.mainMenu__link}
    >
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8 6H21'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M8 12H21'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M8 18H21'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M3 6H3.01'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M3 12H3.01'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M3 18H3.01'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
      {text}
    </a>
  );
}
