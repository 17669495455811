import React from "react";
import styles from "./button.module.css";

import Button from "@material-ui/core/Button";

export default function ButtonCreateHomework(props) {
  let tmp = null;
  if (
    props.states.school.journalColumn.createHomework.targetLesson !== null &&
    props.states.school.journalColumn.createHomework.mainTopic !== null &&
    props.states.school.journalColumn.createHomework.lessonTopic !== null
  ) {
    tmp = (
      <Button
        onClick={() => {
          props.states.school.journalColumn.createHomework.create();
        }}
        variant="contained"
        color="primary"
        className={styles.button_create}
      >
        Створити
      </Button>
    );
  }
  return tmp;
}
