import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/app/app.js';
import state from './services/state.js';
import { stateUpdate } from './services/state.js';

export default function renderUp() {
  ReactDOM.render(
    <BrowserRouter>
      <App states={state} stateUpdate={stateUpdate} />
    </BrowserRouter>,
    document.getElementById('root'),
  );
}

// let timerMessage = null;
// timerMessage = setInterval(() => {
//     state.functions.getAllFeedback();
// }, 1000 * 60 * 1);
