import React from "react";
import styles from "./login.module.css";

import InputLogin from "./input-logo.js";
import imgBGC from "./Screenshot_9.webp";

const styleBGC = {
  backgroundImage: `url(${imgBGC})`,
};

export default function Login(props) {
  if (props.states.school.baseData.data.yearNumber === null) {
    getDataNow(props);
  }

  return (
    <div className={styles.mainBlokLogin}>
      <div
        style={styleBGC}
        id={styles.logoPage}
        className={styles.logoPaige + " " + styles.blur}
      ></div>
      <div id={styles.logoPage} className={styles.logoPaigeForm}>
        <div className={styles.loginP}>
          <div className={styles.titleText}>
            <span>Вітаємо</span>
            <br />
            авторизуйтесь будь ласка
          </div>
          <InputLogin states={props.states} stateUpdate={props.stateUpdate} />
        </div>
      </div>
    </div>
  );
}

function getDataMonth() {
  let tmp = new Date().getMonth();
  return tmp + 1;
}

function getDataNow(props) {
  let tmp = { ...props.states };
  tmp.school.baseData.data.monthNumber = getDataMonth();
  tmp.school.baseData.data.monthNumberString = String(getDataMonth());
  tmp.school.baseData.data.yearNumber = new Date().getFullYear();
  tmp.school.baseData.data.yearNumberString = String(new Date().getFullYear());
  props.stateUpdate(tmp);
}
