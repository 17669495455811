import React from "react";
import styles from "./exitProgram.module.css";

export default function ExitProgram(props) {
  return (
    <div
      className={styles.mainMenu__leftBaseData}
      onClick={() => {
        localStorage.removeItem("token");
        props.changeStateExit();
        props.states.school.baseData.viewLogin.methods.changeStartScreen(0);
        props.states.functions.setOwnClassFlag(false);
      }}
    >
      {textMenu("Вихід")}
    </div>
  );
}

function textMenu(text) {
  return (
    <p>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 17L21 12L16 7"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 12H9"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {text}
    </p>
  );
}
