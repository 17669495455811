import React from "react";
import styles from "./style.module.css";

export default function MessageAdmin(props) {
  return (
    <div
      className={
        styles.mainMenu__leftBaseData + " " + styles.mainMenu__leftBottomBorder
      }
      onClick={() => {
        props.changeStateMenu();
        props.changeStateMainJournal(1);
        props.states.functions.stateTypeJournal(15);
        //props.states.functions.getFeedbackTopic();
        props.states.functions.menu.reversText(15);
        //props.states.functions.getAllFeedback();
        //props.states.functions.setOwnClassFlag(false);
        props.states.functions.getConstData.getSchoolboyRates();
      }}
    >
      {textMenu("Успішність")}
    </div>
  );
}

function textMenu(text) {
  return (
    <p>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 20V10"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 20V4"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 20V14"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {text}
    </p>
  );
}
