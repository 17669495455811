import React from "react";
import styles from "./logo.module.css";
import "./logo.css";

export default function Logo(props) {
  let version = props.states.school.baseData.stateTest;
  let funcChangeStartScreen =
    props.states.school.baseData.viewLogin.methods.changeStartScreen;
  return (
    <div
      className={styles.Logo}
      onClick={() => {
        funcChangeStartScreen(1);
      }}
    >
      <div className={styles.mainPaige__row}>
        <svg
          id="Laer1"
          xmlns="https://www.w3.org/2000/svg"
          xlinkHref="https://www.w3.org/1999/xlink"
          viewBox="0 0 484.96 485.91"
        >
          <defs>
            <linearGradient
              id="Laer57"
              x1="78.25"
              y1="156.72"
              x2="211.58"
              y2="156.72"
              gradientTransform="translate(208.18 -47.14) rotate(60)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#89bad1" />
              <stop offset="0.43" stopColor="#84b4ca" />
              <stop offset="1" stopColor="#79a4b8" />
            </linearGradient>
            <linearGradient
              id="Laer572"
              x1="111.7"
              y1="272.9"
              x2="245.03"
              y2="272.9"
              gradientTransform="translate(325.52 -18.02) rotate(60)"
              xlinkHref="#Laer57"
            />
            <linearGradient
              id="Laer28"
              x1="78.2"
              y1="78.39"
              x2="341.97"
              y2="78.39"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#a0d8f2" />
              <stop offset="0.17" stopColor="#a7dbeb" />
              <stop offset="0.43" stopColor="#bbe3d7" />
              <stop offset="0.73" stopColor="#dbf1b7" />
              <stop offset="1" stopColor="#feff94" />
            </linearGradient>
            <linearGradient
              id="Laer282"
              x1="83.82"
              y1="170.81"
              x2="413.26"
              y2="170.81"
              xlinkHref="#Laer28"
            />
            <linearGradient
              id="Laer283"
              x1="155.12"
              y1="263.23"
              x2="418.88"
              y2="263.23"
              xlinkHref="#Laer28"
            />
          </defs>
          <title>Єдина Школа</title>
          <path
            className="cls-1"
            d="M28.75,476.15a26,26,0,0,1-10-1.84,20.94,20.94,0,0,1-7.7-5.33,24.2,24.2,0,0,1-4.95-8.51,38,38,0,0,1,0-22.94,24,24,0,0,1,5-8.55,21.31,21.31,0,0,1,7.73-5.33,26.09,26.09,0,0,1,10-1.84,41.9,41.9,0,0,1,5.82.37,37.62,37.62,0,0,1,4.69.94,23.34,23.34,0,0,1,3.34,1.17,12.15,12.15,0,0,1,1.84,1L42.63,431a35.82,35.82,0,0,0-4.84-1.84,27.56,27.56,0,0,0-8.14-1,18.72,18.72,0,0,0-7.06,1.24,14.91,14.91,0,0,0-5.25,3.49,16.27,16.27,0,0,0-3.42,5.4,26.3,26.3,0,0,0-1.61,7.06H35.13v6.15h-23A26.88,26.88,0,0,0,13.7,459a17,17,0,0,0,3.45,5.74,15.25,15.25,0,0,0,5.33,3.72,18.27,18.27,0,0,0,7.17,1.31,27.3,27.3,0,0,0,8.22-1,35.81,35.81,0,0,0,4.91-1.88l1.88,6a10.57,10.57,0,0,1-1.88.94,25.66,25.66,0,0,1-3.41,1.09,44.46,44.46,0,0,1-4.77.9A42.66,42.66,0,0,1,28.75,476.15Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-1"
            d="M51.19,469.32h4.2a35.25,35.25,0,0,0,3.94-7.43A44.25,44.25,0,0,0,61.55,454a65.7,65.7,0,0,0,1-8.55q.23-4.47.3-9.42h25.6v33.32h5.25v16.59h-6.6V475H57.8v10.88H51.19Zm30.25,0V441.85H69.21c-.06,2.35-.18,4.72-.38,7.09a66.59,66.59,0,0,1-1,7.06,42.75,42.75,0,0,1-1.91,6.83,39.83,39.83,0,0,1-3.12,6.49Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-1"
            d="M136.16,436v39H129.4V445.3q-2.25,2.85-5,6.68c-1.8,2.56-3.6,5.19-5.41,7.92s-3.52,5.42-5.17,8.07-3.08,5-4.28,7.06h-6.23V436h6.75v28.07q2-3.22,4.43-7.1t5.14-7.65q2.67-3.79,5.33-7.28t5-6Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-1"
            d="M182.25,475h-7V457.76h-18V475h-7V436h7v15.91h18V436h7Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-1"
            d="M209,435a20.13,20.13,0,0,1,7.09,1.09,11.41,11.41,0,0,1,4.65,3.07,11.68,11.68,0,0,1,2.52,4.73,22.94,22.94,0,0,1,.75,6v24.39l-2.52.41c-1.07.18-2.29.34-3.64.49s-2.81.29-4.39.41-3.14.19-4.69.19a25.74,25.74,0,0,1-6.08-.67,13.68,13.68,0,0,1-4.8-2.14,9.85,9.85,0,0,1-3.15-3.87,13.39,13.39,0,0,1-1.13-5.78,11.17,11.17,0,0,1,1.31-5.55,10.63,10.63,0,0,1,3.57-3.76,16.5,16.5,0,0,1,5.25-2.1,28.66,28.66,0,0,1,6.31-.67,20.35,20.35,0,0,1,2.17.11c.75.08,1.47.18,2.14.3l1.77.34,1.05.22v-1.95a15.63,15.63,0,0,0-.38-3.41,7.85,7.85,0,0,0-1.35-3,7,7,0,0,0-2.66-2.1,10.54,10.54,0,0,0-4.39-.79,33.49,33.49,0,0,0-6.05.49,21.5,21.5,0,0,0-3.86,1l-.83-5.78a21.67,21.67,0,0,1,4.51-1.17A39.34,39.34,0,0,1,209,435Zm.6,35c1.65,0,3.11,0,4.39-.12a18.77,18.77,0,0,0,3.19-.41V457.84a9.19,9.19,0,0,0-2.44-.64,28.82,28.82,0,0,0-7.43,0,10.45,10.45,0,0,0-3.23.94,7,7,0,0,0-2.44,1.95,5.15,5.15,0,0,0-1,3.27q0,3.75,2.41,5.21A12.44,12.44,0,0,0,209.57,470Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-1"
            d="M256.55,475V423h7.28V468.8h19.52V423h7.28V468.8h19.52V423h7.28v52Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-1"
            d="M345.27,453.78a49.67,49.67,0,0,1,5,4.17c1.8,1.68,3.57,3.49,5.29,5.44s3.35,3.94,4.85,6,2.77,3.92,3.82,5.67H356a54.62,54.62,0,0,0-3.87-5.22q-2.2-2.67-4.61-5.14c-1.61-1.65-3.23-3.18-4.88-4.58a47.78,47.78,0,0,0-4.66-3.53V475h-7V436h7v16.73q1.8-1.73,4.13-4c1.55-1.53,3.12-3.08,4.69-4.66s3-3.07,4.39-4.5,2.46-2.61,3.31-3.56h8.1q-1.65,1.8-3.86,4.09c-1.48,1.52-3,3.09-4.58,4.69l-4.69,4.76C347.93,451.12,346.52,452.53,345.27,453.78Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-1"
            d="M404.72,455.51a24.73,24.73,0,0,1-1.35,8.41,19.13,19.13,0,0,1-3.79,6.45,16.73,16.73,0,0,1-5.82,4.17,19.26,19.26,0,0,1-14.71,0,16.82,16.82,0,0,1-5.82-4.17,19.3,19.3,0,0,1-3.79-6.45,26.75,26.75,0,0,1,0-16.78,19.29,19.29,0,0,1,3.79-6.49,16.82,16.82,0,0,1,5.82-4.17,19.26,19.26,0,0,1,14.71,0,16.73,16.73,0,0,1,5.82,4.17,19.13,19.13,0,0,1,3.79,6.49A24.82,24.82,0,0,1,404.72,455.51Zm-7.28,0q0-6.6-3-10.47a10.35,10.35,0,0,0-16.14,0q-3,3.87-3,10.47c0,4.4,1,7.9,3,10.47a10.35,10.35,0,0,0,16.14,0C396.45,463.41,397.44,459.91,397.44,455.51Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-1"
            d="M429.27,441.85c0,1.65,0,3.41-.12,5.29s-.21,3.75-.41,5.63-.48,3.73-.82,5.56a39.29,39.29,0,0,1-1.36,5.14,24.23,24.23,0,0,1-2.06,4.5,15.82,15.82,0,0,1-3,3.72,16.63,16.63,0,0,1-4.2,2.7,17.68,17.68,0,0,1-5.63,1.39l-1-5.86a11.59,11.59,0,0,0,5.82-2.78,15,15,0,0,0,3.41-4.95,24.21,24.21,0,0,0,1.66-5.78c.34-2.15.6-4.39.75-6.72s.23-4.66.26-7,0-4.57,0-6.67h26v39h-7V441.85Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-1"
            d="M474.3,435a20.19,20.19,0,0,1,7.1,1.09,11.55,11.55,0,0,1,4.65,3.07,11.79,11.79,0,0,1,2.51,4.73,22.94,22.94,0,0,1,.75,6v24.39l-2.51.41c-1.08.18-2.29.34-3.64.49s-2.82.29-4.39.41-3.14.19-4.69.19a25.74,25.74,0,0,1-6.08-.67,13.73,13.73,0,0,1-4.81-2.14,9.85,9.85,0,0,1-3.15-3.87,13.54,13.54,0,0,1-1.12-5.78,11.06,11.06,0,0,1,1.31-5.55,10.52,10.52,0,0,1,3.56-3.76,16.56,16.56,0,0,1,5.26-2.1,28.63,28.63,0,0,1,6.3-.67,20.53,20.53,0,0,1,2.18.11c.75.08,1.46.18,2.14.3l1.76.34,1.05.22v-1.95a15.62,15.62,0,0,0-.37-3.41,7.85,7.85,0,0,0-1.35-3,6.92,6.92,0,0,0-2.67-2.1,10.51,10.51,0,0,0-4.39-.79,33.45,33.45,0,0,0-6,.49,21.41,21.41,0,0,0-3.87,1l-.82-5.78a21.71,21.71,0,0,1,4.5-1.17A39.42,39.42,0,0,1,474.3,435Zm.6,35c1.65,0,3.12,0,4.39-.12a18.63,18.63,0,0,0,3.19-.41V457.84a9.08,9.08,0,0,0-2.44-.64,28.82,28.82,0,0,0-7.43,0,10.39,10.39,0,0,0-3.22.94,7,7,0,0,0-2.44,1.95,5.15,5.15,0,0,0-1,3.27q0,3.75,2.4,5.21A12.46,12.46,0,0,0,474.9,470Z"
            transform="translate(-4.35)"
          />
          <rect
            className="cls-2"
            x="111.65"
            y="98.94"
            width="66.52"
            height="115.55"
            transform="translate(-67.62 203.86) rotate(-60)"
          />
          <rect
            className="cls-3"
            x="145.1"
            y="215.12"
            width="66.52"
            height="115.55"
            transform="translate(-151.51 290.91) rotate(-60)"
          />
          <path
            className="cls-4"
            d="M255.14,0,109.61,71.22A171.77,171.77,0,0,0,78.2,156.78L342,27.69A171.64,171.64,0,0,0,255.14,0Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-5"
            d="M95,245.94a170.59,170.59,0,0,0,16.5,27L413.26,125.28a169.75,169.75,0,0,0-27.71-56.62L83.82,216.33A171.41,171.41,0,0,0,95,245.94Z"
            transform="translate(-4.35)"
          />
          <path
            className="cls-6"
            d="M241.94,341.62,387.47,270.4a171.71,171.71,0,0,0,31.41-85.56L155.12,313.93A171.67,171.67,0,0,0,241.94,341.62Z"
            transform="translate(-4.35)"
          />
        </svg>
      </div>
      <div className={styles.Logo__version}>
        {version}
        {/*window.screen.width*/}
      </div>
    </div>
  );
}
