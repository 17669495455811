import React from "react";
import styles from "./style.module.css";

export default function MessageAdmin(props) {
  return (
    <div
      className={
        styles.mainMenu__leftBaseData + " " + styles.mainMenu__leftBottomBorder
      }
      onClick={() => {
        props.states.functions.getConstData.clearOwnClassSchoolboy();
        props.changeStateMenu();
        props.changeStateMainJournal(1);
        props.states.profile.functions.getMainUserInfo();
        props.states.functions.stateTypeJournal(212);
        props.states.functions.menu.reversText(212);
        props.states.functions.setOwnClassFlag(false);
      }}
    >
      {textMenu("Профіль учня")}
    </div>
  );
}

function textMenu(text) {
  return (
    <p>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 21.75V19.75C20 18.6891 19.5786 17.6717 18.8284 16.9216C18.0783 16.1714 17.0609 15.75 16 15.75H8C6.93913 15.75 5.92172 16.1714 5.17157 16.9216C4.42143 17.6717 4 18.6891 4 19.75V21.75"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 11.75C14.2091 11.75 16 9.95914 16 7.75C16 5.54086 14.2091 3.75 12 3.75C9.79086 3.75 8 5.54086 8 7.75C8 9.95914 9.79086 11.75 12 11.75Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {text}
    </p>
  );
}
