import React from "react";
import styles from "./popUpBrowserMessage.module.css";

const popUpBrowserMessage = (props) => (
  <div className={styles.mainSettingsBlock}>
    <div className={styles.settingsBlock}>
      <p className={styles.messageBlock}>
        Для коректної роботи системи "Єдина Школа" використовуйте браузер Google
        Chrome
      </p>
      <div
        className={styles.popup__closed}
        onClick={() =>
          props.states.functions.changed.flags.changeFlagBrowserMessage(false)
        }
      >
        X
      </div>
    </div>
  </div>
);

export default popUpBrowserMessage;
