import React from "react";
import styles from "./createHomework.module.css";

import ButtonCreateHomework from "./button/button.js";
import DescriptionCreateHomework from "./description/description.js";
import SelectLessonTopicCreateHomework from "./selectlessontopic/selectlessontopic.js";
import SelectMainTopicCreateHomework from "./selectmaintopic/selectmaintopic.js";
import SelectTargetLesson from "./selectTargetLesson/selecttargetlesson.js";
import ViewData from "../viewData/viewData.js";

export default function CreateHomework(props) {
  return (
    <div className={styles.auroScroll}>
      <div className={styles.homework_wrap_slide_one}>
        <ViewData states={props.states} />
        <SelectTargetLesson states={props.states} />
        <SelectMainTopicCreateHomework states={props.states} />
        <SelectLessonTopicCreateHomework states={props.states} />
        <DescriptionCreateHomework states={props.states} />
        <ButtonCreateHomework states={props.states} />
      </div>
    </div>
  );
}
