import React from "react";
import styles from "./popUpHomework.module.css";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import Lesson from "./lesson/lesson.js";
import CreateHomework from "./createHomework/createHomework.js";
import EditHomework from "./editHomework/editHomework.js";
import ButtonDeleteColumn from "./buttonDeleteColumn/buttonDeleteColumn.js";
import ButtonClosePopup from "./buttonClosePopup/buttonClosePopup.js";

export default function popUpCreateColumn(props) {
  let tmp = null;

  if (props.states.school.baseData.columntypeIdDell !== 18) {
    if (props.states.school.baseData.columntypeIdDell !== 2) {
      if (props.states.school.baseData.columntypeIdDell !== 19) {
        if (props.states.school.baseData.columntypeIdDell !== 20) {
          if (props.states.school.baseData.columntypeIdDell !== 21) {
            tmp = FullWidthTabs(props);
          } else {
            tmp = (
              <div className={styles.headerText}>
                Тип уроку: <span>Семестрова (Скорегована)</span>
              </div>
            );
          }
        } else {
          tmp = (
            <div className={styles.headerText}>
              Тип уроку: <span>Семестрова (Системна середня)</span>
            </div>
          );
        }
      } else {
        tmp = (
          <div className={styles.headerText}>
            Тип уроку: <span>Семестрова</span>
          </div>
        );
      }
    } else {
      tmp = (
        <>
          <div className={styles.headerText}>
            Тип уроку: <span>Тематична</span>
          </div>
          <div className={styles.headerText}>
            Тема:{" "}
            <span>{props.states.school.baseData.columnPopUpData.Topic}</span>
          </div>
        </>
      );
    }
  } else {
    tmp = (
      <>
        <div className={styles.headerText}>
          Тип уроку: <span>Тематична (Системна середня)</span>
        </div>
        <div className={styles.headerText}>
          Тема:{" "}
          <span>{props.states.school.baseData.columnPopUpData.Topic}</span>
        </div>
      </>
    );
  }

  return (
    <div className={styles.mainRateBlock}>
      <div className={styles.block}>
        <ButtonDeleteColumn states={props.states} />
        <ButtonClosePopup states={props.states} />
        {tmp}
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 600,
    marginTop: 50,
  },
}));

function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  /*const handleDescript = name => event => {
    props.states.functions.post.topic.editHomeWorkDescriptText( event.target.value );
  };*/

  //let tmp = createSelectTypeColumn( props );

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Інформація про урок" {...a11yProps(0)} />
          <Tab label="Створити домашнє завдання" {...a11yProps(1)} />
          <Tab label="Редагування домашніх завдань" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Lesson states={props.states} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <CreateHomework states={props.states} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <EditHomework states={props.states} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
