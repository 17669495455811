import React from "react";
import styles from "./journalProfile.module.css";

import ClosePopUp from "./closePopUp/closePopUp.js";

function createViewParent(props) {
  let temp = null;
  if (
    props.states.school.arr.tmpParent !== null &&
    props.states.school.arr.tmpParent.Embed.Parents !== null
  ) {
    if (
      props.states.school.arr.tmpParent.Embed.hasOwnProperty("Parents") !==
      false
    ) {
      temp = props.states.school.arr.tmpParent.Embed.Parents.map((item) => {
        let tmpFirstName = "",
          tmpSecondName = "",
          tmpLastName = "",
          tmpPhone = null,
          tmpEmail = null;
        if (item.FirstName !== null) tmpFirstName = item.FirstName;
        if (item.SecondName !== null) tmpSecondName = item.SecondName;
        if (item.LastName !== null) tmpLastName = item.LastName;
        if (item.Phone === null) tmpPhone = "Дані відсутні";
        else tmpPhone = item.Phone;
        if (item.Email === null) tmpEmail = "Дані відсутні";
        else tmpEmail = item.Email;
        return (
          <div className={styles.PIB}>
            <div>
              <span>ПІБ: </span>
              {tmpFirstName + " " + tmpSecondName + " " + tmpLastName}
            </div>
            <div>
              <span>Телефон: </span>
              {tmpPhone}
            </div>
            <div>
              <span>Електронна пошта: </span>
              {tmpEmail}
            </div>
          </div>
        );
      });
    } else {
      return viewTextElseNotDataParent();
    }
  } else {
    return viewTextElseNotDataParent();
  }
  if (temp.length === 0) {
    temp = viewTextElseNotDataParent();
  }
  return temp;
}

function createViewSchoolboy(props) {
  let temp = null;
  if (props.states.school.arr.tmpParent !== null) {
    temp = (
      <div className={styles.PIB}>
        <div>
          <span>ПІБ: </span>
          {StringText(props.states.school.arr.tmpParent.FirstName) +
            " " +
            StringText(props.states.school.arr.tmpParent.SecondName) +
            " " +
            StringText(props.states.school.arr.tmpParent.LastName)}
        </div>
      </div>
    );
  } else {
    temp = (
      <div className={styles.PIB}>
        <div>
          <span>ПІБ: </span>
          {StringText(null) + " " + StringText(null) + " " + StringText(null)}
        </div>
      </div>
    );
  }
  return temp;
}

function StringText(data) {
  let tmp = "";
  if (data !== null) tmp = data;
  return tmp;
}

export default function JournalProfile(props) {
  return (
    <div className={styles.mainRateBlock}>
      <div className={styles.block}>
        <ClosePopUp states={props.states} />
        {viewDataAssembly(props)}
      </div>
    </div>
  );
}

function viewDataAssembly(props) {
  return (
    <div className={styles.profile__wrap}>
      {viewDataSchoolboy(props)}
      {viewDataParent(props)}
    </div>
  );
}

function viewDataSchoolboy(props) {
  return (
    <div className={styles.pib_first}>
      <div className={styles.popup_topic}>Дані учня</div>
      {createViewSchoolboy(props)}
    </div>
  );
}

function viewDataParent(props) {
  return (
    <div className={styles.pib_second}>
      <div className={styles.popup_topic}>Дані про батьків</div>
      {createViewParent(props)}
    </div>
  );
}

function viewTextElseNotDataParent() {
  return (
    <div>
      <div>Дані про батьків відсутні</div>
    </div>
  );
}
