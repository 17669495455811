import React from "react";
import styles from "./popUpOKTema.module.css";

import Button from "@material-ui/core/Button";

export default function PopUpCreateTopic(props) {
  return (
    <div className={styles.mainRateBlock}>
      <div className={styles.block}>
        {ClosePopUp(props)}
        {BlockText(props)}
        {BlockButtonCancelAndOk(props)}
      </div>
    </div>
  );
}

/**
 * Функция закрытия попапа
 * @param {*} props
 */
function ClosePopUp(props) {
  let funcSrtTypeColumn = props.states.functions.srtTypeColumn;
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        funcSrtTypeColumn(null);
      }}
    >
      X
    </div>
  );
}

/**
 * Функция вывода текста в попапе
 */
function BlockText(props) {
  let tmp = null;
  if (props.states.school.arr.rateValueArr.length > 0) {
    tmp = props.states.school.arr.rateValueArr
      .filter((item) => {
        return item.Value === props.states.school.baseData.typeColumn;
      })
      .map((item) => {
        return <span>{item.Title}</span>;
      });
  }
  let temp = null;
  if (props.states.school.arr.partSchoolboy.length > 0) {
    temp = props.states.school.arr.partSchoolboy
      .filter((item) => {
        return (
          item.Id === props.states.school.baseData.popUpDataRate.schoolBoyId
        );
      })
      .map((item) => {
        if (item.Nick !== null) {
          return <span>{item.Nick}</span>;
        } else {
          return (
            <span>
              {item.LastName} {item.FirstName}
            </span>
          );
        }
      });
  }
  return (
    <div className={styles.textHelp}>
      <span>Увага!</span>
      <br />
      Після закриття теми/семестру, коригування оцінок, виставлених в межах
      теми, неможливе.
      <br />
      Учень: {temp}
      <br />
      Оцінка: {tmp}
    </div>
  );
}

/**
 * Функция выводит кнопки Отмена и Принять
 * @param {*} props
 */
function BlockButtonCancelAndOk(props) {
  let funcRateRate = props.states.functions.post.rate.rateRate,
    funcPopUpRatesPicker =
      props.states.functions.changed.flags.popUpRatesPicker,
    funcSrtTypeColumn = props.states.functions.srtTypeColumn,
    journalId = props.states.school.baseData.popUpDataRate.journalId,
    schoolBoyId = props.states.school.baseData.popUpDataRate.schoolBoyId,
    typeId = props.states.school.baseData.popUpDataRate.typeId,
    typeColumn = props.states.school.baseData.typeColumn;
  const stylesLine = {
    width: "218px",
    height: "50px",
  };
  return (
    <div className={styles.buttonHelp}>
      <Button
        style={stylesLine}
        variant="contained"
        color="primary"
        onClick={() => {
          funcSrtTypeColumn(null);
        }}
      >
        Скасувати
      </Button>
      <Button
        style={stylesLine}
        variant="contained"
        color="primary"
        onClick={() => {
          funcRateRate(journalId, schoolBoyId, typeId, typeColumn);
          funcPopUpRatesPicker();
          funcSrtTypeColumn(null);
        }}
      >
        Підтвердити
      </Button>
    </div>
  );
}
