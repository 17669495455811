import React from "react";
import styles from "./dirtyBlock.module.css";

import BlockUp from "./BlockUp/BlockUp.js";

export default function DirtyBlock(props) {
  return (
    <div className={styles.wrap}>
      <div className={styles.block}>
        <BlockUp states={props.states} />
      </div>
    </div>
  );
}
