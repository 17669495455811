import React from "react";
import styles from "./popUpCreateTopic.module.css";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CreateTopic from "./createTopic/createTopic.js";
import BindTopic from "./bindTopic/bindTopic.js";
import EditTopic from "./editTopic/editTopic.js";
import UploadTopic from "./uploadTopic/uploadTopic.js";

export default function PopUpCreateTopic(props) {
  return (
    <div className={styles.mainRateBlock}>
      <div className={styles.block}>
        {ClosePopUp(props)}
        {viewMenu(props)}
      </div>
    </div>
  );
}

function ClosePopUp(props) {
  let funcChangeFlagCreateTopic =
    props.states.functions.changed.flags.changeFlagCreateTopic;
  let funcClearCreateThemesPopUp =
    props.states.functions.get.column.clearCreateThemesPopUp;
  let funcSetUploadGood = props.states.functions.setUploadGood;
  return (
    <div
      className={styles.popup__closed}
      onClick={() => {
        funcChangeFlagCreateTopic(false);
        funcClearCreateThemesPopUp();
        funcSetUploadGood(null);
        props.states.school.journalTopics.clear();
      }}
    >
      X
    </div>
  );
}

function viewMenu(props) {
  return FullWidthTabs(props);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 700,
    marginTop: 30,
  },
}));

function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Створити" {...a11yProps(0)} />
          <Tab label="Додати до теми, тему уроку" {...a11yProps(1)} />
          <Tab label="Редагування" {...a11yProps(2)} />
          <Tab label="Завантаження" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CreateTopic states={props.states} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <BindTopic states={props.states} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <EditTopic states={props.states} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <UploadTopic states={props.states} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
