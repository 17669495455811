import React from "react";
import styles from "./topics.module.css";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { makeStyles } from "@material-ui/core/styles";

export default function popUpCreateColumn(props) {
  return (
    <div className={styles.popup_topic_scrol}>
      <div className={styles.popup_topic}>Обрати тему</div>
      <div className={styles.popup_div_selected}>
        <Select
          className={styles.popup_selected}
          native
          value={props.states.school.journalColumn.createTopicColumn.mainTopic}
          onChange={(value) => {
            props.states.school.journalColumn.createTopicColumn.setMainTopic(
              value.target.value
            );
          }}
          input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
        >
          <option selected disabled key={5677} value={0}>
            Обрати тему
          </option>
          {createSelectMainTopic(props)}
        </Select>
      </div>
      {createThemes(props)}
    </div>
  );
}

function createSelectMainTopic(props) {
  let tmp = null;
  if (props.states.school.journalColumn.partTopics !== null) {
    tmp = props.states.school.journalColumn.partTopics
      .filter((e) => {
        return e.ParentId === null;
      })
      .filter((e) => {
        return e.Complete !== true;
      })
      .filter((e) => {
        let t = props.states.school.journalColumn.partTopics.filter((j) => {
          return j.ParentId === e.Id;
        });
        return t.length > 0;
      })
      .map((e) => {
        return (
          <option key={e.Id} value={e.Id}>
            {e.Title}
          </option>
        );
      });
  }
  return tmp;
}

/**
 * Создает кнопку Закрыть тему
 * @param {*} props
 */
function createThemes(props) {
  let tmp = null;
  let createThemes = props.states.functions.createThemes;
  if (props.states.school.journalColumn.createTopicColumn.mainTopic !== null) {
    tmp = (
      <div className={styles.popup_button}>
        <Button
          onClick={() => {
            createThemes(true);
          }}
          variant="contained"
          color="secondary"
          className={styles.Button}
        >
          Закрити тему
        </Button>
      </div>
    );
  }
  return tmp;
}
