import React from "react";
import styles from "./common.module.css";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { makeStyles } from "@material-ui/core/styles";

export default function popUpCreateColumn(props) {
  return (
    <div>
      <div className={styles.homework_wrap_slide_one}>
        <div className={styles.popup_topic}>Обрати тип колонки</div>
        <div className={styles.popup_div_selected}>
          <Select
            native
            value={
              props.states.school.journalColumn.createCommonColumn.typeLesson
            }
            className={styles.popup_selected}
            onChange={(value) => {
              props.states.school.journalColumn.createCommonColumn.setTypeLesson(
                value.target.value
              );
            }}
            input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
          >
            <option selected disabled key={5677} value={0}>
              Обрати
            </option>
            {props.states.school.arr.typeColumnArr
              .filter((item) => {
                return item.Id !== 2;
              })
              .filter((item) => {
                return item.Id !== 18;
              })
              .filter((item) => {
                return item.Id !== 19;
              })
              .filter((item) => {
                return item.Id !== 20;
              })
              .filter((item) => {
                return item.Id !== 21;
              })
              .map((e) => {
                return (
                  <option key={e.Id} value={e.Id}>
                    {e.Title}
                  </option>
                );
              })}
          </Select>
        </div>
        <div className={styles.popup_topic}>Обрати тему</div>
        <div className={styles.popup_div_selected}>
          <Select
            native
            value={
              props.states.school.journalColumn.createCommonColumn.mainTopic
            }
            className={styles.popup_selected}
            onChange={(value) => {
              props.states.school.journalColumn.createCommonColumn.setMainTopic(
                value.target.value
              );
            }}
            input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
          >
            <option selected disabled key={5677} value={0}>
              Обрати тему
            </option>
            {createSelectMainTopic(props)}
          </Select>
        </div>
        <div className={styles.popup_topic}>Обрати тему уроку</div>
        <div className={styles.popup_div_selected}>
          <Select
            native
            value={
              props.states.school.journalColumn.createCommonColumn.lessonTopic
            }
            className={styles.popup_selected}
            onChange={(value) => {
              props.states.school.journalColumn.createCommonColumn.setLessonTopic(
                value.target.value
              );
            }}
            input={<OutlinedInput name="age" id="outlined-age-native-simple" />}
          >
            <option selected disabled key={5677} value={0}>
              Обрати тему уроку
            </option>
            {createSelectLessonTopic(props)}
          </Select>
        </div>
        {createButton(props)}
      </div>
    </div>
  );
}

/**
 * Создает кнопку Создать колонку
 * @param {*} props
 */
function createButton(props) {
  let tmp = null;
  //let createThemeTmp = props.states.school.baseData.createThemeTmp;
  let funcCreateColumnThemesAndType =
    props.states.functions.post.column.createColumnThemesAndType;
  if (
    4 ===
    Number(props.states.school.journalColumn.createCommonColumn.typeLesson)
  ) {
    tmp = (
      <div className={styles.popup_button}>
        <Button
          onClick={() => {
            //funcCreateColumnThemesAndType();
            props.states.school.journalColumn.createCommonColumn.create();
          }}
          variant="contained"
          color="secondary"
          className={styles.Button}
        >
          Створити
        </Button>
      </div>
    );
  } else if (
    props.states.school.journalColumn.createCommonColumn.typeLesson !== null &&
    props.states.school.journalColumn.createCommonColumn.mainTopic !== null &&
    props.states.school.journalColumn.createCommonColumn.lessonTopic !== null
  ) {
    /*if( createThemeTmp !== 0 ) {*/
    tmp = (
      <div className={styles.popup_button}>
        <Button
          onClick={() => {
            //funcCreateColumnThemesAndType();
            props.states.school.journalColumn.createCommonColumn.create();
          }}
          variant="contained"
          color="secondary"
          className={styles.Button}
        >
          Створити
        </Button>
      </div>
    );
    /*}*/
  }
  return tmp;
}

function createSelectMainTopic(props) {
  let tmp = null;
  if (props.states.school.journalColumn.partTopics !== null) {
    tmp = props.states.school.journalColumn.partTopics
      .filter((e) => {
        return e.ParentId === null;
      })
      .map((e) => {
        return (
          <option key={e.Id} value={e.Id}>
            {e.Title}
          </option>
        );
      });
  }
  return tmp;
}

/**
 * Создаем массив элементов для выпадающего списка тем по группе
 * @param {*} props
 */
function createSelectLessonTopic(props) {
  let tmp = null;
  if (
    props.states.school.journalColumn.partTopics !== null &&
    props.states.school.journalColumn.createCommonColumn.mainTopic !== null
  ) {
    tmp = props.states.school.journalColumn.partTopics
      .filter((e) => {
        return (
          e.ParentId ===
          Number(props.states.school.journalColumn.createCommonColumn.mainTopic)
        );
      })
      .map((e) => {
        return (
          <option key={e.Id} value={e.Id}>
            {e.Title}
          </option>
        );
      });
  }
  return tmp;
}
