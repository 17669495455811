import React from "react";
import styles from "./Cell.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    height: "32px",
    width: "53px",
  },
}));

export default function Cell(props) {
  const classes = useStyles();
  return (
    <div className={styles.cell}>
      <Button
        className={classes.button + " " + styles.buttonsize}
        variant="contained"
        color="default"
        onClick={() => {
          doClick(props);
        }}
      >
        {props.markT}
      </Button>
    </div>
  );
}

function doClick(props) {
  if (
    props.states.school.baseData.popUpDataRate.typeId === 2 ||
    props.states.school.baseData.popUpDataRate.typeId === 19 ||
    props.states.school.baseData.popUpDataRate.typeId === 20 ||
    props.states.school.baseData.popUpDataRate.typeId === 21
  ) {
    props.states.functions.srtTypeColumn(props.mark);
  } else {
    props.states.functions.post.rate.rateRate(
      props.states.school.baseData.popUpDataRate.journalId,
      props.states.school.baseData.popUpDataRate.schoolBoyId,
      props.states.school.baseData.popUpDataRate.typeId,
      props.mark
    );
    props.states.functions.changed.flags.popUpRatesPicker();
  }
}
