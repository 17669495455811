import renderThree from '../render.js';

let state = {
  profile: {
    mainUserInfo: null,
    schoolboyId: null,
    schoolId: null,
    classId: null,
    schoolboyStatus: null,
    parentsInfo: null,
    titleSchool: null,
    titleClass: null,
    classTeacherId: null,
    classTeacherInfo: null,
    urlAvatarPhoto: null,
    urlCoverPhoto: null,
    isLoadingSpinner: false,

    functions: {
      getMainUserInfo() {
        let tempUrl = state.school.baseData.domen + '/profile';
        state.functions.getConstData.crud
          .get(tempUrl)
          .then(
            (body) => {
              let tmp = { ...state };
              tmp.profile.mainUserInfo = body;
              tmp.profile.schoolboyId = body?.Id;
              tmp.profile.schoolId = body?.SchoolId;
              tmp.profile.classId = body?.ClassId;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then(() =>
            state.profile.functions.changeUrlPhoto(
              'urlAvatarPhoto',
              'avatar.png',
            ),
          )
          .then(() =>
            state.profile.functions.changeUrlPhoto(
              'urlCoverPhoto',
              'cover.png',
            ),
          )
          .then(() => state.profile.functions.getParentsInfo())
          .then(() => state.profile.functions.getSchoolInfo())
          .then(() => state.profile.functions.getClassInfo());
      },
      getParentsInfo() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.profile.schoolId +
          '/SchoolBoy/' +
          state.profile.schoolboyId +
          '?embed=Parent';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            if (body?.States?.length > 0) {
              tmp.profile.schoolboyStatus = body?.States[0]?.State;
            }
            tmp.profile.parentsInfo = body?.Embed?.Parents;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getSchoolInfo() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          'School/' +
          state.profile.schoolId;
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            // tmp.profile.titleSchool = body.Title;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getClassInfo() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.profile.schoolId +
          '/Class/' +
          state.profile.classId;
        state.functions.getConstData.crud
          .get(tempUrl)
          .then(
            (body) => {
              let tmp = { ...state };
              tmp.profile.classTeacherId = body?.ClassTeacherId;
              tmp.profile.titleClass = body?.Title;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then(() => state.profile.functions.getClassTeacherInfo());
      },
      getClassTeacherInfo() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.profile.schoolId +
          '/Teacher/' +
          state.profile.classTeacherId;

        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.profile.classTeacherInfo = body;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      changeUrlPhoto(name, value) {
        let tmp = { ...state };
        let tmpUrl = null;
        if (value !== null) {
          tmpUrl = `${state.school.baseData.domenFS}/school/${state.profile.schoolId}/schoolboy/${state.profile.schoolboyId}/diary/${value}?token=${state.school.baseData.token}`;
        } else {
          tmpUrl = null;
        }
        tmp.profile[name] = tmpUrl;
        stateUpdate(tmp);
      },
      setIsLoadingSpinner(value) {
        let tmp = { ...state };
        tmp.profile.isLoadingSpinner = value;
        stateUpdate(tmp);
      },
    },
  },
  distEducation: {
    arrayStream: null,
    arrayLessons: null,
    selectLessons: null,
    selectLessonsDate: null,
    selectStreamName: null,
    selectLessonsHomeWork: null,
    sizeVideo: null,
    functions: {
      getStream() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/SchoolBoy/' +
          state.school.baseData.teacherId +
          '/Appointment?start=' +
          state.school.baseData.startAcademicPeriod +
          '&end=' +
          state.school.baseData.endAcademicPeriod +
          '&fileRole=teacher&embed=Topic,Homework';

        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            let arrayStream = body?.Items?.filter(
              (item) => item?.StreamStatus === 2,
            );
            tmp.distEducation.arrayStream = arrayStream;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getVideoSize(value) {
        let tempUrl =
          state.school.baseData.domenStream +
          '/stream/size?file=' +
          value.StreamName +
          '/merge/resized_thumb.jpg';

        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.distEducation.sizeVideo = body;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getPoster() {
        let tempUrl =
          state.school.baseData.domenStream +
          '/stream/' +
          state.school.distEducation.arrayLessons +
          state.profile.schoolId;
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.profile.titleSchool = body?.Title;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      setStreamLessons(value) {
        let tmp = { ...state };
        tmp.distEducation.arrayLessons = value;
        stateUpdate(tmp);
      },
      setSelectLessons(value) {
        let tmp = { ...state };
        tmp.distEducation.selectLessons = value;
        tmp.distEducation.selectLessonsDate = value?.Date;
        tmp.distEducation.selectStreamName = value?.StreamName;
        tmp.distEducation.selectLessonsHomeWork = value?.Embed?.Homeworks;
        stateUpdate(tmp);
      },
    },
  },
  school: {
    print: {
      journalRateAndTopic: {
        partPrintRates: null,
        flagClassSubject: false,
        classSchoolboy: false,
        listPart: false,
        listTeachers: false,
        flagClick: false,
        flagMoveSchoolboy: false,
        flagZamin: false,
        flagIndEduk: false,
        flagGurt: false,
        flagGrupProdDay: false,
        flagFakult: false,
        setFlagRatesTrue: (value) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.flagClick = value;
          stateUpdate(tmp);
        },
        setFlagMoveSchoolboyTrue: (value) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.flagMoveSchoolboy = value;
          stateUpdate(tmp);
        },
        setFlagZaminTrue: (value) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.flagZamin = value;
          stateUpdate(tmp);
        },
        setFlagIndEdukTrue: (value) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.flagIndEduk = value;
          stateUpdate(tmp);
        },
        setFlagGurtTrue: (value) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.flagGurt = value;
          stateUpdate(tmp);
        },
        setFlagGrupProdDayTrue: (value) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.flagGrupProdDay = value;
          stateUpdate(tmp);
        },
        setFlagFakultTrue: (value) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.flagFakult = value;
          stateUpdate(tmp);
        },
        buttons: {
          instruction: 0,
          flagCreateBtn: false,
          spinner: false,
        },
        setSpinner: (value, pdfMake) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.buttons.spinner = value;
          stateUpdate(tmp);
        },
        setFlagBtn: (value) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.buttons.flagCreateBtn = value;
          stateUpdate(tmp);
        },
        setBtnInstruction: (value) => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.buttons.instruction = value;
          stateUpdate(tmp);
        },
      },
    },
    journalTopics: {
      partTopics: null,
      partHomework: null,
      partTopicsClass: null,
      editColumn: false,
      editColumnId: null,
      setColumn(value, Id) {
        let temp = { ...state };
        temp.school.journalTopics.editColumn = value;
        temp.school.journalTopics.editColumnId = Id;
        stateUpdate(temp);
      },
      setArrFiles() {
        let temp = { ...state };
        temp.school.arr.PartHW = [];
        stateUpdate(temp);
      },
      setPartTopics: (value) => {
        let tmp = { ...state };
        tmp.school.journalTopics.partTopics = value;
        stateUpdate(tmp);
      },
      setPartHomework: (value) => {
        let tmp = { ...state };
        tmp.school.journalTopics.partHomework = value;
        stateUpdate(tmp);
      },
      clear() {
        let tmp = { ...state };
        tmp.school.journalTopics.createMain.semestr = null;
        tmp.school.journalTopics.createMain.number = null;
        tmp.school.journalTopics.createMain.title = null;
        tmp.school.journalTopics.createMain.description = null;
        tmp.school.journalTopics.createLesson.parentTopic = null;
        tmp.school.journalTopics.createLesson.number = null;
        tmp.school.journalTopics.createLesson.title = null;
        tmp.school.journalTopics.createLesson.description = null;
        tmp.school.journalTopics.createLesson.homework = null;
        tmp.school.journalTopics.bindLesson.parentId = null;
        tmp.school.journalTopics.bindLesson.lessonTopicId = null;
        tmp.school.journalTopics.editMain.id = null;
        tmp.school.journalTopics.editMain.semestr = null;
        tmp.school.journalTopics.editMain.number = null;
        tmp.school.journalTopics.editMain.title = null;
        tmp.school.journalTopics.editMain.description = null;
        tmp.school.journalTopics.editLesson.id = null;
        tmp.school.journalTopics.editLesson.parentTopic = null;
        tmp.school.journalTopics.editLesson.number = null;
        tmp.school.journalTopics.editLesson.title = null;
        tmp.school.journalTopics.editLesson.description = null;
        stateUpdate(tmp);
      },
      getTopics() {
        let temp = { ...state };
        temp.school.journalTopics.partTopics = null;
        stateUpdate(temp);
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Topic?partId=' +
          state.school.baseData.partNumber;

        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.journalTopics.partTopics = body?.Items;
            temp.school.arr.lessonTopic = body?.Items;
            temp.school.popUpFlag.flagLessonTopic = true;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      //Вытягиваю все темы для печатного журнала класса
      getTopicsClass() {
        let temp = { ...state };
        temp.school.journalTopics.partTopics = null;
        stateUpdate(temp);
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Topic?classId=' +
          state.school.baseData.teacher.OwnClassId;

        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.journalTopics.partTopicsClass = body?.Items;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getTopicsXLSX() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Part/' +
          state.school.baseData.partNumber +
          '/topics.xlsx' +
          '?apiKey=' +
          state.school.baseData.apikey +
          '&token=' +
          state.school.baseData.token;
        return tempUrl;
      },
      getHomeworks(partId) {
        let tempUrl = null;
        tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Part/' +
          partId +
          '/Appointment?start=' +
          state.school.baseData.startAcademicPeriod +
          '&end=' +
          state.school.baseData.endAcademicPeriod +
          '&embed=Homework,Topic,Rate';

        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.journalTopics.partHomework = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      delete() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/Part/' +
          state.school.baseData.PartId +
          '/RemoveTopic';
        let dataJS = JSON.stringify({
          TopicId: Number(state.school.journalTopics?.journalColumn?.delId),
        });
        state.functions.getConstData.crud
          .post(tempUrl, dataJS)
          .then(
            (body) => {
              return body;
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then(() => {
            state.school.journalTopics.getTopics();
            state.functions.get.column.dataTopic();
          });
      },

      createMain: {
        semestr: null,
        number: null,
        title: null,
        description: null,
        setSemestr: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.createMain.semestr = value;
          stateUpdate(tmp);
        },
        setNumber: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.createMain.number = value;
          stateUpdate(tmp);
        },
        setTitle: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.createMain.title = value;
          stateUpdate(tmp);
        },
        setDescription: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.createMain.description = value;
          stateUpdate(tmp);
        },
        create() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.partNumber +
            '/CreateTopic';
          let dataJS = JSON.stringify({
            AcademicSemester: Number(
              state.school.journalTopics.createMain?.semestr,
            ),
            Number: Number(state.school.journalTopics?.createMain?.number),
            Title: state.school.journalTopics?.createMain?.title,
            Description: state.school.journalTopics?.createMain?.description,
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                state.school.journalTopics.setPartTopics(body);
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.school.journalTopics.getTopics(
                state.school.baseData?.partNumber,
              );
            })
            .then(() => {
              state.school.journalTopics.clear();
            });
        },
      },
      createLesson: {
        parentTopic: null,
        number: null,
        title: null,
        description: null,
        homework: null,
        setParentTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.createLesson.parentTopic = value;
          stateUpdate(tmp);
        },
        setNumber: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.createLesson.number = value;
          stateUpdate(tmp);
        },
        setTitle: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.createLesson.title = value;
          stateUpdate(tmp);
        },
        setDescription: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.createLesson.description = value;
          stateUpdate(tmp);
        },
        setHomework: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.createLesson.homework = value;
          stateUpdate(tmp);
        },
        getSemestrMainTopic() {
          let tmp = null;
          if (state.school.journalTopics?.createLesson?.parentTopic !== null) {
            if (state.school?.journalTopics?.partTopics !== null) {
              tmp = state.school?.journalTopics?.partTopics?.filter((e) => {
                return (
                  Number(
                    state.school.journalTopics?.createLesson?.parentTopic,
                  ) === e?.Id
                );
              });
            }
          }
          return tmp[0].AcademicSemester;
        },
        create() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.partNumber +
            '/CreateTopic';
          let dataJS = JSON.stringify({
            ParentID: Number(
              state.school.journalTopics?.createLesson?.parentTopic,
            ),
            Number: Number(state.school.journalTopics?.createLesson?.number),
            Title: state.school.journalTopics?.createLesson?.title,
            Description: state.school.journalTopics?.createLesson?.description,
            Homework: state.school.journalTopics?.createLesson?.homework,
            AcademicSemester: Number(
              state.school.journalTopics.createLesson.getSemestrMainTopic(),
            ),
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                state.school.journalTopics.setPartTopics(body);
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.school.journalTopics.getTopics(
                state.school.baseData?.partNumber,
              );
            })
            .then(() => {
              state.school.journalTopics.clear();
            });
        },
      },
      bindLesson: {
        parentId: null,
        lessonTopicId: null,
        setParentId: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.bindLesson.parentId = value;
          stateUpdate(tmp);
        },
        setLessonTopicId: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.bindLesson.lessonTopicId = value;
          stateUpdate(tmp);
        },
        getSemestrMainTopic() {
          let tmp = null;
          if (state.school.journalTopics?.bindLesson?.parentId !== null) {
            if (state.school.journalTopics?.partTopics !== null) {
              tmp = state.school.journalTopics?.partTopics?.filter((e) => {
                return (
                  Number(state.school.journalTopics?.bindLesson?.parentId) ===
                  e.Id
                );
              });
            }
          }
          return tmp[0].AcademicSemester;
        },
        bindLessonTopic() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/EditTopic';
          let dataJS = JSON.stringify({
            TopicId: Number(
              state.school.journalTopics?.bindLesson?.lessonTopicId,
            ),
            ParentID: Number(state.school.journalTopics?.bindLesson?.parentId),
            AcademicSemester: Number(
              state.school.journalTopics?.bindLesson.getSemestrMainTopic(),
            ),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.school.journalTopics.getTopics();
              state.functions.get.column.dataTopic();
              state.school.journalTopics.clear();
            });
        },
      },
      editMain: {
        id: null,
        semestr: null,
        number: null,
        title: null,
        description: null,
        setId: (value) => {
          if (state.school.journalTopics?.partTopics !== null) {
            for (
              let e = 0;
              e < state.school.journalTopics?.partTopics?.length;
              e++
            ) {
              if (
                state.school.journalTopics?.partTopics[e]?.Id === Number(value)
              ) {
                let temp = { ...state };
                temp.school.journalTopics.editMain.id =
                  state.school.journalTopics?.partTopics[e]?.Id;
                temp.school.journalTopics.editMain.semestr =
                  state.school.journalTopics?.partTopics[e]?.AcademicSemester;
                temp.school.journalTopics.editMain.number =
                  state.school.journalTopics?.partTopics[e]?.Number;
                temp.school.journalTopics.editMain.title =
                  state.school.journalTopics?.partTopics[e]?.Title;
                temp.school.journalTopics.editMain.description =
                  state.school.journalTopics?.partTopics[e]?.Description;
                stateUpdate(temp);
              }
            }
          }
        },
        setSemestr: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.editMain.semestr = value;
          stateUpdate(tmp);
        },
        setNumber: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.editMain.number = value;
          stateUpdate(tmp);
        },
        setTitle: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.editMain.title = value;
          stateUpdate(tmp);
        },
        setDescription: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.editMain.description = value;
          stateUpdate(tmp);
        },
        edit() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/EditTopic';
          let dataJS = JSON.stringify({
            TopicId: Number(state.school.journalTopics?.editMain?.id),
            AcademicSemester: Number(
              state.school.journalTopics?.editMain?.semestr,
            ),
            Number: Number(state.school.journalTopics?.editMain?.number),
            Title: state.school.journalTopics?.editMain?.title,
            Description: state.school.journalTopics?.editMain?.description,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.school.journalTopics.getTopics();
              state.functions.get.column.dataTopic();
              state.school.journalTopics.clear();
            });
        },
        delete() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/RemoveTopic';
          let dataJS = JSON.stringify({
            TopicId: Number(state.school.journalTopics?.editMain?.id),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.school.journalTopics.getTopics();
              state.functions.get.column.dataTopic();
              state.school.journalTopics.clear();
            });
        },
      },
      editLesson: {
        id: null,
        parentTopic: null,
        number: null,
        title: null,
        description: null,
        homework: null,
        setId: (value) => {
          if (state.school.journalTopics?.partTopics !== null) {
            for (
              let e = 0;
              e < state.school.journalTopics?.partTopics?.length;
              e++
            ) {
              if (
                state.school.journalTopics?.partTopics[e]?.Id === Number(value)
              ) {
                let temp = { ...state };
                temp.school.journalTopics.editLesson.id =
                  state.school.journalTopics?.partTopics[e]?.Id;
                temp.school.journalTopics.editLesson.semestr =
                  state.school.journalTopics?.partTopics[e]?.AcademicSemester;
                temp.school.journalTopics.editLesson.number =
                  state.school.journalTopics?.partTopics[e]?.Number;
                temp.school.journalTopics.editLesson.title =
                  state.school.journalTopics?.partTopics[e]?.Title;
                temp.school.journalTopics.editLesson.description =
                  state.school.journalTopics?.partTopics[e]?.Description;
                stateUpdate(temp);
              }
            }
          }
        },
        setParentTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.editLesson.parentTopic = value;
          stateUpdate(tmp);
        },
        setNumber: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.editLesson.number = value;
          stateUpdate(tmp);
        },
        setTitle: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.editLesson.title = value;
          stateUpdate(tmp);
        },
        setDescription: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.editLesson.description = value;
          stateUpdate(tmp);
        },
        setHomework: (value) => {
          let tmp = { ...state };
          tmp.school.journalTopics.editLesson.homework = value;
          stateUpdate(tmp);
        },
        getSemestrMainTopic() {
          let tmp = null;
          if (state.school.journalTopics?.editLesson?.parentTopic !== null) {
            if (state.school.journalTopics?.partTopics !== null) {
              tmp = state.school.journalTopics?.partTopics?.filter((e) => {
                return (
                  Number(
                    state.school.journalTopics?.editLesson?.parentTopic,
                  ) === e.Id
                );
              });
            }
          }
          return tmp[0].AcademicSemester;
        },
        edit() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/EditTopic';
          let dataJS = JSON.stringify({
            TopicId: Number(state.school.journalTopics?.editLesson?.id),
            ParentID: Number(
              state.school?.journalTopics?.editLesson?.parentTopic,
            ),
            Number: Number(state.school.journalTopics?.editLesson?.number),
            Title: state.school.journalTopics?.editLesson?.title,
            Description: state.school.journalTopics?.editLesson?.description,
            Homework: state.school.journalTopics?.editLesson?.homework,
            AcademicSemester: Number(
              state.school.journalTopics?.editLesson?.getSemestrMainTopic(),
            ),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.school.journalTopics.getTopics();
              state.functions.get.column.dataTopic();
              state.school.journalTopics.clear();
            });
        },
        delete() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/RemoveTopic';
          let dataJS = JSON.stringify({
            TopicId: Number(state.school.journalTopics?.editLesson?.id),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.school.journalTopics.getTopics();
              state.functions.get.column.dataTopic();
              state.school.journalTopics.clear();
            });
        },
      },
      upload: {},
    },
    journalColumn: {
      partTopics: null,
      setPartTopics: (value) => {
        let tmp = { ...state };
        tmp.school.journalColumn.partTopics = value;
        stateUpdate(tmp);
      },
      clear() {
        let tmp = { ...state };
        tmp.school.journalColumn.createCommonColumn.mainTopic = null;
        tmp.school.journalColumn.createCommonColumn.lessonTopic = null;
        tmp.school.journalColumn.createCommonColumn.typeLesson = null;
        tmp.school.journalColumn.createTopicColumn.mainTopic = null;
        tmp.school.journalColumn.createSemestrColumn.semestr = null;
        tmp.school.journalColumn.updateColumn.typeLesson = null;
        tmp.school.journalColumn.updateColumn.mainTopic = null;
        tmp.school.journalColumn.updateColumn.lessonTopic = null;
        tmp.school.journalColumn.createHomework.targetLesson = null;
        tmp.school.journalColumn.createHomework.mainTopic = null;
        tmp.school.journalColumn.createHomework.lessonTopic = null;
        tmp.school.journalColumn.createHomework.targetLesson = null;
        tmp.school.journalColumn.createHomework.deskription = null;
        tmp.school.journalColumn.editHomework.mainTopic = null;
        tmp.school.journalColumn.editHomework.statertLesson = null;
        tmp.school.journalColumn.editHomework.targetLesson = null;
        tmp.school.journalColumn.editHomework.lessonTopic = null;
        tmp.school.journalColumn.editHomework.deskription = null;
        stateUpdate(tmp);
      },
      getTopics() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Topic?partId=' +
          state.school.baseData.partNumber;

        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.journalColumn.partTopics = body?.Items;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      createCommonColumn: {
        mainTopic: null,
        lessonTopic: null,
        typeLesson: null,
        setTypeLesson: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.createCommonColumn.typeLesson = value;
          stateUpdate(tmp);
        },
        setMainTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.createCommonColumn.mainTopic = value;
          stateUpdate(tmp);
        },
        setLessonTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.createCommonColumn.lessonTopic = value;
          stateUpdate(tmp);
        },
        create() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Column/Create';
          let dataJS = JSON.stringify({
            AppointmentId: Number(state.school.baseData?.appointmentNumber),
            ColumnTypeId: Number(
              state.school.journalColumn?.createCommonColumn?.typeLesson,
            ),
            TopicId: Number(
              state.school.journalColumn?.createCommonColumn?.lessonTopic,
            ),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.school.journalColumn.clear();
              state.functions.changed.column.getTmpColumnSelectThemes(null);
              state.functions.changed.flags.popUpCreateColumnPicker(false);
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
            })
            .then(() => {
              state.school.journalTopics.getTopics();
            });
        },
      },
      createTopicColumn: {
        mainTopic: null,
        setMainTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.createTopicColumn.mainTopic = value;
          stateUpdate(tmp);
        },
        create() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/CloseTopic';
          let dataJS = JSON.stringify({
            AppointmentId: Number(state.school.baseData?.appointmentNumber),
            TopicId: Number(
              state.school.journalColumn?.createTopicColumn?.mainTopic,
            ),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.changed.column.getTmpColumnSelectThemes(null);
              state.functions.changed.column.getTmpColumnSelectCreateThemes(
                null,
              );
              state.functions.changed.flags.popUpCreateColumnPicker(false);
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
            });
        },
      },
      createSemestrColumn: {
        semestr: null,
        setSemestr: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.createSemestrColumn.semestr = value;
          stateUpdate(tmp);
        },
        create() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/CloseSemester';
          let dataJS = JSON.stringify({
            AppointmentId: Number(state.school.baseData?.appointmentNumber),
            AcademicSemester: Number(
              state.school.journalColumn?.createSemestrColumn?.semestr,
            ),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.changed.flags.popUpCreateColumnPicker(false);
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
            });
        },
      },
      updateColumn: {
        mainTopic: null,
        lessonTopic: null,
        typeLesson: null,
        setTypeLesson: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.updateColumn.typeLesson = value;
          stateUpdate(tmp);
        },
        setMainTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.updateColumn.mainTopic = value;
          tmp.school.journalColumn.updateColumn.lessonTopic = 0;
          stateUpdate(tmp);
        },
        setLessonTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.updateColumn.lessonTopic = value;
          stateUpdate(tmp);
        },
        setMainAndLessonTopic(value) {
          // eslint-disable-next-line no-unused-vars
          let tmp = null;
          if (state.school.journalColumn.partTopics !== null) {
            for (
              let i = 0;
              i < state.school.journalColumn?.partTopics?.length;
              i++
            ) {
              if (state.school.journalColumn.partTopics[i].Id === value) {
                let tmp = { ...state };
                tmp.school.journalColumn.updateColumn.lessonTopic =
                  state.school.journalColumn?.partTopics[i]?.Id;
                tmp.school.journalColumn.updateColumn.mainTopic =
                  state.school.journalColumn?.partTopics[i]?.ParentId;
                stateUpdate(tmp);
                break;
              }
            }
          }
        },
        updateTopic() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            state.school.baseData.columnPopUpData.Id +
            '/Update';
          let dataJS = JSON.stringify({
            TopicId: Number(
              state.school.journalColumn?.updateColumn?.lessonTopic,
            ),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then((body) => {
              state.functions.getConstData.getPartRates(
                state.school.baseData.PartId,
              );
              return body;
            });
        },
        updateUnBindTopic(data) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            data +
            '/Update';
          let dataJS = JSON.stringify({
            TopicId: Number(-1),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then((body) => {
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
              return body;
            })
            .then(() => {
              state.functions.get.column.dataTopic();
              state.school.journalTopics.getTopics();
              state.school.journalColumn.getTopics();
              state.school.journalTopics.setColumn(false, null);
            });
        },
        updateType() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            state.school.baseData.columnPopUpData.Id +
            '/Update';
          let dataJS = JSON.stringify({
            ColumnTypeId: Number(
              state.school.journalColumn?.updateColumn?.typeLesson,
            ),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
            });
        },
      },
      createHomework: {
        targetLesson: null,
        mainTopic: null,
        lessonTopic: null,
        deskription: null,
        setMainTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.createHomework.mainTopic = value;
          stateUpdate(tmp);
        },
        setTargetLesson: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.createHomework.targetLesson = value;
          stateUpdate(tmp);
        },
        setLessonTopic: (value) => {
          let temp = null;
          if (state.school.journalColumn.partTopics !== null) {
            temp = state.school.journalColumn?.partTopics?.filter((e) => {
              return e.Id === Number(value);
            });
          }
          let tmp = { ...state };
          tmp.school.journalColumn.createHomework.lessonTopic = value;
          tmp.school.journalColumn.createHomework.deskription =
            temp[0]?.Homework;
          stateUpdate(tmp);
        },
        setDeskription: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.createHomework.deskription = value;
          stateUpdate(tmp);
        },
        create() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Appointment/' +
            /*state.school.baseData.appointmentNumber*/ state.school.baseData
              .columnPopUpData?.AppointmentId +
            '/AssignHomework';
          let dataJS = JSON.stringify({
            TopicId: Number(
              state.school.journalColumn?.createHomework?.lessonTopic,
            ),
            TargetAppointmentId: Number(
              state.school.journalColumn?.createHomework?.targetLesson,
            ),
            Description:
              state.school.journalColumn?.createHomework?.deskription,
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.get.column.getHomework();
              state.school.journalTopics.getHomeworks(
                state.school.baseData?.PartId,
              );
            })
            .then(() => {
              let tmp = { ...state };
              tmp.school.popUpFlag.flagHomework = false;
              tmp.school.popUpFlag.homeworkPicker = false;
              stateUpdate(tmp);
              state.school.journalColumn.clear();
            });
        },
      },
      editHomework: {
        statertLesson: null,
        targetLesson: null,
        mainTopic: null,
        lessonTopic: null,
        deskription: null,
        setMainTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.editHomework.mainTopic = value;
          stateUpdate(tmp);
        },
        setTargetLesson: (data) => {
          //Тут сделать выбор нужного дз
          /*let tmpe = state.school.arr.homework.filter(
                        (item) => {
                            return item.Id === Number(data)
                        }
                    )*/
          let tr = null;
          let temp = state.school.journalTopics?.partHomework?.filter(
            (item) => {
              return (
                item.Id === state.school.baseData.columnPopUpData?.AppointmentId
              );
            },
          );

          tr = temp[0].Embed.Homeworks.filter((e) => {
            return e.Id === Number(data);
          });
          let tmp = { ...state };
          tmp.school.journalColumn.editHomework.statertLesson = data;
          tmp.school.journalColumn.editHomework.deskription =
            tr[0]?.Description;
          tmp.school.journalColumn.editHomework.lessonTopic = tr[0]?.TopicId;
          tmp.school.journalColumn.editHomework.targetLesson =
            tr[0]?.TargetAppointmentId;
          stateUpdate(tmp);
        },
        setLessonTopic: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.editHomework.lessonTopic = value;
          stateUpdate(tmp);
        },
        setDeskription: (value) => {
          let tmp = { ...state };
          tmp.school.journalColumn.editHomework.deskription = value;
          stateUpdate(tmp);
        },
        edit() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Homework/' +
            state.school.journalColumn.editHomework.statertLesson +
            '/Update';
          let dataJS = JSON.stringify({
            TopicId: Number(
              state.school.journalColumn?.editHomework?.lessonTopic,
            ),
            TargetAppointmentId: Number(
              state.school.journalColumn?.editHomework?.targetLesson,
            ),
            Description: state.school.journalColumn?.editHomework?.deskription,
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              let tmp = { ...state };
              tmp.school.popUpFlag.flagHomework = false;
              tmp.school.popUpFlag.homeworkPicker = false;
              stateUpdate(tmp);
              state.functions.get.column.getHomework();
              state.school.journalTopics.getHomeworks(
                state.school.baseData?.PartId,
              );
              state.school.journalColumn.clear();
            });
        },
        delete(appoint, value) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Appointment/' +
            appoint +
            '/RemoveHomework';
          let dataJS = JSON.stringify({
            HomeworkId: Number(value),
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartThemes(
                state.school.baseData?.PartId,
              );
            })
            .then(() => {
              state.functions.get.column.getHomework();
              state.school.journalTopics.getHomeworks(
                state.school.baseData?.PartId,
              );
            });
        },
      },
      popUpDeleteTable: false,
      popUpDeleteHW: false,
      delId: null,
      appointId: null,
      hwId: null,
      setDeleteId: (value) => {
        let tmp = { ...state };
        tmp.school.journalColumn.delId = value;
        tmp.school.journalColumn.popUpDeleteTable = true;
        stateUpdate(tmp);
      },
      setDeleteHW: (value, appointId = null, hwId = null) => {
        let tmp = { ...state };
        //tmp.school.journalColumn.delId = value;
        tmp.school.journalColumn.popUpDeleteHW = value;
        tmp.school.journalColumn.appointId = appointId;
        tmp.school.journalColumn.hwId = hwId;
        stateUpdate(tmp);
      },
      setPopUpDeleteTable: (value) => {
        let tmp = { ...state };
        tmp.school.journalColumn.popUpDeleteTable = value;
        stateUpdate(tmp);
      },
      journalUpdateColumn(columnId, TopicId) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/Column/' +
          columnId +
          '/Update';
        let dataJS = JSON.stringify({
          TopicId: Number(TopicId),
        });
        state.functions.getConstData.crud
          .post(tempUrl, dataJS)
          .then(
            (body) => {
              return body;
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then((body) => {
            state.functions.getConstData.getPartRates(
              state.school.baseData?.PartId,
            );
            return body;
          })
          .then(() => {
            state.school.journalTopics.getHomeworks(
              state.school.baseData?.partNumber,
            );
          });
        /////////
      },
      delete() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/Part/' +
          state.school.baseData.PartId +
          '/RemoveTopic';
        let dataJS = JSON.stringify({
          TopicId: Number(state.school.journalColumn?.delId),
        });
        state.functions.getConstData.crud
          .post(tempUrl, dataJS)
          .then(
            (body) => {
              return body;
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then(() => {
            state.school.journalTopics.getTopics();
            state.functions.get.column.dataTopic();
          });
      },
    },
    baseData: {
      TeacherId: null,
      teacherIdChoiceLesson: null,
      tiberUpdateSheduler: false,
      constTimerupdateSheduler: null,
      answerObjFlag: {},
      answerObj: null,
      answerData: null,
      schoolboyAnswerFS: null,
      videoSchoolboy: null,
      videoSchoolboyFlag: false,
      streamVideoStart: null,
      videoTopic: false,
      streamFilesView: 0,
      streamFiles: null,
      streamUrlSocket: {
        urlSocket: null,
        mineType: null,
        progress: null,
        playVideo: false,
      },
      streamPlayVideo: false,
      streamFilesFlag: false,
      socket: null,
      streamSocket: false,
      streamTimeSocket: null,
      fullAppointment: null,
      answer: null,
      answerBase: null,
      openHomework: false,
      buttonOpenHomework: (data) => {
        let tmp = { ...state };
        tmp.school.baseData.openHomework = data;
        stateUpdate(tmp);
      },
      changeAnswer: (data) => {
        let tmp = { ...state };
        tmp.school.baseData.answer = data;
        stateUpdate(tmp);
      },
      changeAnswerObj: () => {
        let tmp = { ...state };
        tmp.school.baseData.answerObj = null;
        stateUpdate(tmp);
      },
      changeAnswerBase: (data) => {
        let tmp = { ...state };
        tmp.school.baseData.answerBase = data;
        stateUpdate(tmp);
      },
      columnIdPrim: null,
      changeColumnIdPrim: (data) => {
        let tmp = { ...state };
        tmp.school.baseData.columnIdPrim = data;
        stateUpdate(tmp);
      },
      viewLogin: {
        property: {
          startScreen: 0,
        },
        methods: {
          changeStartScreen: (data) => {
            let tmp = { ...state };
            tmp.school.baseData.viewLogin.property.startScreen = data;
            stateUpdate(tmp);
          },
        },
      },
      schedule: {
        property: {
          arrAppointment: null,
        },
        function: {},
      },

      link: {
        all: '',
      },
      data: {
        tmpDateLessonColumn: null,
        monthNumber: null,
        monthNumberString: null,
        yearNumber: null,
        yearNumberString: null,
        studyPeriodNumber: null,
        semesterNumber: 1,
        quarterNumber: 1,
        thisDataStamp: null,
        popupColumnTopicTitle: null,
        popupColumnTopicDescription: null,
      },
      tokenWork: {
        token: null,
        refresh: null,
        exp: null,
        updateToken: false,
        function: {
          updateRefreshToken: () => {
            let tmp = { ...state };
            tmp.school.baseData.tokenWork.updateToken = true;
            stateUpdate(tmp, true);
          },
        },
      },
      teacher: {
        flag: false,
        AvatarUrl: null,
        Email: null,
        FirstName: null,
        Id: null,
        LastName: null,
        OwnClassId: null,
        Position: null,
        PositionId: null,
        SchoolId: null,
        SecondName: null,
        Subject: null,
        SubjectId: null,
      },
      // domen: 'https://api-test.eschool-ua.com', //Test
      // domenStream: 'https://api-test.eschool-ua.com', //Test стрим
      // domenQuiz: 'https://quiz22.eschool-ua.com/', //Test тестирование
      // domenFS: 'https://fs-test.eschool-ua.com', //Test файловое хранилище
      // domenChat: 'https://chat-test.eschool-ua.com', //Test чат
      // apikey: 'f417a941-bd7f-4b68-bf74-175375f94e9a', //ключ на тест

      domen: "https://api.eschool-ua.com", //Prod базовый журнал
      domenStream: "https://api.eschool-ua.com", //Prod стрим
      domenQuiz: "https://quiz.eschool-ua.com", //Prod тестирование
      domenFS: "https://fs.eschool-ua.com", //Prod файловое хранилище
      domenChat: "https://chat.eschool-ua.com", //Prod чат
      apikey: "6c5270bb-ae05-416d-ab7b-e9e8c372e22f", //"v. 1.04.05" // релиз

      stateTest: 'v. 1.04.62',

      rate: null,
      topicText: null,
      StreamName: null,
      StreamStatus: null,
      appointmentNumber: null,
      appointThemeTmp: null,
      createThemeTmp: null,
      createThemeTematicTmp: null,
      appointThemeTmpEdit: null,
      dataFlag: null,
      teacherId: null,
      academicYear: null,
      startAcademicYear: null,
      SupportUrl: null,
      endAcademicYear: null,
      startAcademicPeriod: null,
      endAcademicPeriod: null,
      minAcademicMonth: null,
      startFirstSemester: null,
      startSecondSemester: null,
      endFirstSemester: null,
      endSecondSemester: null,
      userFullName: null,
      schoolId: null,
      appointFlag: false,
      columnTypeId: null,
      popUpDataRate: null,
      topicNumber: null,
      popupColumnTopicType: null,
      ClassId: null,
      SubjectId: null,
      Subject: null,
      Part: null,
      Class: null,
      PartId: null,
      topicNumberHomework: 0,
      targetApointment: 0,
      targetApointmentForThemes: 0,
      targetApointmentForThemesBind: 0,
      topicNumberFirst: null,
      topicThemes: null,
      topicDescript: null,
      editTopicNumberFirst: null,
      editTopicThemes: null,
      editTopicDescript: null,
      editTopicNumber: null,
      editHomeWorkDescript: null,
      targetApointmentEditHomework: null,
      token: null,
      refresh: null,
      flagInstruction: false,
      typeJournal: 0,
      stateError: 0,
      choiseClassRemark: 0,
      choiseClassRemarkText: '',
      choiseClassRemarkTextOwn: '',
      homeworkCheckbox: false,
      countPart: 0,
      scrollTop: 0,
      scrollLeft: 0,
      scrollTopBlock: 0,
      scrollLeftBlock: 0,
      setMissingSchoolboyId: null,
      valueDate: null,
      columnPprimary: null,
      columnId: null,
      feedbackTopic: null,
      feedbackTopicBody: null,
      feedbackTopicHead: null,
      typeScheduleView: true,
      columntypeIdDell: null,
      columnPopUpData: null,
      numRate: null,
      dayTransfer: 0,
      updateAutoSumma: {
        AppointmentId: null,
        TopicId: null,
      },
      multiPartLesson: {
        Appointment: null,
        PartSchoolboys: [],
        PartThemes: [],
        PartRates: [],
        PartClosedThemes: [],
      },
      typeColumn: null,
      dataDescription: null,
      stateTypeJournal: 0,
      langTypeFlag: 0,
      role: null,
      setSelectionRange: {
        range: 0,
        flag: 0,
      },
      password: null,
      keyBoardMode: false,
      summary: null,
      themesFlag: false,
      schoolBoyId: null,
      UploadGood: null,
      createSemestr: false,
      createThemes: false,
      deleteTopic: false,
      messageFlag: false,
      tripJournal: false,
      tripJournalDate: '',
      tripJournalTopic: '',
      tripJournalBody: '',
      tellJournal: false,
      tellJournalDate: '',
      tellJournalTopic: '',
      tellJournalBody: '',
      targetAppointmentIdHomework: null,
      deleteTopicFromAppointment: false,
      AppointmentIdIdFromAppointment: null,
      TopicIdFromAppointment: null,
      BindTopicForAppointment: false,
      OwnAllClassRate: false,
      topicBindAppointment: false,
      streamTimer: false,
      constTimer: null,
      answerObjArr: [],
      answerHWObj: {},
      flagAnswerHWObj: {},
    },
    missing: {
      schoolboyFlag: false,
      popUpSchoolboyId: null,
      popUpData: null,
      tmpMissingArr: [],
      arrMissingSchoolboyDay: null,
      tmpMissingArrSec: [],
      postMiss: null,
    },
    arr: {
      allRates: null,
      lessonsAndTeachers: null,
      PartHW: [],
      data: [
        { monthNumber: 9, monthNumberString: '09', monthText: 'Вересень' },
        { monthNumber: 10, monthNumberString: '10', monthText: 'Жовтень' },
        { monthNumber: 11, monthNumberString: '11', monthText: 'Листопад' },
        { monthNumber: 12, monthNumberString: '12', monthText: 'Грудень' },
        { monthNumber: 1, monthNumberString: '01', monthText: 'Січень' },
        { monthNumber: 2, monthNumberString: '02', monthText: 'Лютий' },
        { monthNumber: 3, monthNumberString: '03', monthText: 'Березень' },
        { monthNumber: 4, monthNumberString: '04', monthText: 'Квітень' },
        { monthNumber: 5, monthNumberString: '05', monthText: 'Травень' },
        { monthNumber: 6, monthNumberString: '06', monthText: 'Червень' },
        { monthNumber: 7, monthNumberString: '07', monthText: 'Липень' },
        { monthNumber: 8, monthNumberString: '08', monthText: 'Серпень' },
      ],
      typeMissingArr: [],
      rateValueArr: [],
      rateAllValueArr: [],
      textMarkAllValueArr: [],
      typeColumnArr: [],
      ownClassMissing: [],
      ownClassSchoolboy: [],
      ownClassSubject: [],
      ownClassMissingSummary: [],
      appointment: [],
      wecend: [],
      parentComments: [],
      partSchoolboy: [],
      partThemes: [],
      partRates: [],
      tmpParent: null,
      lessonTopic: null,
      homework: null,
      TargetAppointment: null,
      homeworkViewDZ: null,
      homeworkViewDZTwo: null,
      appointTheme: null,
      homeworkFilesUpload: null,
      ownClassJournalRemark: null,
      allClass: null,
      ownClassPart: null,
      ownClassAllSubjectSchoolboy: null,
      ownClassAllSubjectSchoolboyRate: null,
      appointmentsForThemes: null,
      allClassAverRateFirstSem: null,
      allClassAverRateSecondSem: null,
      allClassAverRateYear: null,
      allMissedAppointmentSingeSchoolboy: null,
      ownClassSchedule: null,
      feedbackTopic: null,
      schoolboyIn: null,
      schoolboyOut: null,
      teacherReplace: null,
      ownClassMovement: null,
      arrClosedThemesAndSchoolboy: null,
      headTeacher: {
        headTeacherClassId: null,
        headTeacherJournalStates: null,
        headTeacherClasses: null,
        headTeacherClassesAllTeacher: null,
        headTeacherLesson: null,
        headTeacherPart: null,
        headTeacherSchoolboy: null,
        headTeacherArrPartLesson: null,
        headTeacherLessonId: null,
        headTeacherChousenSchoolboy: null,
        headTeacherChousenSchoolboyRates: null,
        headTeacherSchoolboyId: null,
      },
      feedbackArr: null,
    },
    popUpFlag: {
      ratePicker: false,
      missingPicker: false,
      parentPicker: false,
      partFlag: false,
      profileFlag: false,
      createColumnPicker: false,
      flagLessonTopic: false,
      homeworkPicker: false,
      flagHomework: false,
      flagTargetApointment: false,
      flagViewDZ: false,
      flagHomeworkViewDZ: false,
      createTopicPiker: false,
      flagBrowserMessage: false,
      appointThemeFlag: false,
      appointThemeTmp: null,
      error: false,
    },
    streamChat: {
      arrStreamChanel: null,
      arrStreamClasses: null,
      dataStreamChatChannel: null,
      dataStreamChatChannelPM: null,
      arrChatMessage: null,
      arrChatMessagePM: null,
      arrClassArrTeachers: null,
      arrAllChatChanel: null,
      classChatChanel: null,
      lastIdClassChatChanel: null,
      classChatAllMessage: null,
      classChatNewMessageLink: null,
      classChatNewMessage: null,
      privateChatChanel: null,
      lastIdPrivateChatChanel: null,
      privateChatAllMessage: null,
      privateChatNewMessageLink: null,
      privateChatNewMessage: null,
      socketClassNewMessage: null,
      socketPrivateNewMessage: null,
      todayRate: null,
      streamRate: null,
    },
  },
  functions: {
    getHomeworkSchoolboy(_url, flag) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Homework/' +
        _url +
        '/Answer?schoolboyId=' +
        state.school.baseData.teacherId +
        '&embed=File';

      state.functions.getFilesStream(tempUrl).then(
        (body) => {
          if (
            state.school.baseData.answerObjArr[_url] === undefined ||
            state.school.baseData.answerObjArr[_url] === 'undefined'
          ) {
            let tmp = { ...state };
            tmp.school.baseData.answerObjArr[_url] = body;
            stateUpdate(tmp);
          } else {
            if (state.school.baseData?.answerObjArr[_url]?.Items?.length > 0) {
              if (flag) {
                let tmp = { ...state };
                tmp.school.baseData.answerObjArr[_url] = body;
                stateUpdate(tmp);
              }
            } else {
              if (body?.Items?.length > 0) {
                let tmp = { ...state };
                tmp.school.baseData.answerObjArr[_url] = body;
                stateUpdate(tmp);
              }
            }
          }
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },
    getAnswerHomeworkSchoolboy(_url, appointId) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Homework/' +
        _url +
        '/Answer?schoolboyId=' +
        state.school.baseData.teacherId;

      return state.functions.getFilesStream(tempUrl).then(
        (body) => {
          //let tmp = { ...state };
          if (!body?.Items) {
            return;
          }
          if (body?.Items?.length === 0) {
            return;
          }
          if (!body?.Items[0]?.Answer) {
            return;
          } else {
            // tmp.school.baseData.answerHWObj[`${appointId}`] = true

            return true;
          }
          // stateUpdate(tmp);
          // let tmp = { ...state };
          // if (!tmp.school.baseData.answerHWObj[appointId]) {
          //     tmp.school.baseData.answerHWObj[appointId] = {}
          // }
          // if (body.Items.length === 0) {
          //     tmp.school.baseData.answerHWObj[appointId] = { ...tmp.school.baseData.answerHWObj[appointId], _url: false };
          // }
          // if (body.Items.length > 0 && body.Items[0].Answer) {
          //     tmp.school.baseData.answerHWObj[appointId] = { ...tmp.school.baseData.answerHWObj[appointId], _url: true };
          // }
          // if (body.Items.length > 0 && !body.Items[0].Answer) {
          //     tmp.school.baseData.answerHWObj[appointId] = { ...tmp.school.baseData.answerHWObj[appointId], _url: false };
          // }
          // stateUpdate(tmp);
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },
    checkedAllAnswerHW(appoint) {
      state.school.baseData.flagAnswerHWObj[appoint.Id] = true;
      stateUpdate(state);

      let arrFunction = appoint?.Embed?.Homeworks?.map((item, index) =>
        state.functions.getAnswerHomeworkSchoolboy(
          appoint?.Embed?.Homeworks[index]?.Id,
          appoint?.Id,
        ),
      );
      return Promise.all([...arrFunction]).then((body) => {
        return body;
      });
    },
    //new
    checkedAllAnswerHWNew(homeworkItem) {
      state.school.baseData.flagAnswerHWObj[homeworkItem.Id] = true;
      stateUpdate(state);

      let arrFunction = homeworkItem?.Embed?.Homeworks?.map((item, index) =>
        state.functions.getAnswerHomeworkSchoolboy(
          homeworkItem?.Embed?.Homeworks[index]?.Id,
          homeworkItem?.Id,
        ),
      );
      return Promise.all([...arrFunction]).then((body) => {
        return body;
      });
    },
    //
    setFlagCheckedAllAnswerHW(appointId, value) {
      let tmp = { ...state };
      tmp.school.baseData.flagAnswerHWObj[appointId] = value;
      stateUpdate(tmp);
    },
    updateRate() {
      let tmp = null;
      if (state.school.arr?.appointment !== null) {
        if (state.school.arr?.appointment.length > 0) {
          tmp = state.school.arr?.appointment?.filter((i) => {
            return i.Id === state.school.baseData?.appointmentNumber;
          });
        }
      }
      let tmpRate = null;
      if (tmp !== null) {
        if (tmp?.length > 0) {
          if (tmp[0]?.Embed?.Rates?.length > 0) {
            tmpRate = tmp[0]?.Embed?.Rates?.filter((i) => {
              return (
                i.SchoolboyId === Number(state.school?.baseData?.teacherId)
              );
            });
          }
        }
      }
      let tmpTitle = null;
      if (tmpRate !== null) {
        if (tmpRate?.length > 0) {
          if (state.school?.arr?.rateValueArr?.length > 0) {
            tmpTitle = state.school.arr?.rateValueArr?.filter((item) => {
              return item.Id === tmpRate[0]?.Rate;
            });
          }
        }
      }
      if (tmpTitle !== null) {
        if (tmpTitle?.length > 0) {
          if (state.school.baseData?.rate !== tmpTitle[0]?.Title) {
            let tmp = { ...state };
            tmp.school.baseData.rate = tmpTitle[0]?.Title;
            stateUpdate(tmp);
          }
        }
      }
    },
    changeStreamStatus(data) {
      let tmp = { ...state };
      tmp.school.baseData.StreamStatus = data;
      stateUpdate(tmp);
    },
    changeStreamVideoStart(data) {
      let tmp = { ...state };
      tmp.school.baseData.streamVideoStart = data;
      stateUpdate(tmp);
    },
    setSocket(data) {
      let tmp = { ...state };
      tmp.school.baseData.socket = data;
      stateUpdate(tmp);
    },
    startStreamSocket(data) {
      let tmp = { ...state };
      tmp.school.baseData.streamSocket = data;
      stateUpdate(tmp);
    },
    setStreamTimeSocket(data) {
      let tmp = { ...state };
      tmp.school.baseData.streamTimeSocket = data;
      stateUpdate(tmp);
    },
    // timerStream(data) {
    //     let timer = setInterval(() => {
    //         if (state.school.streamChat.dataStreamChatChannel !== null) {
    //             if (state.school.streamChat.dataStreamChatChannel.length > 0) {
    //                 state.functions.streamChat.getChatMessagesUnread(state.school.streamChat.dataStreamChatChannel[0].Links.Messages);
    //             }
    //         }
    //         if (state.school.streamChat.dataStreamChatChannelPM !== null) {
    //             if (state.school.streamChat.dataStreamChatChannelPM.length > 0) {
    //                 state.functions.streamChat.getChatMessagesUnreadPM(state.school.streamChat.dataStreamChatChannelPM[0].Links.Messages)
    //             }
    //         }
    //     }, 5000);
    //     let tmp = { ...state };
    //     tmp.school.baseData.streamTimer = true;
    //     tmp.school.baseData.constTimer = timer;
    //     stateUpdate(tmp);
    // },
    // timerStreamClose() {
    //     let tmp = { ...state };
    //     tmp.school.baseData.streamTimer = false;
    //     clearInterval(tmp.school.baseData.constTimer)
    //     tmp.school.baseData.constTimer = null;
    //     stateUpdate(tmp);
    // },
    streamChat: {
      //старый запрос на чаты. не нужен
      getStream() {
        let tempUrl = state.school.baseData.domenChat + '/profile';
        state.functions.getFilesStream(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.streamChat.arrStreamChanel = body;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      // новый запрос получения всех каналов чата
      getAllChatChanel() {
        let tempUrl =
          state.school.baseData.domenChat +
          '/profile?embed=Subscribers,UnreadMessages&keepLastId=true';
        return state.functions.getFilesStream(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.streamChat.arrAllChatChanel = body?.Topics;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getTeacherClasses() {
        return state.functions.getConstData.crud
          .get(
            state.school.baseData.domen +
              '/v1/' +
              state.school.baseData.schoolId +
              '/Class/' +
              state.school.baseData.ClassId,
          )
          .then(
            (body) => {
              let tmp = { ...state };
              tmp.school.streamChat.arrStreamClasses = body;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
      },
      getClassChatChanel() {
        let tmp = { ...state };
        tmp.school.streamChat.classChatChanel = state.school.streamChat?.arrAllChatChanel?.find(
          (item) =>
            item.Id.includes(state.school.streamChat.arrStreamClasses.GroupUID),
        );
        if (tmp.school.streamChat?.classChatChanel) {
          tmp.school.streamChat.lastIdClassChatChanel =
            tmp.school.streamChat?.classChatChanel?.LastId;
          tmp.school.streamChat.classChatNewMessageLink =
            tmp.school.streamChat?.classChatChanel?.Links?.UnreadMessages;
        }
        stateUpdate(tmp);
      },
      getPrivateChatChanel() {
        let tmp = { ...state };
        let privateChatChanel = state.school.streamChat?.arrAllChatChanel?.find(
          (item) => item.Type === 'private',
        );
        if (privateChatChanel) {
          tmp.school.streamChat.privateChatChanel = privateChatChanel;
          tmp.school.streamChat.lastIdPrivateChatChanel =
            privateChatChanel?.LastId;
          tmp.school.streamChat.privateChatNewMessageLink =
            privateChatChanel?.Links?.UnreadMessages;
        }
        stateUpdate(tmp);
      },
      getClassChatAllMessage() {
        if (state.school.streamChat?.classChatChanel) {
          let tempUrl =
            state.school.streamChat?.classChatChanel?.Links?.Messages;
          return state.functions.getConstData.crud.get(tempUrl).then(
            (body) => {
              let tmp = { ...state };
              tmp.school.streamChat.classChatAllMessage = body;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        }
      },
      getPrivateChatAllMessage() {
        if (state.school.streamChat?.privateChatChanel) {
          let tempUrl =
            state.school.streamChat?.privateChatChanel?.Links?.Messages;
          return state.functions.getConstData.crud.get(tempUrl).then(
            (body) => {
              state.school.streamChat.privateChatAllMessage = body;
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        } else {
          return state.functions.streamChat.createPmChat(
            state.school.baseData?.teacherIdChoiceLesson,
          );
          // .then(() => {
          //     let tempUrl = state.school.streamChat.privateChatChanel.Links.Messages
          //     return state.functions.getConstData.crud.get(tempUrl)
          //         .then(
          //             (body) => {
          //                 state.school.streamChat.privateChatAllMessage = body;
          //             },
          //             (err) => {
          //                 if (err.type === "error") {
          //                 } else if (err === 404 || err === 403) {
          //                 }
          //             }
          //         )
          // })
        }
      },
      createPmChat(id) {
        let url = state.school.baseData.domenChat + '/invite';
        let dataJS = JSON.stringify({ UserId: Number(id) });
        return state.functions.getConstData.crud.post(url, dataJS).then(
          (body) => {
            state.functions.streamChat.getAllChatChanel().then(() => {
              state.functions.streamChat.getPrivateChatChanel();
              let tempUrl =
                state.school.streamChat?.privateChatChanel?.Links?.Messages;
              return state.functions.getConstData.crud.get(tempUrl).then(
                (body) => {
                  state.school.streamChat.privateChatAllMessage = body;
                },
                (err) => {
                  if (err.type === 'error') {
                  } else if (err === 404 || err === 403) {
                  }
                },
              );
            });
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getClassChatNewMessage() {
        if (state.school.streamChat?.classChatChanel) {
          let tempUrl = state.school.streamChat?.classChatNewMessageLink;
          return state.functions.getConstData.crud.get(tempUrl).then(
            (body) => {
              let tmp = { ...state };
              tmp.school.streamChat.classChatNewMessage = body;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        }
      },
      getPrivateChatNewMessage() {
        if (state.school.streamChat?.privateChatChanel) {
          let tempUrl = state.school.streamChat?.privateChatNewMessageLink;
          return state.functions.getConstData.crud.get(tempUrl).then(
            (body) => {
              let tmp = { ...state };
              tmp.school.streamChat.privateChatNewMessage = body;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        }
      },
      setClassNewMessage(data) {
        let tmp = { ...state };
        tmp.school.streamChat.socketClassNewMessage = data;
        stateUpdate(tmp);
      },
      setPrivateNewMessage(data) {
        let tmp = { ...state };
        tmp.school.streamChat.socketPrivateNewMessage = data;
        stateUpdate(tmp);
      },

      takeChatChannel() {
        if (state.school.streamChat?.arrStreamChanel !== null) {
          state.school.streamChat.dataStreamChatChannel = state.school.streamChat?.arrStreamChanel?.Topics?.filter(
            (i) => {
              return i.Id.indexOf('SB_CLASS_') !== -1;
            },
          );
        }
      },
      getChatMessagesUnread(value) {
        state.functions.getConstData.crud.get(value).then(
          (body) => {
            let tmp = { ...state };
            if (body === null) {
              tmp.school.streamChat.arrChatMessage = [];
            } else {
              tmp.school.streamChat.arrChatMessage = body;
            }
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      postMessageToChat(url, text) {
        let dataJS = JSON.stringify({ Body: text });
        return state.functions.getConstData.crud
          .post(url, dataJS)
          .then(
            (body) => {},
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then(() => {
            // document.getElementById('message_all').value = '';
          });
      },
      takePMChatChannel() {
        if (state.school.streamChat?.arrStreamChanel !== null) {
          state.school.streamChat.dataStreamChatChannelPM = state.school.streamChat?.arrStreamChanel?.Topics?.filter(
            (i) => {
              let tmpTeacher = state.school.baseData.TeacherId + '_';
              let tmp = i?.Id?.split(tmpTeacher);
              return tmp.length === 2;
            },
          ).filter((i) => {
            let tmpTeacher = state.school.baseData?.TeacherId + '_';
            let tmp = i?.Id?.split(tmpTeacher);
            return tmp[1] === state.school.baseData?.teacherId;
          });
        }
      },
      getChatMessagesUnreadPM(value) {
        state.functions.getConstData.crud.get(value).then(
          (body) => {
            let tmp = { ...state };
            //tmp.school.streamChat.arrChatMessage = '';
            if (body === null) {
              tmp.school.streamChat.arrChatMessagePM = [];
            } else {
              tmp.school.streamChat.arrChatMessagePM = body;
            }
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      postMessageToChatPM(url, text) {
        let dataJS = JSON.stringify({
          Body: text,
        });
        state.functions.getConstData.crud
          .post(url, dataJS)
          .then(
            (body) => {},
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then(() => {
            document.getElementById('message_pm').value = '';
          });
      },
      getStreamRate() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/SchoolBoy/' +
          state.school.baseData.teacherId +
          '/Appointment/' +
          state.school.baseData.appointmentNumber +
          '/Rate';
        return state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            if (body?.RateValue) {
              tmp.school.streamChat.streamRate = body?.RateValue;
            } else {
              tmp.school.streamChat.streamRate = body?.Rate;
            }
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      setTodayRate(value) {
        let tmp = { ...state };
        tmp.school.streamChat.todayRate = value;
        stateUpdate(tmp);
      },
    },

    getHomeworkId(_url) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Appointment/' +
        _url +
        '/Homework';
      state.functions.getConstData.crud.get(tempUrl).then(
        (body) => {
          if (body?.Items?.length > 0) {
            state.functions.filesSchoolboyAnswerFS(body?.Items[0]?.Id);
          } else {
            let tmp = { ...state };
            tmp.school.baseData.answerObj = null;
            stateUpdate(tmp);
          }
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },
    getFilesStream(_url) {
      return new Promise(function (resolve, reject) {
        let request = new XMLHttpRequest();
        let jsonData;
        request.open(`GET`, _url);
        request.setRequestHeader('X-Auth-Token', state.school.baseData.token);
        request.setRequestHeader('Api-key', state.school.baseData.apikey);
        request.setRequestHeader('Content-type', 'multipart/form-data');
        request.onload = function () {
          if (request.status === 200 && request.readyState === 4) {
            jsonData = JSON.parse(request.responseText);
            resolve(jsonData);
          } else {
            reject(request.status);
          }
        };
        request.onerror = function (error) {
          reject(error);
        };
        request.send();
      });
    },
    sliderStream(value) {
      let tmp = { ...state };
      tmp.school.baseData.streamFilesView = value;
      stateUpdate(tmp);
    },
    setStreamUrlSocket(data) {
      state.school.baseData.streamUrlSocket = data;
    },
    setPlayVideoSocket(data) {
      state.school.baseData.streamPlayVideo = data;
    },
    clearFlagStream() {
      let tmp = { ...state };
      tmp.school.baseData.streamFiles = null;
      tmp.school.baseData.streamFilesFlag = false;
      stateUpdate(tmp);
    },
    timerScheduleUpdate() {
      let timer = setInterval(
        () => state.functions.data.updataSchedulerPing(),
        60000000,
      );
      let tmp = { ...state };
      tmp.school.baseData.tiberUpdateSheduler = true;
      tmp.school.baseData.constTimerupdateSheduler = timer;
      stateUpdate(tmp);
    },
    timerScheduleUpdateClose() {
      clearInterval(state.school.baseData.constTimerupdateSheduler);
      let tmp = { ...state };
      tmp.school.baseData.tiberUpdateSheduler = false;
      tmp.school.baseData.constTimerupdateSheduler = null;
      stateUpdate(tmp);
    },

    getAnswer(_url) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Homework/' +
        _url +
        '?embed=Answer';
      state.functions.getConstData.crud.get(tempUrl).then(
        (body) => {
          let temp = { ...state };
          temp.school.baseData.answerData = body;
          stateUpdate(temp);
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },

    filesStream(_url) {
      let tempUrl = state.school.baseData.domenFS + '/stream/' + _url;
      state.functions.getFilesStream(tempUrl).then(
        (body) => {
          let tmp = { ...state };
          tmp.school.baseData.streamFiles = body;
          tmp.school.baseData.streamFilesFlag = true;
          stateUpdate(tmp);
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },
    answerSchoolboy(_url, TopicId = null) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Column/' +
        state.school.baseData.columnIdPrim.Id +
        '/Answer';
      let dataJS = JSON.stringify({
        SchoolBoyId: Number(state.school.baseData?.teacherId),
        AppointmentId: state.school.baseData?.appointmentNumber,
        Answer: '',
        TopicId: TopicId,
      });
      return state.functions.getConstData.crud
        .post(tempUrl, dataJS)
        .then(
          (body) => {
            // state.school.baseData.changeAnswer(body.Answer);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then(() => {
          // state.functions.assignAnswerFS(_url)
        });
    },

    assignAnswerFS(_url) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Homework/' +
        _url +
        '/AssignAnswer';
      let dataJS = JSON.stringify({
        Answer: '',
        Files: [],
      });
      return state.functions.getConstData.crud.post(tempUrl, dataJS).then(
        (body) => {
          state.profile.functions.setIsLoadingSpinner(false);
          return body;
        },
        (err) => {
          if (err.type === 'error') {
            state.profile.functions.setIsLoadingSpinner(false);
          } else if (err === 404 || err === 403) {
            state.profile.functions.setIsLoadingSpinner(false);
          } else state.profile.functions.setIsLoadingSpinner(false);
        },
      );
    },
    updateAnswerFS(_url, fileId, answerId, answerDescrip) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Homework/' +
        _url +
        '/UpdateAnswer';
      let dataJS = JSON.stringify({
        // Id: state.school.baseData.answerObj.Items[0].Id,
        // Answer: state.school.baseData.answer,
        Id: answerId,
        Answer: answerDescrip,
        Files: [{ Id: fileId }],
      });
      state.functions.getConstData.crud
        .post(tempUrl, dataJS)
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then(() => {
          state.functions.filesSchoolboyAnswerFS(_url);
        });
    },

    filesSchoolboyAnswerFS(_url) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Homework/' +
        _url +
        '/Answer?schoolboyId=' +
        state.school.baseData.teacherId +
        '&embed=File';
      state.functions
        .getFilesStream(tempUrl)
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.baseData.answerObj = body;
            stateUpdate(tmp);
            state.profile.functions.setIsLoadingSpinner(false);
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then((body) => {
          if (body?.Items?.length > 0) {
            state.school.baseData.changeAnswer(body?.Items[0]?.Answer);
            state.school.baseData.changeAnswerBase(body?.Items[0]?.Answer);
          } else {
            state.school.baseData.changeAnswer(null);
            state.school.baseData.changeAnswerBase(null);
          }
        });
    },

    getSchoolboyAnswer(_url) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Homework/' +
        _url +
        '/Answer?schoolboyId=' +
        state.school.baseData.teacherId +
        '&embed=File';
      return state.functions.getFilesStream(tempUrl).then(
        (body) => {
          let tmp = { ...state };
          //флаг, чтобы остановить многократный вызов функций
          tmp.school.baseData.answerObjFlag[_url] = true;
          stateUpdate(tmp);
          state.profile.functions.setIsLoadingSpinner(false);
          return body;
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },

    dropAswerObjFlag() {
      let tmp = { ...state };
      tmp.school.baseData.answerObjFlag = {};
      stateUpdate(tmp);
    },

    ////////////////////////////////////
    getFullAppoint() {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Part/' +
        state.school.baseData.partNumber +
        '/Appointment?start=' +
        state.school.baseData.startAcademicPeriod +
        '&end=' +
        state.school.baseData.endAcademicPeriod;
      state.functions.getConstData.crud.get(tempUrl).then(
        (body) => {
          let temp = { ...state };
          temp.school.baseData.fullAppointment = body?.Items;
          stateUpdate(temp);
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },

    downloadFiles(path, filename, mimetype) {
      state.functions.getConstData.crud
        .getNotJSON(state.school.baseData.domen + '/v1/' + path)
        .then(
          (body) => {
            let pom = document.createElement('a');
            pom.setAttribute(
              'href',
              'data:' + mimetype + ';charset=utf-8,' + encodeURIComponent(body),
            );
            pom.setAttribute('download', filename);

            if (document.createEvent) {
              let event = document.createEvent('MouseEvents');
              event.initEvent('click', true, true);
              pom.dispatchEvent(event);
            } else {
              pom.click();
            }
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    setYear(value) {
      let tmp = { ...state };
      tmp.school.baseData.data.yearNumber = Number(value);
      tmp.school.baseData.data.yearNumberString = value;
      stateUpdate(tmp);
    },
    setTopicBindAppointment(value) {
      let tmp = { ...state };
      tmp.school.baseData.topicBindAppointment = value;
      stateUpdate(tmp);
    },
    setOwnClassFlag(value) {
      let tmp = { ...state };
      tmp.school.baseData.OwnAllClassRate = value;
      if (value === false) {
        tmp.school.arr.headTeacher.headTeacherArrPartLesson = null;
        tmp.school.arr.partSchoolboy = [];
        tmp.school.arr.partRates = [];
      }
      stateUpdate(tmp);
    },
    setNullHeadPartRates() {
      let tmp = { ...state };
      tmp.school.arr.partRates = [];
      stateUpdate(tmp);
    },
    getTopicFromAppointment(appointmentId) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.teacher.SchoolId +
        '/Appointment/' +
        appointmentId +
        '&embed=Topic';
      state.functions.getConstData.crud.get(tempUrl).then(
        (body) => {
          return body;
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },
    deleteTopicFromAppointment(appointmentId, topicId) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.teacher.SchoolId +
        '/Appointment/' +
        appointmentId +
        '/RemoveTopic';
      let dataJS = JSON.stringify({
        TopicId: Number(topicId),
      });
      state.functions.getConstData.crud
        .post(tempUrl, dataJS)
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then(() => {
          state.functions.getConstData.getPartThemes(
            state.school.baseData?.PartId,
          );
        });
    },
    journalDeleteTopicFromAppointment: (value) => {
      let tmp = { ...state };
      tmp.school.baseData.deleteTopicFromAppointment = value;
      stateUpdate(tmp);
    },
    journalDeleteDataTopicFromAppointment: (
      AppointmentIdIdFromAppointment,
      TopicIdFromAppointment,
    ) => {
      let tmp = { ...state };
      tmp.school.baseData.AppointmentIdIdFromAppointment = AppointmentIdIdFromAppointment;
      tmp.school.baseData.TopicIdFromAppointment = TopicIdFromAppointment;
      stateUpdate(tmp);
    },
    journalTellDate: (value) => {
      let tmp = { ...state };
      tmp.school.baseData.tellJournalDate = value;
      stateUpdate(tmp);
    },
    journalTellTopic: (value) => {
      let tmp = { ...state };
      tmp.school.baseData.tellJournalTopic = value;
      stateUpdate(tmp);
    },
    journalTellBody: (value) => {
      let tmp = { ...state };
      tmp.school.baseData.tellJournalBody = value;
      stateUpdate(tmp);
    },
    journalTellClodePopUp: () => {
      let tmp = { ...state };
      tmp.school.baseData.tellJournalDate = '';
      tmp.school.baseData.tellJournalTopic = '';
      tmp.school.baseData.tellJournalBody = '';
      tmp.school.baseData.tellJournal = false;
      stateUpdate(tmp);
    },
    journalTellPopUp: () => {
      let tmp = { ...state };
      tmp.school.baseData.tellJournal = true;
      stateUpdate(tmp);
    },
    journalTripDate: (value) => {
      let tmp = { ...state };
      tmp.school.baseData.tripJournalDate = value;
      stateUpdate(tmp);
    },
    journalTripTopic: (value) => {
      let tmp = { ...state };
      tmp.school.baseData.tripJournalTopic = value;
      stateUpdate(tmp);
    },
    journalTripBody: (value) => {
      let tmp = { ...state };
      tmp.school.baseData.tripJournalBody = value;
      stateUpdate(tmp);
    },
    journalTripClodePopUp: () => {
      let tmp = { ...state };
      tmp.school.baseData.tripJournalDate = '';
      tmp.school.baseData.tripJournalTopic = '';
      tmp.school.baseData.tripJournalBody = '';
      tmp.school.baseData.tripJournal = false;
      stateUpdate(tmp);
    },
    data: {
      dayTransfer: (data) => {
        let tmp = { ...state };
        tmp.school.baseData.dayTransfer = data;
        stateUpdate(tmp);
        state.functions.data.detectStart();
      },
      daysInMonth: (month, year) => {
        return new Date(year, month, 0).getDate();
      },
      updateMonth: (monthNumber, monthString) => {
        let tmp = { ...state };
        tmp.school.baseData.data.monthNumber = monthNumber;
        tmp.school.baseData.data.monthNumberString = monthString;
        stateUpdate(tmp);
      },
      detectWecend: () => {
        let tmp = { ...state };
        let temp = new Date().getTime();
        let tempIfWeek = new Date(temp).getDay();
        /*if(tempIfWeek === 6){
                    tmp.school.baseData.data.thisDataStamp = new Date().getTime() - ((3600 * 24 * 1000) * 1);
                }else */ if (
          tempIfWeek === 0
        ) {
          tmp.school.baseData.data.thisDataStamp =
            new Date().getTime() - 3600 * 24 * 1000 * 2;
        } else {
          tmp.school.baseData.data.thisDataStamp = new Date().getTime();
        }
        stateUpdate(tmp);
      },
      detectStart: () => {
        let tempIfWeek = new Date(
          state.school.baseData?.data?.thisDataStamp,
        ).getDay();

        //if(tempIfWeek === 6) tempIfWeek = 5;
        if (tempIfWeek === 0) tempIfWeek = 5;

        let dayTmp = 3600 * 24 * 1000;
        let tmp = { ...state };

        if (tempIfWeek === 1) {
          tmp.school.arr.wecend = state.functions.data.createTmpArrayDate(
            dayTmp,
            0 + 7 * state.school.baseData?.dayTransfer,
          );
        } else if (tempIfWeek === 2) {
          tmp.school.arr.wecend = state.functions.data.createTmpArrayDate(
            dayTmp,
            -1 + 7 * state.school.baseData?.dayTransfer,
          );
        } else if (tempIfWeek === 3) {
          tmp.school.arr.wecend = state.functions.data.createTmpArrayDate(
            dayTmp,
            -2 + 7 * state.school.baseData?.dayTransfer,
          );
        } else if (tempIfWeek === 4) {
          tmp.school.arr.wecend = state.functions.data.createTmpArrayDate(
            dayTmp,
            -3 + 7 * state.school.baseData?.dayTransfer,
          );
        } else if (tempIfWeek === 5) {
          tmp.school.arr.wecend = state.functions.data.createTmpArrayDate(
            dayTmp,
            -4 + 7 * state.school.baseData?.dayTransfer,
          );
        } else if (tempIfWeek === 6) {
          //Врезка Субботы
          tmp.school.arr.wecend = state.functions.data.createTmpArrayDate(
            dayTmp,
            -5 + 7 * state.school.baseData?.dayTransfer,
          );
        }
        stateUpdate(tmp);
      },
      createTmpArrayDate: (dayTmp, count) => {
        let tmp = [];
        for (let i = 0; i < 6; i++) {
          tmp[i] = {
            day: new Date(
              state.school.baseData.data.thisDataStamp + dayTmp * count,
            ).getDate(),
            month:
              new Date(
                state.school.baseData.data.thisDataStamp + dayTmp * count,
              ).getMonth() + 1,
            year: new Date(
              state.school.baseData.data.thisDataStamp + dayTmp * count,
            ).getFullYear(),
          };
          count++;
        }
        return tmp;
      },
      updataScheduler: () => {
        //state.functions.getConstData.getTeacherApointment(state.school.arr.wecend[0].year, createStringData(state.school.arr.wecend[0].month), createStringData(state.school.arr.wecend[0].day), state.school.arr.wecend[4].year, createStringData(state.school.arr.wecend[4].month), createStringData(state.school.arr.wecend[4].day));
        state.functions.getConstData.getSchoolboySchedule(
          state.school.arr.wecend[0]?.year,
          createStringData(state.school.arr.wecend[0]?.month),
          createStringData(state.school.arr.wecend[0]?.day),
          state.school.arr.wecend[5]?.year,
          createStringData(state.school.arr.wecend[5]?.month),
          createStringData(state.school.arr.wecend[5]?.day),
        );
      },
      updataSchedulerPing: () => {
        //state.functions.getConstData.getTeacherApointment(state.school.arr.wecend[0].year, createStringData(state.school.arr.wecend[0].month), createStringData(state.school.arr.wecend[0].day), state.school.arr.wecend[4].year, createStringData(state.school.arr.wecend[4].month), createStringData(state.school.arr.wecend[4].day));
        state.functions.getConstData.getSchoolboySchedulePing(
          state.school.arr.wecend[0]?.year,
          createStringData(state.school.arr.wecend[0]?.month),
          createStringData(state.school.arr.wecend[0]?.day),
          state.school.arr.wecend[5]?.year,
          createStringData(state.school.arr.wecend[5]?.month),
          createStringData(state.school.arr.wecend[5]?.day),
        );
      },
    },
    createSemestr(value) {
      let tmp = { ...state };
      tmp.school.baseData.createSemestr = value;
      stateUpdate(tmp);
    },
    createThemes(value) {
      let tmp = { ...state };
      tmp.school.baseData.createThemes = value;
      stateUpdate(tmp);
    },
    deleteTopic(value) {
      let tmp = { ...state };
      tmp.school.baseData.deleteTopic = value;
      stateUpdate(tmp);
    },
    tripJournal(value) {
      let tmp = { ...state };
      tmp.school.baseData.tripJournal = value;
      stateUpdate(tmp);
    },
    setUploadGood(value) {
      let tmp = { ...state };
      tmp.school.baseData.UploadGood = value;
      stateUpdate(tmp);
    },
    setSchoolBoyId(value) {
      let tmp = { ...state };
      tmp.school.baseData.schoolBoyId === value
        ? (tmp.school.baseData.schoolBoyId = null)
        : (tmp.school.baseData.schoolBoyId = value);
      stateUpdate(tmp);
    },
    setThemesFlag() {
      let tmp = { ...state };
      tmp.school.baseData.themesFlag === false
        ? (tmp.school.baseData.themesFlag = true)
        : (tmp.school.baseData.themesFlag = false);
      stateUpdate(tmp);
    },
    setFlagOnOffThemes(value) {
      let tmp = { ...state };
      tmp.school.baseData.summary = value;
      stateUpdate(tmp);
    },
    setPassword(vPass) {
      let tmp = { ...state };
      tmp.school.baseData.password = vPass;
      stateUpdate(tmp);
    },
    setSelectionRange(value, flag) {
      let tmp = { ...state };
      tmp.school.baseData.setSelectionRange.range = value;
      tmp.school.baseData.setSelectionRange.flag = flag;
      stateUpdate(tmp);
    },
    stateTypeLang(value) {
      let tmp = { ...state };
      tmp.school.baseData.langTypeFlag = value;
      stateUpdate(tmp);
    },
    stateTypeJournal(value) {
      let tmp = { ...state };
      tmp.school.baseData.stateTypeJournal = value;
      stateUpdate(tmp);
    },
    dataDescription(value) {
      let tmp = { ...state };
      tmp.school.baseData.popUpDataRate.description = value;
      stateUpdate(tmp);
    },
    srtTypeColumn(value) {
      let tmp = { ...state };
      tmp.school.baseData.typeColumn = value;
      stateUpdate(tmp);
    },
    setAutoUpdate(appointmentId, topicId) {
      let tmp = { ...state };
      tmp.school.baseData.update.AppointmentId = appointmentId;
      tmp.school.baseData.update.TopicId = topicId;
      stateUpdate(tmp);
    },
    setArrClosedThemesAndSchoolboy(value) {
      let tmp = { ...state };
      tmp.school.arr.arrClosedThemesAndSchoolboy = value;
      stateUpdate(tmp);
    },
    setTypeScheduleView(data) {
      let tmp = { ...state };
      tmp.school.baseData.typeScheduleView = data;
      stateUpdate(tmp);
    },
    settypeLang(data) {
      let tmp = { ...state };
      tmp.school.baseData.langType = data;
      stateUpdate(tmp);
    },
    setColumnChoise(columnPprimary, columnId, columnTypeId, columnPopUpData) {
      let tmp = { ...state };
      tmp.school.baseData.columnPprimary = columnPprimary;
      tmp.school.baseData.columnId = columnId;
      tmp.school.baseData.columntypeIdDell = columnTypeId;
      tmp.school.baseData.columnPopUpData = columnPopUpData;
      stateUpdate(tmp);
    },
    setNumRates(value) {
      let tmp = { ...state };
      tmp.school.baseData.numRate = value;
      stateUpdate(tmp);
    },
    setTypeColumn(value) {
      let tmp = { ...state };
      tmp.school.baseData.columnPopUpData.ColumnTypeId = value;
      stateUpdate(tmp);
    },
    setThemeColumn(value) {
      let tmp = { ...state };
      tmp.school.baseData.columnPopUpData.TopicId = value;
      stateUpdate(tmp);
    },
    postUpdateColumnType() {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.teacher.SchoolId +
        '/Column/' +
        state.school.baseData.columnPopUpData.Id +
        '/Update';
      let dataJS = JSON.stringify({
        ColumnTypeId: Number(
          state.school.baseData?.columnPopUpData?.ColumnTypeId,
        ),
      });
      state.functions.getConstData.crud
        .post(tempUrl, dataJS)
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then(() => {
          state.functions.getConstData.getPartRates(
            state.school.baseData?.PartId,
          );
        })
        .then(() => {
          state.functions.getConstData.getPartThemes(
            state.school.baseData?.PartId,
          );
        });
    },
    postUpdateColumnThemes() {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.teacher.SchoolId +
        '/Column/' +
        state.school.baseData.columnPopUpData.Id +
        '/Update';
      let dataJS = JSON.stringify({
        TopicId: Number(state.school.baseData?.columnPopUpData?.TopicId),
      });
      state.functions.getConstData.crud
        .post(tempUrl, dataJS)
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then((body) => {
          state.functions.getConstData.getPartRates(
            state.school.baseData?.PartId,
          );
          return body;
        })
        .then((body) => {
          //Тут надо сделать проверку и если такой топик уже привязан то не привязывать
          if (state.school.arr.partThemes?.length !== 0) {
            let count = 0;
            for (let i = 0; i < state.school.arr.partThemes?.length; i++) {
              if (state.school.arr.partThemes[i].Id === body?.TopicId) {
                count = 1;
              }
            }
            if (count !== 1) {
              state.functions.post.themes.topicAddAppointmentFromColumn(
                state.school.baseData.targetAppointmentIdHomework,
                body?.TopicId,
              );
            }
          } else {
            state.functions.post.themes.topicAddAppointmentFromColumn(
              state.school.baseData.targetAppointmentIdHomework,
              body?.TopicId,
            );
          }
        });
    },
    switchTargetAppointmentEditHomework(data) {
      let tmpe = state.school.arr.homework?.filter((item) => {
        return item.Id === Number(data);
      });
      let tmp = { ...state };
      tmp.school.baseData.targetApointmentEditHomework = data;
      tmp.school.baseData.editHomeWorkDescript = tmpe[0]?.Description;
      tmp.school.baseData.topicNumberHomework = tmpe[0]?.TopicId;
      tmp.school.baseData.targetApointmentId = tmpe[0]?.TargetAppointmentId;
      stateUpdate(tmp);
    },
    setMissingSchoolboyId(valueSchoolboyId, valueDate) {
      let tmp = { ...state };
      tmp.school.baseData.setMissingSchoolboyId = valueSchoolboyId;
      tmp.school.baseData.setMissingDate = valueDate;
      stateUpdate(tmp);
    },
    getSchoolboyMoving() {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Class/' +
            state.school.baseData.teacher.OwnClassId +
            '/Movement',
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.ownClassMovement = body?.Items;
            stateUpdate(tmp);
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    getTeacherReplace() {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Replace?start=' +
            state.school.baseData.startAcademicPeriod +
            '&end=' +
            state.school.baseData.endAcademicPeriod,
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.teacherReplace = body?.Items;
            stateUpdate(tmp);
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    getSchoolboyIn() {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/In',
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.schoolboyIn = body?.Items;
            stateUpdate(tmp);
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    getSchoolboyOut() {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Out',
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.schoolboyOut = body?.Items;
            stateUpdate(tmp);
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    /**
     * Получаем классы школы
     * под заучем
     */
    getHeadTeacherClasses() {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Class',
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.headTeacher.headTeacherClasses = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    getHeadTeacherSaveSchoolboyId(value) {
      let tmp = { ...state };
      tmp.school.arr.headTeacher.headTeacherSchoolboyId = value;
      stateUpdate(tmp);
    },
    getHeadTeacherChousenSchoolboyRates(value) {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/SchoolBoy/' +
            value +
            '/Rate',
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.headTeacher.headTeacherChousenSchoolboyRates =
              body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    getHeadTeacherClassesAllTeachers(value) {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Class/' +
            value +
            '/Teacher',
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.headTeacher.headTeacherClassesAllTeacher =
              body?.Items;
            tmp.school.print.journalRateAndTopic.listTeachers = true;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    setHeadTeacherClassId(idClass, states, text, idLesson) {
      let tmp = { ...state };
      tmp.school.arr.headTeacher.headTeacherClassId = idClass;
      tmp.school.arr.headTeacher.headTeacherJournalStates = states;
      tmp.school.arr.headTeacher.headTeacherLessonId = idLesson;
      tmp.school.arr.headTeacher.headTeacherLessonText = text;
      stateUpdate(tmp);
    },
    setTeacherMoveBack(data) {
      let tmp = { ...state };
      tmp.school.baseData.typeJournal = data;
      tmp.school.baseData.stateTypeJournal = 0;
      stateUpdate(tmp);
    },
    setHeadTeacherMoveBack(data) {
      let tmp = { ...state };
      tmp.school.arr.headTeacher.headTeacherJournalStates = data;
      stateUpdate(tmp);
    },
    /**
     * Получаем предметы для класса
     * под заучем
     * @param {*} classId
     */
    getHeadTeacherLesson(classId) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.teacher.SchoolId +
        '/Class/' +
        classId +
        '/Subject';
      state.functions.getConstData.crud.get(tempUrl).then(
        (body) => {
          let tmp = { ...state };
          tmp.school.arr.headTeacher.headTeacherLesson = body?.Items;
          stateUpdate(tmp);
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },
    /**
     * Получаем список учеников класса
     * под заучем
     * @param {*} value
     */
    getHeadTeacherSchoolboy(value) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.teacher.SchoolId +
        '/Class/' +
        value +
        '/SchoolBoy';
      state.functions.getConstData.crud.get(tempUrl).then(
        (body) => {
          let tmp = { ...state };
          tmp.school.arr.headTeacher.headTeacherSchoolboy = body?.Items;
          stateUpdate(tmp);
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },
    /**
     * Получаем группы класса
     * под заучем
     * @param {*} classId
     */
    getHeadTeacherPart(classId) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Class/' +
        classId +
        '/Part';
      state.functions.getConstData.crud.get(tempUrl).then(
        (body) => {
          let tmp = { ...state };
          tmp.school.arr.headTeacher.headTeacherPart = body?.Items;
          stateUpdate(tmp);
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },
    getPrintTeacherPart(classId) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Class/' +
        classId +
        '/Part';
      state.functions.getConstData.crud
        .get(tempUrl)
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.headTeacher.headTeacherPart = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then(() => {
          if (state.school.arr.headTeacher?.headTeacherPart !== null) {
            for (
              let i = 0;
              i < state.school.arr.headTeacher?.headTeacherPart?.length;
              i++
            ) {
              state.functions.getPrintPartSchoolboy(
                state.school.arr.headTeacher?.headTeacherPart[i].Id,
              );
            }
          }
        })
        /*.then(
                    () => {
                        if(state.school.arr.headTeacher.headTeacherPart !== null){
                            for(let i = 0; i < state.school.arr.headTeacher.headTeacherPart.length; i++){
                                state.functions.getPrintPartRates(state.school.arr.headTeacher.headTeacherPart[i].Id);
                            }
                        }
                    }
                )*/
        .then(() => {
          let tmp = { ...state };
          tmp.school.print.journalRateAndTopic.listPart = true;
          stateUpdate(tmp);
        });
    },
    getPrintPartRates(classId) {
      //let tempUrl = state.school.baseData.domen + '/v1/' +
      //    state.school.baseData.schoolId + '/Part/' + value + '/Column?embed=Rate';
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Class/' +
        classId +
        '/Column?embed=Rate';

      state.functions.getConstData.crud
        .get(tempUrl)
        .then(
          (body) => {
            let tmp = { ...state };
            //tmp.school.print.journalRateAndTopic.partPrintRates = body.Items;
            tmp.school.print.journalRateAndTopic.partPrintRates = body?.Items?.filter(
              (item) => {
                return item.ColumnTypeId !== 18;
              },
            )
              .filter((item) => {
                return item.ColumnTypeId !== 19;
              })
              .map((item) => {
                return item;
              });
            stateUpdate(tmp);
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then(() => {
          /*let tmpAppId = 0;
                        let tmpPrim = 0;
                        let rate = [];
                        let similarity = false;
                        if(state.school.print.journalRateAndTopic.partPrintRates !== null){
                            for(let i = 0; i < state.school.print.journalRateAndTopic.partPrintRates.length; i++){
                                if(state.school.print.journalRateAndTopic.partPrintRates[i].Primary === 1){
                                    tmpPrim = 1;
                                    tmpAppId = state.school.print.journalRateAndTopic.partPrintRates[i].AppointmentId;
                                    if(state.school.print.journalRateAndTopic.partPrintRates[i].Embed.Rates.length !== 0){
                                        for(let j = 0; j < state.school.print.journalRateAndTopic.partPrintRates[i].Embed.Rates.length; j++){
                                            if(state.school.print.journalRateAndTopic.partPrintRates[i].Embed.Rates[j].MissingTypeId !== null){
                                                similarity = 1;
                                            }
                                        }
                                        if(similarity === 1){
                                            similarity = 0;
                                            rate = state.school.print.journalRateAndTopic.partPrintRates[i].Embed.Rates; 
                                        }
                                    }
                                }else{
                                    tmpPrim = 0;
                                    if(tmpAppId === state.school.print.journalRateAndTopic.partPrintRates[i].AppointmentId){
                                        if(rate.length > 0){
                                            if(state.school.print.journalRateAndTopic.partPrintRates[i].Embed.Rates.length !== 0){
                                                for(let k = 0; k < rate.length; k++){
                                                    if(rate[k].MissingTypeId !== null){
                                                        for(let j = 0; j < state.school.print.journalRateAndTopic.partPrintRates[i].Embed.Rates.length; j++){
                                                            if(rate[k].SchoolboyId === state.school.print.journalRateAndTopic.partPrintRates[i].Embed.Rates[j].SchoolboyId){
     
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                if(rate.length > 0){
                                }
                            }
                        }*/
        });
    },
    getPrintPartSchoolboy(value) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.schoolId +
        '/Part/' +
        value +
        '/SchoolBoy';
      state.functions.getConstData.crud.get(tempUrl).then(
        (body) => {
          for (let i = 0; i < body.Items.length; i++) {
            body.Items[i].PartId = value;
          }
          let tmp = { ...state };
          if (tmp.school.arr?.partSchoolboy?.length !== 0) {
            tmp.school.arr.partSchoolboy = tmp.school.arr?.partSchoolboy?.concat(
              body.Items,
            );
          } else {
            tmp.school.arr.partSchoolboy = body?.Items;
          }
          stateUpdate(tmp);
        },
        (err) => {
          if (err.type === 'error') {
          } else if (err === 404 || err === 403) {
          }
        },
      );
    },
    /**
     * Созаем массив для выведения групп по уроку
     */
    createArrLessonParts(itemId) {
      let tmp = [];
      let count = 0;
      for (
        let i = 0;
        i < state.school.arr.headTeacher?.headTeacherPart?.length;
        i++
      ) {
        if (
          itemId === state.school.arr.headTeacher?.headTeacherPart[i]?.SubjectId
        ) {
          tmp[count] = state.school.arr.headTeacher?.headTeacherPart[i];
          count = count + 1;
        }
      }
      if (tmp.length > 0) {
        let temp = { ...state };
        temp.school.arr.headTeacher.headTeacherArrPartLesson = tmp;
        stateUpdate(temp);
      } else {
        let temp = { ...state };
        temp.school.arr.headTeacher.headTeacherArrPartLesson = null;
        stateUpdate(temp);
      }
    },
    /**
     * Принудительная очистка массивов при выходе с урока на расписание
     */
    clearData() {
      let tmp = { ...state };
      tmp.school.arr.partSchoolboy = [];
      tmp.school.arr.partThemes = [];
      tmp.school.arr.partRates = [];
      tmp.school.arr.lessonTopic = null;
      tmp.school.baseData.Class = null;
      tmp.school.baseData.ClassId = null;
      tmp.school.baseData.Part = null;
      tmp.school.baseData.Subject = null;
      tmp.school.baseData.SubjectId = null;
      tmp.school.baseData.partNumber = null;
      tmp.school.baseData.appointmentNumber = null;
      tmp.school.baseData.stateTypeJournal = 0;
      tmp.school.baseData.streamFilesView = 0;
      tmp.school.baseData.streamFiles = null;
      tmp.school.baseData.streamFilesFlag = false;
      tmp.school.baseData.fullAppointment = null;
      stateUpdate(tmp);
    },
    setFlagFeedback(value) {
      let tmp = { ...state };
      tmp.school.baseData.messageFlag = value;
      stateUpdate(tmp);
      state.functions.getAllFeedback();
    },
    getAllFeedback() {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/feedback/' +
            state.school.baseData.teacherId,
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.feedbackArr = body?.Items;
            tmp.school.baseData.messageFlag = false;
            stateUpdate(tmp);
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    getFeedbackTopic() {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/feedback/topic',
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.feedbackTopic = body?.Items;
            stateUpdate(tmp);
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    feedbackReadTopic(feedbackId) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.teacher.SchoolId +
        '/feedback/' +
        feedbackId +
        '/read';
      let dataJS = null;
      state.functions.getConstData.crud
        .post(tempUrl, dataJS)
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then(() => {
          state.functions.getAllFeedback();
        });
    },
    postFeedback(dataFeedbackTopoclId, dataHead, dataBody) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.teacher.SchoolId +
        '/feedback';
      let dataJS = null;
      if (dataHead === null && dataHead !== '') {
        dataJS = JSON.stringify({
          /*Subject: dataHead,*/
          FeedbackTopicId: dataFeedbackTopoclId,
          Body: dataBody,
        });
      } else {
        dataJS = JSON.stringify({
          Subject: dataHead,
          Body: dataBody,
        });
      }

      state.functions.getConstData.crud
        .post(tempUrl, dataJS)
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then(() => {
          state.functions.changed.feedback.getFeedbackTopicTmp(null);
          state.functions.changed.feedback.getFeedbackTopicHeadTmp(null);
          state.functions.changed.feedback.getFeedbackTopicBodyTmp(null);
        })
        .then(() => {
          state.functions.getAllFeedback();
        })
        .then(() => {
          let tmp = { ...state };
          tmp.school.baseData.feedbackTopic = null;
          tmp.school.baseData.feedbackTopicBody = '';
          tmp.school.baseData.feedbackTopicHead = null;
          stateUpdate(tmp);
        });
    },
    getSchoolboyAppointment(valueId, valueDate) {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/SchoolBoy/' +
            valueId +
            '/Appointment' +
            '?start=' +
            state.school.baseData.data.yearNumberString +
            '-' +
            state.school.baseData.data.monthNumberString +
            '-' +
            valueDate +
            '&end=' +
            state.school.baseData.data.yearNumberString +
            '-' +
            createStringData(state.school.baseData.data.monthNumber) +
            '-' +
            valueDate,
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.allMissedAppointmentSingeSchoolboy = body;
            stateUpdate(tmp);
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    getSchoolboyAppointmentCol(valueId, valueDate) {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/SchoolBoy/' +
            valueId +
            '/Appointment' +
            '?start=' +
            state.school.baseData.data.yearNumberString +
            '-' +
            state.school.baseData.data.monthNumberString +
            '-' +
            valueDate +
            '&end=' +
            state.school.baseData.data.yearNumberString +
            '-' +
            createStringData(state.school.baseData.data.monthNumber) +
            '-' +
            valueDate,
        )
        .then(
          (body) => {
            let tmp = { ...state };
            tmp.school.missing.tmpMissingArrSec = [];
            stateUpdate(tmp);
            for (let i = 0; i < body.Items.length; i++) {
              state.functions.getAppointmentMainColumnOne(
                body?.Items[i]?.Id,
                body?.Items[i]?.Subject,
                valueId,
              );
            }
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    getAppointmentMainColumnOne(value, text, SchoolboyId) {
      let tempUrl = state.school.baseData.domen + '/v1/';
      state.functions.getConstData.crud
        .get(
          tempUrl +
            state.school.baseData.schoolId +
            '/Appointment/' +
            value +
            '/PrimaryColumn',
        )
        .then(
          (body) => {
            body.Subject = text;
            body.SchoolboyId = SchoolboyId;
            let tmp = { ...state };
            tmp.school.missing.tmpMissingArrSec = [
              ...tmp.school?.missing?.tmpMissingArrSec,
              body,
            ];
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
    },
    getSchoolboyAppointmentPart(value, valueData) {
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Appointment/' +
            value +
            '/PrimaryColumn',
        )
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then((body) => {
          state.functions.rateMassMiss(
            body?.Id,
            state.school.baseData?.setMissingSchoolboyId,
            body?.AppointmentId,
            valueData,
          );
        });
    },
    rateMassMiss(dataJurnalId, dataSchoolbouId, dataAppoint, valueData) {
      let tempUrl =
        state.school.baseData.domen +
        '/v1/' +
        state.school.baseData.teacher.SchoolId +
        '/Column/' +
        dataJurnalId +
        '/Missing';
      let dataJS = JSON.stringify({
        SchoolBoyId: dataSchoolbouId,
        AppointmentId: dataAppoint,
        MissingTypeId: valueData,
      });

      state.functions.getConstData.crud
        .post(tempUrl, dataJS)
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then(() => {
          state.functions.getConstData.getOwnClassMissing();
          state.functions.getConstData.getOwnClassMissingSummary();
          state.functions.getConstData.getOwnClassSchoolboy();
          state.functions.getConstData.getMissingSchoolboy(
            state.school.baseData?.setMissingSchoolboyId,
            state.school.baseData?.setMissingSchoolboyId,
          );
        });
    },
    getMissDaySchoolboys() {
      let tmpYear = null;
      if (state.school.baseData.data?.monthNumber > 8) {
        tmpYear = state.school.baseData.data?.yearNumber - 1;
      } else {
        tmpYear = state.school.baseData.data?.yearNumberString;
      }
      state.functions.getConstData.crud
        .get(
          state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Class/' +
            state.school.baseData.teacher.OwnClassId +
            '/FullDayMissing?start=' +
            tmpYear +
            '-' +
            state.school.baseData.data.monthNumberString +
            '-01&end=' +
            tmpYear +
            '-' +
            state.school.baseData.data.monthNumberString +
            '-31',
        )
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then((body) => {
          let tmp = { ...state };
          tmp.school.missing.arrMissingSchoolboyDay = body?.Items;
          stateUpdate(tmp);
        });
    },
    refresch() {
      state.functions.getConstData.crud
        .postRef(state.school.baseData.domen + '/refresh')
        .then(
          (body) => {
            return body;
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        )
        .then((body) => {
          if (state.school.baseData.stateError === 0) {
            let tmp = { ...state };
            tmp.school.baseData.token = body?.token;
            tmp.school.baseData.refresh = body?.refresh;
            stateUpdate(tmp, true);
            // stateUpdate(tmp);
          }
        });
    },
    setScrollTop(value) {
      let tmp = { ...state };
      tmp.school.baseData.scrollTop = value;
      stateUpdate(tmp);
    },
    setScrollLeft(value) {
      let tmp = { ...state };
      tmp.school.baseData.scrollLeft = value;
      stateUpdate(tmp);
    },
    countPart(value) {
      let tmp = { ...state };
      tmp.school.baseData.countPart = value;
      stateUpdate(tmp);
    },
    homeforkFilesUploadPath(value) {
      let tmp = { ...state };
      tmp.school.baseData.homeworkFilesUpload = value;
      stateUpdate(tmp);
    },
    setHomeworkCheckbox(value) {
      let temp = false;
      if (value === 1) {
        temp = true;
      }

      let tmp = { ...state };
      tmp.school.baseData.homeworkCheckbox = temp;
      stateUpdate(tmp);
    },
    getConstData: {
      crud: {
        get(_url) {
          return new Promise(function (resolve, reject) {
            let request = new XMLHttpRequest();
            let jsonData;
            request.open(`GET`, _url);
            request.setRequestHeader(
              'X-Auth-Token',
              state.school.baseData.token,
            );
            request.setRequestHeader('Api-key', state.school.baseData.apikey);
            request.setRequestHeader('Content-type', 'application/json');
            request.onload = function () {
              if (request.status === 200 && request.readyState === 4) {
                jsonData = JSON.parse(request.responseText);
                resolve(jsonData);
              } else {
                reject(request.status);
              }
            };
            request.onerror = function (error) {
              reject(error);
            };
            request.send();
          });
        },
        getNotJSON(_url) {
          return new Promise(function (resolve, reject) {
            let request = new XMLHttpRequest();
            // eslint-disable-next-line no-unused-vars
            let jsonData;
            request.open(`GET`, _url);
            request.setRequestHeader(
              'X-Auth-Token',
              state.school.baseData.token,
            );
            request.setRequestHeader('Api-key', state.school.baseData.apikey);
            request.setRequestHeader('Content-type', 'application/json');
            request.onload = function () {
              if (request.status === 200 && request.readyState === 4) {
                //jsonData = JSON.parse( request.responseText );
                resolve(request.responseText);
              } else {
                reject(request.status);
              }
            };
            request.onerror = function (error) {
              reject(error);
            };
            request.send();
          });
        },
        post(_url, data) {
          return new Promise(function (resolve, reject) {
            let request = new XMLHttpRequest();
            let jsonData;
            request.open('POST', _url);
            request.setRequestHeader(
              'X-Auth-Token',
              state.school.baseData.token,
            );
            request.setRequestHeader('Api-key', state.school.baseData.apikey);
            request.setRequestHeader('Content-type', 'application/json');
            request.onload = function () {
              if (request.status === 200 && request.readyState === 4) {
                jsonData = JSON.parse(request.responseText);
                resolve(jsonData);
              } else {
                if (request.response.indexOf('bad_client') === 0) {
                  reject(999);
                } else {
                  reject(request.status);
                }
              }
            };
            request.onerror = function (error) {
              reject(error);
            };
            request.send(data);
          });
        },
        postRef(_url, data) {
          return new Promise(function (resolve, reject) {
            let request = new XMLHttpRequest();
            let jsonData;
            request.open('POST', _url);
            request.setRequestHeader(
              'X-Auth-Token',
              state.school.baseData.refresh,
            );
            request.setRequestHeader('Api-key', state.school.baseData.apikey);
            request.setRequestHeader('Content-type', 'application/json');
            request.onload = function () {
              if (request.status === 200 && request.readyState === 4) {
                jsonData = JSON.parse(request.responseText);
                resolve(jsonData);
              } else {
                reject(request.status);
              }
            };
            request.onerror = function (error) {
              reject(error);
            };
            request.send(data);
          });
        },
      },
      getClassAverRateFirstSem(value, startDate, endDate) {
        let tempUrl = state.school.baseData.domen + '/v1/';
        state.functions.getConstData.crud
          .get(
            tempUrl +
              state.school.baseData.schoolId +
              '/Class/' +
              value +
              '/RateAvg?start=' +
              startDate +
              '&end=' +
              endDate,
          )
          .then(
            (body) => {
              let tmp = { ...state };
              tmp.school.arr.allClassAverRateFirstSem = body?.Items;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
      },
      getClassAverRateSecondSem(value, startDate, endDate) {
        let tempUrl = state.school.baseData.domen + '/v1/';
        state.functions.getConstData.crud
          .get(
            tempUrl +
              state.school.baseData.schoolId +
              '/Class/' +
              value +
              '/RateAvg?start=' +
              startDate +
              '&end=' +
              endDate,
          )
          .then(
            (body) => {
              let tmp = { ...state };
              tmp.school.arr.allClassAverRateSecondSem = body?.Items;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
      },
      getClassAverRateYear(value, startDate, endDate) {
        let tempUrl = state.school.baseData.domen + '/v1/';
        state.functions.getConstData.crud
          .get(
            tempUrl +
              state.school.baseData.schoolId +
              '/Class/' +
              value +
              '/RateAvg?start=' +
              startDate +
              '&end=' +
              endDate,
          )
          .then(
            (body) => {
              let tmp = { ...state };
              tmp.school.arr.allClassAverRateYear = body?.Items;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
      },
      getAllClass() {
        let tempUrl = state.school.baseData.domen + '/v1/';
        state.functions.getConstData.crud
          .get(tempUrl + state.school.baseData.schoolId + '/Class')
          .then(
            (body) => {
              let tmp = { ...state };
              tmp.school.arr.allClass = body?.Items;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
      },
      getJournalRemark(value) {
        let tempUrl = state.school.baseData.domen + '/v1/';
        state.functions.getConstData.crud
          .get(
            tempUrl +
              state.school.baseData.schoolId +
              '/Class/' +
              value +
              '/Remark',
          )
          .then(
            (body) => {
              let tmp = { ...state };
              tmp.school.arr.ownClassJournalRemark = body?.Items;
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
      },
      getAllJournalRemark() {
        let temps = { ...state };
        temps.school.arr.ownClassJournalRemark = null;
        stateUpdate(temps);
        for (let i = 0; i < state.school.arr.allClass.length; i++) {
          let tempUrl = state.school.baseData.domen + '/v1/';
          state.functions.getConstData.crud
            .get(
              tempUrl +
                state.school.baseData.schoolId +
                '/Class/' +
                state.school.arr.allClass[i].Id +
                '/Remark',
            )
            .then(
              // eslint-disable-next-line no-loop-func
              (body) => {
                let tmp = { ...state };
                let temp = [];
                if (tmp.school.arr?.ownClassJournalRemark !== null) {
                  temp = tmp.school.arr?.ownClassJournalRemark;
                }
                if (body?.Items?.length > 0) {
                  if (temp?.length > 0) {
                    temp = temp.concat(body?.Items);
                  } else {
                    temp = body?.Items;
                  }
                }
                tmp.school.arr.ownClassJournalRemark = temp;
                stateUpdate(tmp);
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            );
        }
      },
      getTeacherReplacement() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Teacher/' +
          state.school.baseData.teacherId +
          '/Replacement';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {},
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getMissingType() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/MissingType';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.typeMissingArr = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getRateType() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/RateValue';
        state.functions.getConstData.crud
          .get(tempUrl)
          .then(
            (body) => {
              let tmp = body?.Items?.sort(function (a, b) {
                return a.Value - b.Value;
              }).reverse();
              body.Items = tmp;
              return body;
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then((body) => {
            let tmp = { ...state };
            tmp.school.arr.rateValueArr = body?.Items;
            stateUpdate(tmp);
          });
      },
      getAllRateValue() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/RateValue?RateCategoryID=-1';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let sortBody = body?.Items?.sort((a, b) => {
              return a?.Value - b?.Value;
            }).reverse();
            let tmp = { ...state };
            tmp.school.arr.rateAllValueArr = sortBody;
            tmp.school.arr.textMarkAllValueArr = sortBody?.filter(
              (item) => item.Value < 0,
            );
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getTypeColumn() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/RateType';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.typeColumnArr = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      /**
       * Получаем пропуски для класса
       *
       */
      getOwnClassMissing() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/Class/' +
          state.school.baseData.teacher.OwnClassId +
          '/Missing?year=' +
          state.school.baseData.data.studyPeriodNumber +
          /*'&semester=' + state.school.baseData.data.semesterNumber +
                    '&quarter=' + state.school.baseData.data.quarterNumber +*/
          '&start=' +
          state.school.baseData.data.yearNumberString +
          '-' +
          createStringData(state.school.baseData.data.monthNumber) +
          '-01&end=' +
          state.school.baseData.data.yearNumberString +
          '-' +
          createStringData(state.school.baseData.data.monthNumber) +
          '-' +
          state.functions.data.daysInMonth(
            state.school.baseData?.data?.monthNumber,
            state.school.baseData?.data?.yearNumber,
          );
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.ownClassMissing = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getOwnClassMissingSummary() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/Class/' +
          state.school.baseData.teacher.OwnClassId +
          '/MissingSummary?year=' +
          state.school.baseData.data.studyPeriodNumber +
          /*'&semester=' + state.school.baseData.data.semesterNumber +
                    '&quarter=' + state.school.baseData.data.quarterNumber +*/
          '&start=' +
          state.school.baseData.data.yearNumberString +
          '-' +
          createStringData(state.school.baseData.data.monthNumber) +
          '-01&end=' +
          state.school.baseData.data.yearNumberString +
          '-' +
          createStringData(state.school.baseData.data.monthNumber) +
          '-' +
          state.functions.data.daysInMonth(
            state.school.baseData.data.monthNumber,
            state.school.baseData.data.yearNumber,
          );
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.ownClassMissingSummary = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },

      getOwnClassAllPartAndAppointment() {
        let tmpDates = new Date();
        let tmpMonthe = tmpDates.getMonth() + 1;
        let tmpYear = state.school.baseData.startAcademicYear;
        if (tmpMonthe < state.school.baseData.minAcademicMonth) {
          tmpYear = state.school.baseData.endAcademicYear;
        }
        // eslint-disable-next-line no-unused-vars
        let tmpDate = state.school.baseData.data.monthNumber;
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/Class/' +
          state.school.baseData.teacher.OwnClassId +
          '/Appointment?start=' +
          tmpYear +
          '-' +
          tmpMonthe +
          '-01&end=' +
          tmpYear +
          '-' +
          tmpMonthe +
          '-31';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.ownClassSchedule = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      /**
       * Получаем учеников для класса
       *
       */
      clearOwnClassSchoolboy() {
        let tmp = { ...state };
        tmp.school.arr.ownClassSchoolboy = [];
        stateUpdate(tmp);
      },
      getOwnClassSchoolboy() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/Class/' +
          state.school.baseData.teacher.OwnClassId +
          '/SchoolBoy';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.ownClassSchoolboy = body?.Items;
            tmp.school.print.journalRateAndTopic.classSchoolboy = true;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getOwnClassSchoolboyAndParent() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/Class/' +
          state.school.baseData.teacher.OwnClassId +
          '/SchoolBoy?embed=Parent';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.ownClassSchoolboy = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getOwnClassSubject() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/Class/' +
          state.school.baseData.teacher.OwnClassId +
          '/Subject';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.ownClassSubject = body?.Items;
            tmp.school.print.journalRateAndTopic.flagClassSubject = true;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getMissingSchoolboy(schoolboyId, data) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.teacher.SchoolId +
          '/SchoolBoy/' +
          schoolboyId +
          '/Missing?start=' +
          data +
          '&end=' +
          data;
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.missing.tmpMissingArr = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getChurrentMeetUrl(appointment, setMeet) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Appointment/' +
          appointment +
          '/GetMeetingUrl';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            setMeet(body);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      errorLoginConect(value) {
        let tmp = { ...state };
        tmp.school.baseData.stateError = value;
        tmp.school.baseData.viewLogin.property.startScreen = 0;
        stateUpdate(tmp);
      },
      postLogin(body) {
        state.functions.getConstData.crud
          .post(state.school.baseData.domen + '/signIn', body)
          .then(
            (body) => {
              state.functions.menu.reversText(0);
              return body;
            },
            (err) => {
              if (err.type === 'error') {
                state.functions.getConstData.errorLoginConect(1);
              } else if (err === 404 || err === 403) {
                state.functions.getConstData.errorLoginConect(2);
              } else if (err === 999) {
                state.functions.getConstData.errorLoginConect(3);
              }
              let tmp = { ...state };
              tmp.school.popUpFlag.error = true;
              tmp.school.baseData.password = null;
              stateUpdate(tmp);
            },
          )
          .then((body) => {
            const item = {
              value: body,
              expiry: new Date().getTime() + 2400000,
            };
            localStorage.setItem('token', JSON.stringify(item));
            if (state.school.baseData.stateError === 0) {
              let temp;
              let indexToken;
              let tmpArr = [];
              for (let i = 0; i < body?.length; i++) {
                tmpArr[i] = parseJwt(body[i]?.token);
              }
              // eslint-disable-next-line array-callback-return
              temp = tmpArr.find((item, index) => {
                if (item.role === 'schoolboy') {
                  indexToken = index;
                  return true;
                }
              });
              if (!temp) {
                // indexToken = 0
                // temp = tmpArr[0]
                state.functions.getConstData.errorLoginConect(2);
                let tmp = { ...state };
                tmp.school.popUpFlag.error = true;
                tmp.school.baseData.password = null;
                stateUpdate(tmp);
                return;
              }
              let tmp = { ...state };
              tmp.school.baseData.schoolId = temp?.school_id;
              tmp.school.baseData.teacherId = temp?.jti;
              tmp.school.baseData.role = temp?.role;
              tmp.school.baseData.token = body[indexToken]?.token;
              tmp.school.baseData.refresh = body[indexToken]?.refresh;
              tmp.school.baseData.flagInstruction = true;
              stateUpdate(tmp);
            }
          })
          .then(() => {
            if (state.school.baseData.stateError === 0) {
              state.functions.data.detectStart();
            }
          })
          .then(() => {
            state.functions.getConstData
              .getAcademicPeriod()
              .then(() => {
                if (state.school.baseData.stateError === 0) {
                  sessionStorage.clear();
                  state.functions.getConstData.getMissingType();
                  state.functions.getConstData.getRateType();
                  state.functions.getConstData.getAllRateValue();
                  state.functions.getConstData.getTypeColumn();
                  state.functions.getConstData.getSchoolboySchedule(
                    state.school.arr.wecend[0]?.year,
                    createStringData(state.school.arr.wecend[0]?.month),
                    createStringData(state.school.arr.wecend[0]?.day),
                    state.school.arr.wecend[5]?.year,
                    createStringData(state.school.arr.wecend[5]?.month),
                    createStringData(state.school.arr.wecend[5]?.day),
                  );
                  state.functions.getConstData.getSchoolboyLink();
                  state.functions.getConstData.getSchoolboyParts();
                  state.functions.getConstData.getParentComments();
                  // state.functions.getConstData.getSchoolboyRates();
                  setInterval(() => {
                    sessionStorage.clear();
                  }, 7200000);
                }
              })
              .then(() => {
                if (state.school.baseData.stateError === 0) {
                  let tmp = { ...state };
                  tmp.school.baseData.appointFlag = true;
                  tmp.school.baseData.password = null;
                  stateUpdate(tmp);
                }
              });
          });
      },
      closeFlagInstruction() {
        let tmp = { ...state };
        tmp.school.baseData.flagInstruction = false;
        stateUpdate(tmp);
      },
      getAcademicPeriod() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          'School/' +
          state.school.baseData.schoolId;
        return state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.baseData.academicYear = body?.AcademicYear;
            // tmp.school.baseData.academicYear = '2019,2020'; // хардкод
            tmp.school.baseData.data.studyPeriodNumber = body?.AcademicYear;
            // tmp.school.baseData.data.studyPeriodNumber = '2019,2020'; // хардкод
            tmp.school.baseData.startAcademicYear = tmp.school.baseData.academicYear.split(
              ',',
            )[0];
            tmp.school.baseData.endAcademicYear = tmp.school.baseData.academicYear.split(
              ',',
            )[1];
            tmp.school.baseData.SupportUrl = body?.Links?.SupportUrl?.Href;
            stateUpdate(tmp);
            return state.functions.getConstData.crud
              .get(body.Links.AcademicYear.Href)
              .then((body) => {
                const startAcademicPeriod = [...body.Items];
                startAcademicPeriod.sort((a, b) =>
                  Date.parse(a.Start) > Date.parse(b.Start) ? 1 : -1,
                );
                const endAcademicPeriod = [...body.Items];
                endAcademicPeriod.sort((a, b) =>
                  Date.parse(a.End) < Date.parse(b.End) ? 1 : -1,
                );
                let tmp = { ...state };
                tmp.school.baseData.startAcademicPeriod =
                  startAcademicPeriod[0]?.Start;
                // tmp.school.baseData.startAcademicPeriod = "2019-09-01"; //хардкод
                tmp.school.baseData.endAcademicPeriod =
                  endAcademicPeriod[0]?.End;
                // tmp.school.baseData.endAcademicPeriod = "2020-08-31"; //хардкод
                tmp.school.baseData.minAcademicMonth =
                  new Date(tmp.school.baseData.startAcademicPeriod).getMonth() +
                  1;
                // tmp.school.baseData.minAcademicMonth = 9; //хардкод
                stateUpdate(tmp);
              });
          },
          (err) => {
            if (err.type === 'error') {
              localStorage.removeItem('token');
              state.school.baseData.viewLogin.methods.changeStartScreen(0);
            } else if (err === 404 || err === 403) {
              localStorage.removeItem('token');
              state.school.baseData.viewLogin.methods.changeStartScreen(0);
            }
          },
        );
      },
      clearError() {
        let tmp = { ...state };
        tmp.school.popUpFlag.error = false;
        stateUpdate(tmp);
      },
      postMissing(url, data) {
        state.functions.getConstData.crud
          .post(url, data)
          .then(
            (body) => {
              return body;
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then(() => {
            state.functions.getConstData.getOwnClassMissing();
            state.functions.getConstData.getOwnClassMissingSummary();
          })
          .then(() => {
            //Тут надо выбрать дату и айди ученика
            //Эти данные надо внести в стейт чтоб с ними работать
            //Потом я перевызываю с ними функцию ниже и у меня обновляемый попап
            state.functions.getSchoolboyAppointmentCol(
              state.school.missing?.postMiss?.SchoolboyId,
              state.school.missing?.postMiss?.data,
            );
            //потом такое же применить и для удаления Нки в миссингах
          });
      },
      setMissing(SchoolboyId, data) {
        let temp = { ...state };
        temp.school.missing.postMiss = { SchoolboyId: SchoolboyId, data: data };
        stateUpdate(temp);
      },
      /*getTeacherApointment*/
      getSchoolboyLink() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          `/SchoolBoy/` +
          state.school.baseData.teacherId;
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.baseData.userFullName = body?.Nick
              ? body?.Nick
              : `${body?.LastName} ${body?.FirstName} ${body?.SecondName}`;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getParentComments() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/CommentType';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let tmp = { ...state };
            tmp.school.arr.parentComments = body?.Items;
            stateUpdate(tmp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getSchoolboyParts() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          `/SchoolBoy/` +
          state.school.baseData.teacherId +
          `/Part`;
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.arr.lessonsAndTeachers = body?.Items;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getSchoolboyRates() {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/SchoolBoy/' +
          state.school.baseData.teacherId +
          '/Appointment?start=' +
          state.school.baseData.startAcademicPeriod +
          '&end=' +
          state.school.baseData.endAcademicPeriod +
          '&embed=Rate,Topic,Homework';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.arr.allRates = body?.Items;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getSchoolboySchedule(sYear, sMonth, sDay, eYear, eMonth, eDay) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          `/SchoolBoy/` +
          state.school.baseData.teacherId +
          `/Appointment?start=` +
          sYear +
          `-` +
          sMonth +
          `-` +
          sDay +
          `&end=` +
          eYear +
          `-` +
          eMonth +
          `-` +
          eDay +
          `&embed=Topic,Rate,TargetHomework,Teacher,Meeting&fileRole=schoolboy`;
        let keyWeek = `${sYear}-${sMonth}-${sDay}-${eYear}-${eMonth}-${eDay}`;
        // eslint-disable-next-line no-unused-vars
        let sessionData = sessionStorage.getItem(keyWeek);
        //commented becouse state is not updated
        //if (!sessionData) {
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.arr.appointment = body?.Items?.filter((item) => !item.Canceled);
            sessionStorage[keyWeek] = JSON.stringify(body?.Items?.filter((item) => !item.Canceled));
            state.profile.functions.setIsLoadingSpinner(false);
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
              state.profile.functions.setIsLoadingSpinner(false);
            } else if (err === 404 || err === 403) {
              state.profile.functions.setIsLoadingSpinner(false);
            } else state.profile.functions.setIsLoadingSpinner(false);
          },
        );
        // } else {
        //   let temp = { ...state };
        //   temp.school.arr.appointment = JSON.parse(sessionStorage[keyWeek]);
        //   state.profile.functions.setIsLoadingSpinner(false);
        //   stateUpdate(temp);
        // }
      },
      getSchoolboySchedulePing(sYear, sMonth, sDay, eYear, eMonth, eDay) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          `/SchoolBoy/` +
          state.school.baseData.teacherId +
          `/Appointment?start=` +
          sYear +
          `-` +
          sMonth +
          `-` +
          sDay +
          `&end=` +
          eYear +
          `-` +
          eMonth +
          `-` +
          eDay +
          `&embed=TargetHomework&fileRole=schoolboy`;
        let keyWeek = `${sYear}-${sMonth}-${sDay}-${eYear}-${eMonth}-${eDay}`;
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            if (state.school.arr.appointment.length === body?.Items?.length) {
              let temp = { ...state };
              let tmp = [...temp.school.arr?.appointment];
              let isDoCycle = true;
              tmp.forEach((item, index) => {
                let findAppoint = body?.Items?.find(
                  (findElem) => item.Id === findElem.Id,
                );
                if (!findAppoint) {
                  isDoCycle = false;
                }
                if (!isDoCycle) {
                  return;
                }
                tmp[index].StreamName = findAppoint?.StreamName;
                tmp[index].StreamStatus = findAppoint?.StreamStatus;
                tmp[index].TestID = findAppoint?.TestID;
                tmp[index].TestStatus = findAppoint?.TestStatus;
                tmp[index].ZoomStreamName = findAppoint?.ZoomStreamName;
                tmp[index].Embed.Homeworks = findAppoint?.Embed?.Homeworks;
                tmp[index].Embed.TargetHomeworks =
                  findAppoint?.Embed?.TargetHomeworks;
              });
              if (isDoCycle) {
                temp.school.arr.appointment = tmp;
                sessionStorage[keyWeek] = JSON.stringify(tmp);
              } else {
                sessionStorage.removeItem(keyWeek);
                state.functions.getConstData.getSchoolboySchedule(
                  sYear,
                  sMonth,
                  sDay,
                  eYear,
                  eMonth,
                  eDay,
                );
              }
              stateUpdate(temp);
            } else {
              sessionStorage.removeItem(keyWeek);
              state.functions.getConstData.getSchoolboySchedule(
                sYear,
                sMonth,
                sDay,
                eYear,
                eMonth,
                eDay,
              );
            }
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      nullPartSchoolboy() {
        let temp = { ...state };
        temp.school.arr.partSchoolboy = [];
        //temp.school.baseData.scrollTopBlock = body.Items.length;
        stateUpdate(temp);
      },
      getPartSchoolboy(value) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Part/' +
          value +
          '/SchoolBoy';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.arr.partSchoolboy = body?.Items;
            temp.school.popUpFlag.partFlag = true;
            temp.school.baseData.scrollTopBlock = body?.Items?.length;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getMultiPartSchoolboy(value, count) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Part/' +
          value +
          '/SchoolBoy';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.baseData.multiPartLesson.PartSchoolboys[count] =
              body?.Items;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getPartThemes(value) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Part/' +
          value +
          '/Topic?embed=Homework';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.arr.partThemes = body?.Items;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getMultiPartThemes(value, count) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Part/' +
          value +
          '/Topic?embed=Homework';
        state.functions.getConstData.crud.get(tempUrl).then(
          (body) => {
            let temp = { ...state };
            temp.school.baseData.multiPartLesson.PartThemes[count] =
              body?.Items;
            stateUpdate(temp);
          },
          (err) => {
            if (err.type === 'error') {
            } else if (err === 404 || err === 403) {
            }
          },
        );
      },
      getPartRates(value, data) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/Part/' +
          value +
          '/Column?embed=Rate';
        state.functions.getConstData.crud
          .get(tempUrl)
          .then(
            (body) => {
              let temp = { ...state };
              temp.school.arr.partRates = body?.Items;
              stateUpdate(temp);
              return body;
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then((body) => {
            let tmp = [];
            if (body?.Items !== null) {
              for (let i = 0; i < body?.Items?.length; i++) {
                if (body?.Items[i]?.ColumnTypeId === 2) {
                  if (
                    body.Items[i].Embed.Rates !== null ||
                    body.Items[i].Embed.Rates.length !== 0
                  ) {
                    for (
                      let k = 0;
                      k < body.Items[i]?.Embed?.Rates?.length;
                      k++
                    ) {
                      if (body.Items[i]?.Embed?.Rates[k]?.Rate !== 0) {
                        tmp[tmp?.length] = {
                          TopicId: body?.Items[i]?.TopicId,
                          PartId: body?.Items[i]?.PartId,
                          ColumnTypeId: body?.Items[i]?.ColumnTypeId,
                          ClassId: body?.Items[i]?.ClassId,
                          AppointmentId: body?.Items[i]?.AppointmentId,
                          Id: body?.Items[i]?.Id,
                          SubjectId: body?.Items[i]?.SubjectId,
                          SchoolboyId:
                            body?.Items[i]?.Embed?.Rates[k]?.SchoolboyId,
                        };
                      }
                    }
                  }
                }
              }
            }
            state.functions.setArrClosedThemesAndSchoolboy(tmp);
          });
      },
      //эта функция нигде не вызывается. закомментировала
      // getMultiPartRates(value, count) {
      //   let tempUrl =
      //     state.school.baseData.domen +
      //     "/v1/" +
      //     state.school.baseData.schoolId +
      //     "/Part/" +
      //     value +
      //     "/Column?embed=Rate";
      //   state.functions.getConstData.crud
      //     .get(tempUrl)
      //     .then(
      //       (body) => {
      //         let temp = { ...state };
      //         temp.school.baseData.multiPartLesson.PartRates[count] =
      //           body?.Items;
      //         stateUpdate(temp);
      //         return { body: body, count: count };
      //       },
      //       (err) => {
      //         if (err.type === "error") {
      //         } else if (err === 404 || err === 403) {
      //         }
      //       }
      //     )
      //     .then((closedThemes) => {
      //       let tmp = [];
      //       if (closedThemes?.body?.Items !== null) {
      //         for (let i = 0; i < closedThemes?.body?.Items?.length; i++) {
      //           if (closedThemes?.body?.Items[i]?.ColumnTypeId === 2) {
      //             if (
      //               closedThemes?.body?.Items[i]?.Embed?.Rates !== null ||
      //               closedThemes?.body?.Items[i]?.Embed?.Rates?.length !== 0
      //             ) {
      //               for (
      //                 let k = 0;
      //                 k < closedThemes?.body?.Items[i]?.Embed?.Rates?.length;
      //                 k++
      //               ) {
      //                 if (
      //                   closedThemes?.body?.Items[i]?.Embed?.Rates[k]?.Rate !== 0
      //                 ) {
      //                   tmp[tmp?.length] = {
      //                     TopicId: closedThemes?.body?.Items[i]?.TopicId,
      //                     PartId: closedThemes?.body?.Items[i]?.PartId,
      //                     ColumnTypeId: closedThemes?.body?.Items[i]?.ColumnTypeId,
      //                     ClassId: closedThemes?.body?.Items[i]?.ClassId,
      //                     AppointmentId:
      //                       closedThemes?.body?.Items[i]?.AppointmentId,
      //                     Id: closedThemes?.body?.Items[i]?.Id,
      //                     SubjectId: closedThemes?.body?.Items[i]?.SubjectId,
      //                     SchoolboyId:
      //                       closedThemes?.body?.Items[i]?.Embed?.Rates[k]?.SchoolboyId,
      //                   };
      //                 }
      //               }
      //             }
      //           }
      //         }
      //       }
      //       state.functions.getConstData.setMultiPartClosedThemes(
      //         tmp,
      //         closedThemes?.count
      //       );
      //     });
      // },
      setMultiPartClosedThemes(value, count) {
        let tmp = { ...state };
        tmp.school.baseData.multiPartLesson.PartClosedThemes[count] = value;
        stateUpdate(tmp);
      },
      setAppointment(value) {
        let tmp = { ...state };
        tmp.school.baseData.multiPartLesson.Appointment = value;
        stateUpdate(tmp);
      },

      getSchoolboyParent(value) {
        let tempUrl =
          state.school.baseData.domen +
          '/v1/' +
          state.school.baseData.schoolId +
          '/SchoolBoy/' +
          value +
          '?embed=Parent';
        state.functions.getConstData.crud
          .get(tempUrl)
          .then(
            (body) => {
              return body;
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          )
          .then((body) => {
            let temp = { ...state };
            temp.school.arr.tmpParent = body;
            stateUpdate(temp);
          });
      },
    },
    changed: {
      flags: {
        choiseClassRemark(value) {
          let tmp = { ...state };
          tmp.school.baseData.choiseClassRemark = value;
          stateUpdate(tmp);
        },
        choiseClassRemarkText(value) {
          let tmp = { ...state };
          tmp.school.baseData.choiseClassRemarkText = value;
          stateUpdate(tmp);
        },
        choiseClassRemarkTextOwn(count, value) {
          let tmp = { ...state };
          tmp.school.arr.ownClassJournalRemark[count].Notes = value;
          stateUpdate(tmp);
        },
        popUpRatesPicker() {
          let tmp = { ...state };
          !tmp.school.popUpFlag.ratePicker
            ? (tmp.school.popUpFlag.ratePicker = true)
            : (tmp.school.popUpFlag.ratePicker = false);
          stateUpdate(tmp);
        },
        popUpMissingPicker(schoolboyId, data) {
          let tmp = { ...state };
          !tmp.school.popUpFlag.missingPicker
            ? (tmp.school.popUpFlag.missingPicker = true)
            : (tmp.school.popUpFlag.missingPicker = false);
          tmp.school.missing.popUpSchoolboyId = schoolboyId;
          tmp.school.missing.popUpData = data;
          stateUpdate(tmp);
        },
        popUpCreateColumnPicker(value) {
          let tmp = { ...state };
          tmp.school.popUpFlag.createColumnPicker = value;
          stateUpdate(tmp);
        },
        popupProfile() {
          let tmp = { ...state };
          !tmp.school.popUpFlag.profileFlag
            ? (tmp.school.popUpFlag.profileFlag = true)
            : (tmp.school.popUpFlag.profileFlag = false);
          stateUpdate(tmp);
        },
        popupRate(value) {
          let tmp = { ...state };
          tmp.school.baseData.popUpDataRate = value;
          stateUpdate(tmp);
        },
        ratePartId(value) {
          let tmp = { ...state };
          tmp.school.baseData.partNumber = value;
          stateUpdate(tmp);
        },
        columnTopicType(value) {
          let tmp = { ...state };
          tmp.school.baseData.popupColumnTopicType = value;
          stateUpdate(tmp);
        },
        columnTopicTitle(value) {
          let tmp = { ...state };
          tmp.school.baseData.popupColumnTopicTitle = value;
          stateUpdate(tmp);
        },
        columnTopicDescription(value) {
          let tmp = { ...state };
          tmp.school.baseData.popupColumnTopicDescription = value;
          stateUpdate(tmp);
        },
        columnTypeId(value) {
          let tmp = { ...state };
          tmp.school.baseData.columnTypeId = Number(value);
          stateUpdate(tmp);
        },
        popUpHomework(value) {
          let tmp = { ...state };
          tmp.school.popUpFlag.homeworkPicker = value;
          tmp.school.baseData.topicNumberHomework = 0;
          tmp.school.baseData.targetApointment = 0;
          stateUpdate(tmp);
        },
        switchTopicHomework(value) {
          let tmp = { ...state };
          tmp.school.baseData.topicNumberHomework = Number(value);
          stateUpdate(tmp);
        },
        switchTargetAppointmentHomework(value) {
          let tmp = { ...state };
          tmp.school.baseData.targetApointment = Number(value);
          stateUpdate(tmp);
        },
        switchTargetAppointmentForThemes(value) {
          let tmp = { ...state };
          tmp.school.baseData.targetApointmentForThemes = Number(value);
          stateUpdate(tmp);
        },
        switchTargetAppointmentForThemesBind(value) {
          let tmp = { ...state };
          tmp.school.baseData.BindTopicForAppointment = false;
          tmp.school.baseData.targetApointmentForThemesBind = Number(value);
          stateUpdate(tmp);
        },
        changeFlagViewDZ(value) {
          let tmp = { ...state };
          tmp.school.popUpFlag.flagViewDZ = value;
          stateUpdate(tmp);
        },
        changeFlagCreateTopic(value) {
          let tmp = { ...state };
          tmp.school.baseData.BindTopicForAppointment = false;
          tmp.school.popUpFlag.createTopicPiker = value;
          stateUpdate(tmp);
        },
        changeFlagBrowserMessage(value) {
          let tmp = { ...state };
          tmp.school.popUpFlag.flagBrowserMessage = value;
          stateUpdate(tmp);
        },
      },
      data: {
        headTeacherPart(PartId) {
          let tmp = { ...state };
          tmp.school.baseData.PartId = PartId;
          tmp.school.baseData.partNumber = PartId;
          stateUpdate(tmp);
        },
        appointmentChoise(
          ReplaceId,
          value,
          data,
          ClassId,
          SubjectId,
          PartId,
          Subject,
          Part,
          Class,
          typeJournal,
          StreamName,
          StreamStatus,
          topicText,
          rate,
          TeacherId,
        ) {
          let tmp = { ...state };
          tmp.school.baseData.appointmentNumber = value;
          tmp.school.baseData.dataFlag = data;
          tmp.school.baseData.ClassId = ClassId;
          tmp.school.baseData.SubjectId = SubjectId;
          tmp.school.baseData.PartId = PartId;
          tmp.school.baseData.Subject = Subject;
          tmp.school.baseData.Part = Part;
          tmp.school.baseData.Class = Class;
          tmp.school.baseData.typeJournal = typeJournal;
          tmp.school.baseData.StreamName = StreamName;
          tmp.school.baseData.StreamStatus = StreamStatus;
          tmp.school.baseData.topicText = topicText;
          tmp.school.baseData.rate = rate;
          tmp.school.baseData.TeacherId = TeacherId;
          tmp.school.baseData.teacherIdChoiceLesson = TeacherId;
          tmp.school.baseData.appointmentReplaceId = ReplaceId;
          stateUpdate(tmp);
        },
        dateLessonColumn(value) {
          let tmp = { ...state };
          tmp.school.baseData.data.tmpDateLessonColumn = value;
          stateUpdate(tmp);
        },
        dateColumnType(value) {
          let tmp = { ...state };
          tmp.school.baseData.partNumber = Number(value);
          stateUpdate(tmp);
        },
        dateColumnTopic(value) {
          let tmp = { ...state };
          tmp.school.baseData.topicNumber = Number(value);
          stateUpdate(tmp);
        },
      },
      column: {
        getTmpColumnSelectCreateThemes(value) {
          let temp = { ...state };
          temp.school.baseData.createThemeTmp = Number(value);
          stateUpdate(temp);
        },
        getTmpColumnSelectCreateTematicThemes(value) {
          let temp = { ...state };
          temp.school.baseData.createThemeTematicTmp = Number(value);
          stateUpdate(temp);
        },
        getTmpColumnSelectThemes(value) {
          let temp = { ...state };
          temp.school.baseData.BindTopicForAppointment = false;
          temp.school.baseData.appointThemeTmp = Number(value);
          stateUpdate(temp);
        },
        getTmpColumnSelectThemesEdit(value) {
          let temp = { ...state };
          temp.school.baseData.appointThemeTmpEdit = Number(value);
          stateUpdate(temp);
        },
      },
      feedback: {
        getFeedbackTopicTmp(value) {
          let temp = { ...state };
          temp.school.baseData.feedbackTopic = Number(value);
          stateUpdate(temp);
        },
        getFeedbackTopicBodyTmp(value) {
          let temp = { ...state };
          temp.school.baseData.feedbackTopicBody = value;
          stateUpdate(temp);
        },
        getFeedbackTopicHeadTmp(value) {
          let temp = { ...state };
          temp.school.baseData.feedbackTopicHead = value;
          stateUpdate(temp);
        },
      },
    },
    get: {
      column: {
        dataTopic() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Topic?partId=' +
            state.school.baseData.partNumber;

          state.functions.getConstData.crud.get(tempUrl).then(
            (body) => {
              let temp = { ...state };
              temp.school.arr.lessonTopic = body?.Items;
              temp.school.popUpFlag.flagLessonTopic = true;
              stateUpdate(temp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        },
        createTopic() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.partNumber +
            '/CreateTopic';
          let dataJS = JSON.stringify({
            AppointmentId: state.school.baseData?.targetApointmentForThemes,
            Number: Number(state.school.baseData?.topicNumberFirst),
            Title: state.school.baseData?.topicThemes,
            Description: state.school.baseData?.topicDescript,
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.get.column.dataTopic();
            })
            .then(() => {
              state.functions.get.column.clearCreateThemesPopUp();
            });
        },
        clearCreateThemesPopUp() {
          let temp = { ...state };
          temp.school.baseData.targetApointmentForThemes = null;
          temp.school.baseData.topicThemes = null;
          temp.school.baseData.topicDescript = null;
          stateUpdate(temp);
        },
        setAppointmentIdHomework(value) {
          let temp = { ...state };
          temp.school.baseData.targetAppointmentIdHomework = value;
          stateUpdate(temp);
        },
        getHomework() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Appointment/' +
            state.school.baseData.appointmentNumber +
            '/Homework';
          state.functions.getConstData.crud
            .get(tempUrl)
            .then(
              (body) => {
                let temp = { ...state };
                temp.school.arr.homework = body?.Items;
                temp.school.baseData.topicNumberHomework = 0;
                temp.school.baseData.targetApointment = 0;
                temp.school.baseData.editHomeWorkDescript = '';
                stateUpdate(temp);
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              for (let i = 0; i < state.school.arr.homework.length; i++) {
                state.functions.post.homework.getHomeworkFailesTwo(
                  state.school.arr?.homework[i]?.Id,
                  i,
                );
              }
            })
            .then(() => {
              let temp = { ...state };
              temp.school.popUpFlag.flagHomework = true;
              stateUpdate(temp);
            });
        },
        //new
        getCurrentHomework(appointmentNumber) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Appointment/' +
            appointmentNumber +
            '/Homework';
          state.functions.getConstData.crud
            .get(tempUrl)
            .then(
              (body) => {
                let temp = { ...state };
                temp.school.arr.homework = body?.Items;
                temp.school.baseData.topicNumberHomework = 0;
                temp.school.baseData.targetApointment = 0;
                temp.school.baseData.editHomeWorkDescript = '';
                stateUpdate(temp);
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              for (let i = 0; i < state.school.arr.homework.length; i++) {
                state.functions.post.homework.getHomeworkFailesTwo(
                  state.school.arr?.homework[i]?.Id,
                  i,
                );
              }
            })
            .then(() => {
              let temp = { ...state };
              temp.school.popUpFlag.flagHomework = true;
              stateUpdate(temp);
            });
        },
        //new
        getAllCurrentHomeworks(appointmentArray) {
          if (appointmentArray?.length > 0) {
            let temp = { ...state };
            temp.school.arr.homework = [];
            stateUpdate(temp);
            return appointmentArray?.forEach(async (appointmentNumber) => {
              let tempUrl =
                state.school.baseData.domen +
                '/v1/' +
                state.school.baseData.schoolId +
                '/Appointment/' +
                appointmentNumber.AppointmentId +
                '/Homework';
              await state.functions.getConstData.crud
                .get(tempUrl)
                .then(
                  (body) => {
                    temp.school.arr.homework = temp.school.arr?.homework
                      ?.concat(body?.Items)
                      ?.filter(
                        (v, i, a) => a.findIndex((t) => t.Id === v.Id) === i,
                      )
                      ?.sort(
                        (a, b) =>
                          new Date(a.AppointmentDate) -
                          new Date(b.AppointmentDate),
                      );
                    temp.school.baseData.topicNumberHomework = 0;
                    temp.school.baseData.targetApointment = 0;
                    temp.school.baseData.editHomeWorkDescript = '';
                    stateUpdate(temp);
                  },
                  (err) => {
                    if (err.type === 'error') {
                    } else if (err === 404 || err === 403) {
                    }
                  },
                )
                .then(() => {
                  for (let i = 0; i < state.school.arr.homework.length; i++) {
                    state.functions.post.homework.getHomeworkFailesTwo(
                      state.school.arr?.homework[i]?.Id,
                      i,
                    );
                  }
                })
                .then(() => {
                  let temp = { ...state };
                  temp.school.popUpFlag.flagHomework = true;
                  stateUpdate(temp);
                });
            });
          } else {
            return null;
          }
        },

        // const handlePublishTemplate = async () => {
        //   if (!editTemplate.Complete) {
        //     await postCompleteTemplate(editTemplate.Id).then(() => {
        //       newCurrentClassOptions?.forEach(element => {
        //         if (element?.State === true) {
        //           templateDuplicate(editTemplate.Id, element.Id)
        //         }
        //       })
        //     },
        //       localStorage.setItem("ClassOptions", JSON.stringify([]))
        //     );
        //     history.push("pdf-template");
        //   } else {
        //     history.push("pdf-template");
        //   }
        // };
        //new
        getTargetAppointmentsHomework() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Part/' +
            state.school.baseData.partNumber +
            '/Appointment?start=' +
            state.school.baseData.startAcademicPeriod +
            '&end=' +
            state.school.baseData.endAcademicPeriod +
            '&embed=Topic&embed=Homework,Topic,Rate';
          state.functions.getConstData.crud
            .get(tempUrl)
            .then(
              (body) => {
                let temp = { ...state };
                let tmp = [];
                let count = 0;
                if (body?.Items?.length !== 0) {
                  for (let i = 0; i < body?.Items?.length; i++) {
                    //if(body.Items[i].Id > temp.school.baseData.appointmentNumber){
                    //if(body.Items[i].Id > temp.school.baseData.columnPopUpData.AppointmentId){
                    if (
                      body?.Items[i]?.Date >
                      temp.school.baseData.columnPopUpData?.Date
                    ) {
                      tmp[count] = body?.Items[i];
                      count = count + 1;
                    }
                  }
                }

                temp.school.arr.TargetAppointment = tmp;
                temp.school.popUpFlag.flagTargetApointment = true;
                stateUpdate(temp);
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then((body) => {
              for (let i = 0; i < body?.Items?.length; i++) {
                if (body?.Items[i]?.Embed?.Homeworks !== null) {
                  if (body?.Items[i]?.Embed?.Homeworks?.length > 0) {
                    for (
                      let j = 0;
                      j < body?.Items[i]?.Embed?.Homeworks?.length;
                      j++
                    ) {
                      state.functions.get.column.getCollectHomework(
                        body?.Items[i]?.Embed.Homeworks[j]?.Id,
                      );
                    }
                  }
                }
              }
            });
        },
        getCollectHomework(hwId) {
          let tmpUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Homework/' +
            hwId +
            '?embed=File';
          state.functions.getConstData.crud.get(tmpUrl).then(
            (body) => {
              let tmp = { ...state };
              tmp.school.arr.PartHW = [...state.school.arr?.PartHW, body];
              stateUpdate(tmp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        },
      },
    },
    post: {
      rate: {
        rateMiss(dataJurnalId, dataSchoolbouId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            dataJurnalId +
            '/Missing';
          let dataJS = JSON.stringify({
            SchoolBoyId: dataSchoolbouId,
            AppointmentId: state.school.baseData?.appointmentNumber,
            MissingTypeId: 1,
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartRates(
                state.school.baseData?.partNumber,
              );
            });
        },
        rateRate(dataJurnalId, dataSchoolbouId, dataTypeId, value) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            dataJurnalId +
            '/Rate';
          let dataJS = null;
          if (dataTypeId === 2) {
            dataJS = JSON.stringify({
              SchoolBoyId: dataSchoolbouId,
              AppointmentId: state.school.baseData?.appointmentNumber,
              Rate: Number(value),
              Closing: true,
            });
          } else {
            dataJS = JSON.stringify({
              SchoolBoyId: dataSchoolbouId,
              AppointmentId: state.school.baseData?.appointmentNumber,
              Rate: Number(value),
            });
          }
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartRates(
                state.school.baseData?.partNumber,
              );
            });
        },
        rateHomeworkCheked(dataJurnalId, dataSchoolbouId, flag) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            dataJurnalId +
            '/Rate';
          !flag ? (flag = true) : (flag = false);

          let dataJS = JSON.stringify({
            SchoolBoyId: dataSchoolbouId,
            AppointmentId: state.school.baseData?.appointmentNumber,
            Homework: flag,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartRates(
                state.school.baseData?.partNumber,
              );
            });
        },
        rateComment(dataJurnalId, dataSchoolbouId, flag, value) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            dataJurnalId +
            '/Rate';
          let dataJS = JSON.stringify({
            SchoolBoyId: dataSchoolbouId,
            AppointmentId: state.school.baseData?.appointmentNumber,
            Description: value,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartRates(
                state.school.baseData?.partNumber,
              );
            });
        },
        switchClear(dataJurnalId, dataSchoolbouId) {
          if (state.school.baseData.popUpDataRate?.missingId !== null) {
            state.functions.post.rate.rateMissUnrate(
              dataJurnalId,
              dataSchoolbouId,
            );
          } else {
            state.functions.post.rate.rateRateUnrate(
              dataJurnalId,
              dataSchoolbouId,
            );
          }
        },
        popUpDataRateClear() {
          let tmp = { ...state };
          tmp.school.baseData.popUpDataRate = null;
          tmp.school.baseData.dataDescription = null;
          stateUpdate(tmp);
        },
        rateMissUnrate(dataJurnalId, dataSchoolbouId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            dataJurnalId +
            '/Present';
          let dataJS = JSON.stringify({
            SchoolBoyId: dataSchoolbouId,
            AppointmentId: state.school.baseData?.appointmentNumber,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartRates(
                state.school.baseData?.partNumber,
              );
            });
        },
        rateMissUnrateMis(columnId, appointmentId, schoolboyId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            columnId +
            '/Present';
          let dataJS = JSON.stringify({
            SchoolBoyId: schoolboyId,
            AppointmentId: appointmentId,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getOwnClassMissing();
              state.functions.getConstData.getOwnClassMissingSummary();
              state.functions.getConstData.getOwnClassSchoolboy();
              state.functions.getConstData.getMissingSchoolboy(
                state.school.baseData?.setMissingSchoolboyId,
                state.school.baseData?.setMissingSchoolboyId,
              );
            });
        },
        rateRateUnrate(dataJurnalId, dataSchoolbouId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            dataJurnalId +
            '/UnRate';
          let dataJS = JSON.stringify({
            SchoolBoyId: dataSchoolbouId,
            AppointmentId: state.school.baseData?.appointmentNumber,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartRates(
                state.school.baseData?.partNumber,
              );
            });
        },
      },
      homework: {
        createHomework() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Appointment/' +
            /*state.school.baseData.appointmentNumber*/ state.school.baseData
              .columnPopUpData.AppointmentId +
            '/AssignHomework';
          let dataJS = JSON.stringify({
            TopicId: Number(state.school.baseData?.topicNumberHomework),
            TargetAppointmentId: Number(
              state.school.baseData?.targetApointment,
            ),
            Description: state.school.baseData?.editHomeWorkDescript,
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.get.column.getHomework();
              state.school.journalTopics.getHomeworks(
                state.school.baseData.columnPopUpData?.AppointmentId,
              ); //New
            });
        },
        editHomework() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Homework/' +
            /*state.school.baseData.targetApointmentEditHomework*/ state.school
              .baseData.columnPopUpData.AppointmentId +
            '/Update';
          let dataJS = JSON.stringify({
            TopicId: Number(state.school.baseData?.topicNumberHomework),
            TargetAppointmentId: Number(
              state.school.baseData?.targetApointment,
            ),
            Description: state.school.baseData?.editHomeWorkDescript,
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.get.column.getHomework();
              state.school.journalTopics.getHomeworks(
                state.school.baseData.columnPopUpData?.AppointmentId,
              ); //New
            });
        },
        deleteHomework(value) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Appointment/' +
            /*state.school.baseData.appointmentNumber*/ state.school.baseData
              .columnPopUpData?.AppointmentId +
            '/RemoveHomework';
          let dataJS = JSON.stringify({
            HomeworkId: Number(value),
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.get.column.getHomework();
              state.school.journalTopics.getHomeworks(
                state.school.baseData?.PartId,
              ); //new
            });
        },
        getHomeworkFailes(value) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Homework/' +
            value +
            '?embed=File';
          state.functions.getConstData.crud.get(tempUrl).then(
            (body) => {
              let temp = { ...state };
              temp.school.arr.homeworkViewDZ = body?.Embed?.Files;
              temp.school.popUpFlag.flagHomeworkViewDZ = true;
              stateUpdate(temp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        },
        getHomeworkFailesTwo(value, count) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Homework/' +
            value +
            '?embed=File';
          state.functions.getConstData.crud.get(tempUrl).then(
            (body) => {
              let temp = { ...state };
              temp.school.arr.homework[count]['filesDZ'] = body?.Embed?.Files;
              temp.school.popUpFlag.flagHomeworkViewDZ = true;
              stateUpdate(temp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        },
        switcClearViewHomevorkFiles() {
          let temp = { ...state };
          temp.school.arr.homeworkViewDZ = null;
          temp.school.popUpFlag.flagViewDZ = false;
          temp.school.popUpFlag.flagHomeworkViewDZ = false;
          stateUpdate(temp);
        },
        deleteHomeworkFiles(filesId, homeworkId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Homework/' +
            homeworkId +
            '/RemoveFile';
          let dataJS = JSON.stringify({
            Id: Number(filesId),
          });

          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              let temp = { ...state };
              temp.school.arr.PartHW = [];
              stateUpdate(temp);
            })
            .then(() => {
              //state.functions.get.column.getHomework();
              state.functions.get.column.getTargetAppointmentsHomework();
              state.functions.get.column.getHomework();
              state.school.journalTopics.getHomeworks(
                state.school.baseData?.PartId,
              );
            });
        },
      },
      topic: {
        createTopicThemesNumber(value) {
          let temp = { ...state };
          temp.school.baseData.topicNumberFirst = value;
          stateUpdate(temp);
        },
        createTopicThemesText(value) {
          let temp = { ...state };
          temp.school.baseData.topicThemes = value;
          stateUpdate(temp);
        },
        editTopicThemesText(value) {
          let temp = { ...state };
          temp.school.baseData.editTopicThemes = value;
          stateUpdate(temp);
        },
        createTopicDescriptText(value) {
          let temp = { ...state };
          temp.school.baseData.topicDescript = value;
          stateUpdate(temp);
        },
        editTopicDescriptText(value) {
          let temp = { ...state };
          temp.school.baseData.editTopicDescript = value;
          stateUpdate(temp);
        },
        editTopicNumber(value) {
          let temp = { ...state };
          temp.school.baseData.editTopicNumber = value;
          stateUpdate(temp);
        },
        editHomeWorkDescriptText(value) {
          let temp = { ...state };
          temp.school.baseData.editHomeWorkDescript = value;
          stateUpdate(temp);
        },
      },
      column: {
        getTopicColumn() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Appointment/' +
            state.school.baseData.appointmentNumber +
            '?embed=Topic';

          state.functions.getConstData.crud.get(tempUrl).then(
            (body) => {
              let temp = { ...state };
              temp.school.arr.appointTheme = body?.Embed?.Topics;
              temp.school.popUpFlag.appointThemeFlag = true;
              stateUpdate(temp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        },
        createColumnThemesAndType() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Column/Create';
          let dataJS = JSON.stringify({
            AppointmentId: Number(state.school.baseData?.appointmentNumber),
            ColumnTypeId: Number(state.school.baseData?.columnTypeId),
            TopicId: Number(state.school.baseData?.createThemeTmp),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.changed.column.getTmpColumnSelectThemes(null);
              state.functions.changed.flags.popUpCreateColumnPicker(false);
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
            });
        },
        createThematicColumn() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/CloseTopic';
          let dataJS = JSON.stringify({
            AppointmentId: Number(state.school.baseData?.appointmentNumber),
            TopicId: Number(state.school.baseData?.appointThemeTmp),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.changed.column.getTmpColumnSelectThemes(null);
              state.functions.changed.column.getTmpColumnSelectCreateThemes(
                null,
              );
              state.functions.changed.flags.popUpCreateColumnPicker(false);
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
            });
        },
        autoUpdateThematicColumn(AppointmentId, TopicId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/CloseTopic';
          let dataJS = JSON.stringify({
            AppointmentId: Number(AppointmentId),
            TopicId: Number(TopicId),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
            });
        },
        autoUpdateSemestrColumn(AppointmentId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/CloseSemester';
          let dataJS = JSON.stringify({
            AppointmentId: Number(AppointmentId),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartRates(
                state.school?.baseData?.PartId,
              );
            });
        },
        createSemestrColumn() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/CloseSemester';
          let dataJS = JSON.stringify({
            AppointmentId: Number(state.school.baseData?.appointmentNumber),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.changed.flags.popUpCreateColumnPicker(false);
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
            });
        },

        deleteColumn() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Column/' +
            state.school.baseData.columnId +
            '/Remove';

          state.functions.getConstData.crud
            .post(tempUrl)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.changed.flags.popUpCreateColumnPicker(false);
              state.functions.getConstData.getPartRates(
                state.school.baseData?.PartId,
              );
              let tmp = { ...state };
              tmp.school.popUpFlag.flagHomework = false;
              tmp.school.popUpFlag.homeworkPicker = false;
              stateUpdate(tmp);
            });
        },
      },
      remark: {
        createClassRemark() {
          let tmp = state.school.arr.allClass?.filter((item) => {
            return item.Id === Number(state.school.baseData?.choiseClassRemark);
          });
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Class/' +
            state.school.baseData.choiseClassRemark +
            '/WriteRemark';
          let dataJS = JSON.stringify({
            Comment: state.school.baseData?.choiseClassRemarkText,
            TeacherId: tmp[0]?.ClassTeacherId,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              let temp = { ...state };
              temp.school.baseData.choiseClassRemark = 0;
              temp.school.baseData.choiseClassRemarkText = '';
              temp.school.arr.ownClassJournalRemark = null;
              stateUpdate(temp);
            })
            .then(() => {
              state.functions.getConstData.getAllJournalRemark();
            });
        },
        createClassRemarkJournal() {
          let tmp = state.school.arr.allClass?.filter((item) => {
            return (
              item.Id ===
              Number(state.school.arr.headTeacher?.headTeacherClassId)
            );
          });
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Class/' +
            state.school.arr.headTeacher.headTeacherClassId +
            '/WriteRemark';
          let dataJS = JSON.stringify({
            Comment: state.school.baseData.choiseClassRemarkText,
            TeacherId: tmp[0]?.ClassTeacherId,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              let temp = { ...state };
              temp.school.baseData.choiseClassRemarkText = '';
              stateUpdate(temp);
            });
        },
        UpdateClassRemark(classId, remarkId, TeacherId, count) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Class/' +
            classId +
            '/Remark/' +
            remarkId +
            '/Update';
          let dataJS = JSON.stringify({
            Notes: state.school.arr.ownClassJournalRemark[count]?.Notes,
            TeacherId: TeacherId,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getJournalRemark(
                state.school.baseData.teacher?.OwnClassId,
              );
            });
        },
      },
      classData: {
        getSchoolboyPart() {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Class/' +
            state.school.baseData.teacher.OwnClassId +
            '/Part';
          state.functions.getConstData.crud
            .get(tempUrl)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then((body) => {
              let temp = { ...state };
              temp.school.arr.ownClassPart = body?.Items;
              temp.school.arr.ownClassAllSubjectSchoolboy = null;
              temp.school.arr.ownClassAllSubjectSchoolboyRate = null;
              stateUpdate(temp);
            })
            .then(() => {
              for (let i = 0; i < state.school.arr.ownClassPart?.length; i++) {
                state.functions.post.classData.getAllSchoolboyPart(
                  state.school.arr.ownClassPart[i]?.Id,
                  state.school.arr.ownClassPart[i]?.Id,
                  state.school.arr.ownClassPart[i]?.SubjectId,
                );
              }
            })
            .then(() => {
              for (let i = 0; i < state.school.arr.ownClassPart.length; i++) {
                state.functions.post.classData.getAllSchoolboyRate(
                  state.school.arr.ownClassPart[i]?.Id,
                );
              }
            });
        },
        getAllSchoolboyPart(value, partId, subjectId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Part/' +
            value +
            '/SchoolBoy';
          state.functions.getConstData.crud
            .get(tempUrl)
            .then(
              (body) => {
                if (body?.Items?.length > 0) {
                  for (let t = 0; t < body?.Items?.length; t++) {
                    body.Items[t].partId = partId;
                    body.Items[t].subjectId = subjectId;
                  }
                }
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then((body) => {
              let tmp = { ...state };
              let temp = [];
              if (tmp.school.arr.ownClassAllSubjectSchoolboy !== null) {
                temp = tmp.school.arr.ownClassAllSubjectSchoolboy;
              }
              if (body.Items.length > 0) {
                if (temp.length > 0) {
                  temp = temp.concat(body?.Items);
                } else {
                  temp = body?.Items;
                }
              }
              tmp.school.arr.ownClassAllSubjectSchoolboy = temp;
              stateUpdate(tmp);
            });
        },
        getAllSchoolboyRate(value) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.schoolId +
            '/Part/' +
            value +
            '/Column?embed=Rate';
          state.functions.getConstData.crud
            .get(tempUrl)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then((body) => {
              let tmp = { ...state };
              let temp = [];
              if (tmp.school.arr?.ownClassAllSubjectSchoolboyRate !== null) {
                temp = tmp.school.arr?.ownClassAllSubjectSchoolboyRate;
              }
              if (body.Items.length > 0) {
                if (temp.length > 0) {
                  temp = temp.concat(body?.Items);
                } else {
                  temp = body?.Items;
                }
              }
              tmp.school.arr.ownClassAllSubjectSchoolboyRate = temp;
              stateUpdate(tmp);
              return body;
            });
        },
      },
      themes: {
        getPartAppointments() {
          let year = state.school.baseData.startAcademicYear;
          if (
            state.school.baseData.data.monthNumber <
            state.school.baseData.minAcademicMonth
          ) {
            year = state.school.baseData.endAcademicYear;
          }
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/Appointment?start=' +
            year +
            '-' +
            state.school.baseData.data.monthNumberString +
            '-01&end=' +
            year +
            '-' +
            state.school.baseData.data.monthNumberString +
            '-31';
          state.functions.getConstData.crud.get(tempUrl).then(
            (body) => {
              let temp = { ...state };
              temp.school.arr.appointmentsForThemes = body?.Items;
              stateUpdate(temp);
            },
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        },
        getPartTopic() {
          let url =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/Topic';
          state.functions.getConstData.crud.get(url).then(
            (body) => {},
            (err) => {
              if (err.type === 'error') {
              } else if (err === 404 || err === 403) {
              }
            },
          );
        },
        topicAddAppointment(appointmentId, data) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Appointment/' +
            appointmentId +
            '/AssignTopic';
          let dataJS = JSON.stringify({
            TopicId: data,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartThemes(
                state.school.baseData?.PartId,
              );
            })
            .then(() => {
              let temp = { ...state };
              temp.school.baseData.appointThemeTmp = 0;
              temp.school.baseData.targetApointmentForThemesBind = 0;
              temp.school.baseData.BindTopicForAppointment = true;
              stateUpdate(temp);
            });
        },
        topicAddAppointmentFromColumn(appointmentId, data) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Appointment/' +
            appointmentId +
            '/AssignTopic';
          let dataJS = JSON.stringify({
            TopicId: data,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.getConstData.getPartThemes(
                state.school.baseData?.PartId,
              );
            });
        },
        topicAddColumn(columnId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Column/' +
            columnId +
            '/Update';
          let dataJS = JSON.stringify({
            TopicId: Number(state.school.baseData?.appointThemeTmp),
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then((body) => {
              state.functions.post.classData.getAllSchoolboyRate(
                state.school.baseData?.PartId,
              );
              return body;
            })
            .then((body) => {
              //Тут надо сделать проверку и если такой топик уже привязан то не привязывать
              if (state.school.arr.partThemes?.length !== 0) {
                let count = 0;
                for (let i = 0; i < state.school.arr.partThemes?.length; i++) {
                  if (state.school.arr.partThemes[i]?.Id === body?.TopicId) {
                    count = 1;
                  }
                }
                if (count !== 1) {
                  state.functions.post.themes.topicAddAppointmentFromColumn(
                    state.school.baseData?.targetAppointmentIdHomework,
                    body.TopicId,
                  );
                }
              } else {
                state.functions.post.themes.topicAddAppointmentFromColumn(
                  state.school.baseData?.targetAppointmentIdHomework,
                  body.TopicId,
                );
              }
            });
        },

        getAppointmentMainColumn(value) {
          let tempUrl = state.school.baseData.domen + '/v1/';
          state.functions.getConstData.crud
            .get(
              tempUrl +
                state.school.baseData.schoolId +
                '/Appointment/' +
                value +
                '/PrimaryColumn',
            )
            .then(
              (body) => {
                return body?.Id;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then((body) => {
              state.functions.post.themes.topicAddColumn(body);
            });
        },

        editTopic(topicId, number) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/EditTopic';
          let dataJS = JSON.stringify({
            TopicId: topicId,
            Number: Number(number),
            Title: state.school.baseData?.editTopicThemes,
            Description: state.school.baseData?.editTopicDescript,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              state.functions.get.column.dataTopic();
            });
        },
        deleteTopic(topicId) {
          let tempUrl =
            state.school.baseData.domen +
            '/v1/' +
            state.school.baseData.teacher.SchoolId +
            '/Part/' +
            state.school.baseData.PartId +
            '/RemoveTopic';
          let dataJS = JSON.stringify({
            TopicId: topicId,
          });
          state.functions.getConstData.crud
            .post(tempUrl, dataJS)
            .then(
              (body) => {
                return body;
              },
              (err) => {
                if (err.type === 'error') {
                } else if (err === 404 || err === 403) {
                }
              },
            )
            .then(() => {
              let temp = { ...state };
              temp.school.baseData.appointThemeTmpEdit = null;
              stateUpdate(temp);
              state.functions.post.topic.editTopicThemesText('');
              state.functions.post.topic.editTopicDescriptText('');
              state.functions.get.column.dataTopic();
            });
        },
      },
    },
    menu: {
      clearTop() {
        let temp = { ...state };
        temp.school.baseData.Subject = null;
        temp.school.baseData.Part = null;
        temp.school.baseData.Class = null;
        temp.school.baseData.typeJournal = 0;
        temp.school.baseData.dataFlag = null;
        stateUpdate(temp);
      },
      reversText(value) {
        let temp = { ...state };
        temp.school.baseData.typeJournal = value;
        stateUpdate(temp);
      },
    },
  },
};

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

// eslint-disable-next-line no-unused-vars
function createBaseLink(dataId, dataSchoolId, dataPosition) {
  return (
    state.school.baseData.domen +
    '/v1/' +
    dataSchoolId +
    '/' +
    dataPosition +
    '/' +
    dataId
  );
}

// eslint-disable-next-line no-unused-vars
function upperCaseFirstChar(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function createStringData(data) {
  let tmp = data;
  if (data < 10) {
    tmp = '0' + tmp;
  }
  return tmp;
}

// export let stateUpdate = (value) => {
//   state = value;
//   renderThree();
// };

export let stateUpdate = (value, isDisplay = false) => {
  state = value;
  if (!isDisplay) {
    renderThree();
  }
};

export default state;
