import React from "react";
import styles from "./mainTopic.module.css";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function MainTopic(props) {
  const handleMainNumber = (name) => (event) => {
    props.states.school.journalTopics.createMain.setNumber(event.target.value);
  };
  const handleMainTopic = (name) => (event) => {
    props.states.school.journalTopics.createMain.setTitle(event.target.value);
  };
  const handleMainDescript = (name) => (event) => {
    props.states.school.journalTopics.createMain.setDescription(
      event.target.value
    );
  };
  let buttomSem = null;
  if (props.states.school.journalTopics.createMain.semestr === null) {
    buttomSem = (
      <div>
        <div className={styles.popup_topic}>Семестр</div>
        <div className={styles.semestr_topic}>
          <div
            onClick={() => {
              props.states.school.journalTopics.createMain.setSemestr(1);
            }}
          >
            1
          </div>
          <div
            onClick={() => {
              props.states.school.journalTopics.createMain.setSemestr(2);
            }}
          >
            2
          </div>
        </div>
      </div>
    );
  } else if (props.states.school.journalTopics.createMain.semestr === 1) {
    buttomSem = (
      <div>
        <div className={styles.popup_topic}>Семестр</div>
        <div className={styles.semestr_topic}>
          <div
            className={styles.semestr_topicActiv}
            onClick={() => {
              props.states.school.journalTopics.createMain.setSemestr(1);
            }}
          >
            1
          </div>
          <div
            onClick={() => {
              props.states.school.journalTopics.createMain.setSemestr(2);
            }}
          >
            2
          </div>
        </div>
      </div>
    );
  } else if (props.states.school.journalTopics.createMain.semestr === 2) {
    buttomSem = (
      <div>
        <div className={styles.popup_topic}>Семестр</div>
        <div className={styles.semestr_topic}>
          <div
            onClick={() => {
              props.states.school.journalTopics.createMain.setSemestr(1);
            }}
          >
            1
          </div>
          <div
            className={styles.semestr_topicActiv}
            onClick={() => {
              props.states.school.journalTopics.createMain.setSemestr(2);
            }}
          >
            2
          </div>
        </div>
      </div>
    );
  }
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <p>Тема</p>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>
          {buttomSem}
          <div>
            <div className={styles.popup_topic}>Номер теми</div>
            <div>
              <TextField
                value={props.states.school.journalTopics.createMain.number}
                onChange={handleMainNumber("name")}
                id="outlined-bare"
                className={styles.textField + " " + styles.textfild_topic}
                inputProps={{ "aria-label": "bare" }}
                margin="normal"
                variant="outlined"
              />
            </div>
          </div>
          <div>
            <div className={styles.popup_topic}>Назва теми</div>
            <div>
              <TextField
                value={props.states.school.journalTopics.createMain.title}
                onChange={handleMainTopic("name")}
                id="outlined-bare"
                className={styles.textField + " " + styles.textfild_topic}
                inputProps={{ "aria-label": "bare" }}
                margin="normal"
                variant="outlined"
              />
            </div>
          </div>
          {buttonCtreateMainTopic(props)}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

function buttonCtreateMainTopic(props) {
  let tmp = null;
  if (
    props.states.school.journalTopics.createMain.semestr !== null &&
    props.states.school.journalTopics.createMain.number !== null &&
    props.states.school.journalTopics.createMain.title !== null
  ) {
    tmp = (
      <div className={styles.popup_button}>
        <Button
          onClick={() => {
            if (props.states.school.baseData.topicThemes !== 0) {
              props.states.school.journalTopics.createMain.create();
              props.states.functions.changed.flags.changeFlagCreateTopic(false);
            }
          }}
          variant="contained"
          color="primary"
          className={styles.Button}
        >
          Створити тему
        </Button>
      </div>
    );
  }
  return tmp;
}
