import React from "react";
import styles from "./mainTopic.module.css";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function MainTopic(props) {
  const handleMainNumber = (name) => (event) => {
    props.states.school.journalTopics.editMain.setNumber(event.target.value);
  };
  const handleMainTopic = (name) => (event) => {
    props.states.school.journalTopics.editMain.setTitle(event.target.value);
  };
  const handleMainDescript = (name) => (event) => {
    props.states.school.journalTopics.editMain.setDescription(
      event.target.value
    );
  };
  let buttomSem = null;
  if (props.states.school.journalTopics.editMain.semestr === null) {
    buttomSem = (
      <div>
        <div className={styles.popup_topic}>Семестр</div>
        <div className={styles.semestr_topic}>
          <div
            onClick={() => {
              props.states.school.journalTopics.editMain.setSemestr(1);
            }}
          >
            1
          </div>
          <div
            onClick={() => {
              props.states.school.journalTopics.editMain.setSemestr(2);
            }}
          >
            2
          </div>
        </div>
      </div>
    );
  } else if (props.states.school.journalTopics.editMain.semestr === 1) {
    buttomSem = (
      <div>
        <div className={styles.popup_topic}>Семестр</div>
        <div className={styles.semestr_topic}>
          <div
            className={styles.semestr_topicActiv}
            onClick={() => {
              props.states.school.journalTopics.editMain.setSemestr(1);
            }}
          >
            1
          </div>
          <div
            onClick={() => {
              props.states.school.journalTopics.editMain.setSemestr(2);
            }}
          >
            2
          </div>
        </div>
      </div>
    );
  } else if (props.states.school.journalTopics.editMain.semestr === 2) {
    buttomSem = (
      <div>
        <div className={styles.popup_topic}>Семестр</div>
        <div className={styles.semestr_topic}>
          <div
            onClick={() => {
              props.states.school.journalTopics.editMain.setSemestr(1);
            }}
          >
            1
          </div>
          <div
            className={styles.semestr_topicActiv}
            onClick={() => {
              props.states.school.journalTopics.editMain.setSemestr(2);
            }}
          >
            2
          </div>
        </div>
      </div>
    );
  }
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <p>Тема</p>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>
          <div className={styles.popup_div_selected}>
            <div className={styles.popup_descrip}>Тема</div>
            <Select
              className={styles.textField + " " + styles.selectFiles}
              native
              value={props.states.school.journalTopics.editMain.id}
              onChange={(value) => {
                props.states.school.journalTopics.editMain.setId(
                  value.target.value
                );
              }}
              input={
                <OutlinedInput name="age" id="outlined-age-native-simple" />
              }
            >
              <option selected disabled key={5677} value={0}>
                Обрати тему
              </option>
              {bindSelectMainTopic(props)}
            </Select>
          </div>
          {buttomSem}
          <div>
            <div className={styles.popup_topic}>Номер теми</div>
            <div>
              <TextField
                value={props.states.school.journalTopics.editMain.number}
                onChange={handleMainNumber("name")}
                id="outlined-bare"
                className={styles.textField + " " + styles.textfild_topic}
                inputProps={{ "aria-label": "bare" }}
                margin="normal"
                variant="outlined"
              />
            </div>
          </div>
          <div>
            <div className={styles.popup_topic}>Назва теми</div>
            <div>
              <TextField
                value={props.states.school.journalTopics.editMain.title}
                onChange={handleMainTopic("name")}
                id="outlined-bare"
                className={styles.textField + " " + styles.textfild_topic}
                inputProps={{ "aria-label": "bare" }}
                margin="normal"
                variant="outlined"
              />
            </div>
          </div>
          {buttonCtreateMainTopic(props)}
          {buttonDeleteMainTopic(props)}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

function buttonCtreateMainTopic(props) {
  let tmp = null;
  if (
    props.states.school.journalTopics.editMain.id !== null &&
    props.states.school.journalTopics.editMain.semestr !== null &&
    props.states.school.journalTopics.editMain.number !== null &&
    props.states.school.journalTopics.editMain.title !== null
  ) {
    tmp = (
      <div className={styles.popup_button}>
        <Button
          onClick={() => {
            if (props.states.school.baseData.topicThemes !== 0) {
              props.states.school.journalTopics.editMain.edit();
              props.states.functions.changed.flags.changeFlagCreateTopic(false);
            }
          }}
          variant="contained"
          color="primary"
          className={styles.Button}
        >
          Зберегти
        </Button>
      </div>
    );
  }
  return tmp;
}

function buttonDeleteMainTopic(props) {
  let tmp = null;
  if (props.states.school.journalTopics.editMain.id !== null) {
    tmp = (
      <div className={styles.popup_button}>
        <Button
          onClick={() => {
            if (props.states.school.baseData.topicThemes !== 0) {
              props.states.school.journalTopics.editMain.delete();
              props.states.functions.changed.flags.changeFlagCreateTopic(false);
            }
          }}
          variant="contained"
          color="primary"
          className={styles.Button}
        >
          Видалити
        </Button>
      </div>
    );
  }
  return tmp;
}

function bindSelectMainTopic(props) {
  let tmp = null;
  if (props.states.school.journalTopics.partTopics !== null) {
    tmp = props.states.school.journalTopics.partTopics
      .filter((e) => {
        return e.ParentId === null;
      })
      .map((e) => {
        return (
          <option key={e.Id} value={e.Id}>
            {e.Title}
          </option>
        );
      });
  }
  return tmp;
}
